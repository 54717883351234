import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import videoplay from "assets/img/videoplay.png";
import quotations from "assets/img/quotations1.png";
import quotations2 from "assets/img/quotation2.png";
import pic1 from "assets/img/pic1.jpg";
import pic2 from "assets/img/pic2.png";
import training from "assets/img/training.png";
import consultation from "assets/img/consultation.png";
import strategicpartners from "assets/img/strategicpartners.png";
import corporatedprograms from "assets/img/corporatedprograms.png";
import teacher from "assets/img/teacher.png";
import compensationconsulting from "assets/img/compensationconsulting.png";
import talent from "assets/img/teacher.png";
import researchstrategy from "assets/img/researchstrategy.png";
import blue from "assets/img/blue.jpeg";
import calender from "assets/img/calendar.png";
import art from "assets/img/art.png";
import business from "assets/img/business.png";
import technology from "assets/img/technology.png";
import financialsupport from "assets/img/financialsupport.png";
import development from "assets/img/development.png";
import share from "assets/img/share.png";
import playstore from "assets/img/playstore.png";
import apple from "assets/img/apple.png";

import Footer2 from "components/Footers/Footer2";

export default function Homepage1() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % 3);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + 3) % 3);
  };

  const [showIcons, setShowIcons] = useState(false);
  const [showIcons1, setShowIcons1] = useState(false);
  const [showIcons2, setShowIcons2] = useState(false);
  const [showIcons3, setShowIcons3] = useState(false);
  return (
    <section>
      <Indexnavbar2 />

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden bg-newshade33 xl:pb-52">
        <section className="container mx-auto figtree">
          <div className="grid grid-cols-2 xl:pt-36">
            <div className="xl:ml-6 ">
              <p class="text-darkblue xl:text-base font-bold">
                100% QUALITY COURSES
              </p>
              <p class="text-white xl:text-6xl font-bold xl:pt-4">
                Find Your Perfect Course
              </p>
              <div>
                <img
                  class="xl:w-3/12 xl:ml-104"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/underline-shape.svg"
                  alt=""
                />
              </div>
              <p class="text-white xl:text-6xl font-bold xl:pt-2">
                And Improve Your Skills
              </p>

              <div className="border bg-white rounded-full  xl:mt-8 p-2">
                <div className="flex ">
                  <p class="xl:text-base font-bold xl:ml-4 xl:mt-3">
                    All Categories
                  </p>

                  <div class="custom-select">
                    <div class="select-icon">
                      <img
                        class=" ml-4 mt-2 "
                        src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%2371717a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e"
                        alt=""
                      />
                    </div>

                    <select class="hidden-select">
                      <option value="1">All</option>
                      <option value="2">Art & Design</option>
                      <option value="3">Business</option>
                      <option value="3">Data Science</option>
                      <option value="3">Development</option>
                      <option value="3">Finance</option>
                    </select>
                  </div>

                  <div class="vl-4"></div>
                  <div>
                    <p class="xl:text-xs text-grey font-bold xl:ml-4 xl:mt-4">
                      Find Your Courses...
                    </p>
                  </div>

                  <div className="border bg-newshade8  rounded-full p-2 xl:ml-44 red-on-hover2">
                    <div className="flex">
                      <div>
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                            />
                          </svg>
                        </p>
                      </div>
                      Search
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="flex xl:mt-12">
                <div className=" absolute xl:-ml-12">
                  <img
                    class="xl:w-12/12"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/hero-shape-17.svg"
                    alt=""
                  />
                </div>
                <div className=" absolute xl:ml-40 xl:-mt-28">
                  <img
                    class="xl:w-12/12"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/hero-img-3.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto figtree">
        <div className="bg-white rounded-md shadow-xl xl:-mt-16">
          <div className="grid grid-cols-5 xl:pb-8">
            <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 xl:mt-8 xl:pb-4 xl:ml-4">
                <div className="flex">
                  <div className="bg-newshade34 rounded-full xl:h-16 xl:w-16 xl:mt-4 xl:ml-4">
                    <div className="center-button">
                      <img class="w-8 xl:pt-4" src={art} />
                    </div>
                  </div>
                  <div>
                    <p class="xl:text-base1  font-semibold xl:mt-6 xl:ml-4 ">
                      Art&Design
                    </p>
                    <p class="xl:text-base  xl:ml-4">1 Course</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="rounded-md bg-newshade25 xl:mt-8 xl:pb-4 xl:ml-4 red-on-hover4 ">
                <div className="flex">
                  <div className="bg-newshade35 rounded-full xl:h-16 xl:w-16 xl:mt-4 xl:ml-4">
                    <div className="center-button">
                      <img class="w-8 xl:pt-4" src={business} />
                    </div>
                  </div>
                  <div>
                    <p class="xl:text-base1  font-semibold xl:mt-6 xl:ml-4 ">
                      Business
                    </p>
                    <p class="xl:text-base  xl:ml-4">3 Courses</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="rounded-md bg-newshade25 xl:mt-8 xl:pb-4 xl:ml-4 red-on-hover4">
                <div className="flex">
                  <div className="bg-newshade36 rounded-full xl:h-16 xl:w-16 xl:mt-4 xl:ml-4">
                    <div className="center-button">
                      <img class="w-8 xl:pt-4" src={development} />
                    </div>
                  </div>
                  <div>
                    <p class="xl:text-base1  font-semibold xl:mt-6 xl:ml-4 ">
                      Development
                    </p>
                    <p class="xl:text-base  xl:ml-4">2 Courses</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="rounded-md bg-newshade25 xl:mt-8 xl:pb-4 xl:ml-4 red-on-hover4">
                <div className="flex">
                  <div className="bg-newshade37 rounded-full xl:h-16 xl:w-16 xl:mt-4 xl:ml-4">
                    <div className="center-button">
                      <img class="w-8 xl:pt-4" src={financialsupport} />
                    </div>
                  </div>
                  <div>
                    <p class="xl:text-base1  font-semibold xl:mt-6 xl:ml-4 ">
                      Finance
                    </p>
                    <p class="xl:text-base  xl:ml-4 ">2 Courses</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="rounded-md bg-newshade25 xl:mt-8 xl:pb-4 xl:ml-4 xl:mr-4 red-on-hover4">
                <div className="flex">
                  <div className="bg-newshade38 rounded-full xl:h-16 xl:w-16 xl:mt-4 xl:ml-4">
                    <div className="center-button ">
                      <img class="w-8 xl:pt-4" src={technology} />
                    </div>
                  </div>
                  <div>
                    <p class="xl:text-base1  font-semibold xl:mt-6 xl:ml-4 ">
                      Technology
                    </p>
                    <p class="xl:text-base  xl:ml-4">3 Courses</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden  xl:mt-24 figtree  bg-newshade29 xl:pb-16 ">
        <p class="text-center text-darkblue xl:text-base font-bold xl:pt-12">
          FEATURED COURSES
        </p>
        <p class="text-center xl:text-4xl font-bold text-darkblack xl:pt-2">
          Find Yours From The Featured
        </p>
        <div className="justify-center center-button">
          <img
            class="xl:w-2/12"
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="xl:text-base text-center xl:mt-4">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen
        </p>
        <p class="xl:text-base text-center xl:pt-2">
          Book It Has Survived Not Only Five Centuries
        </p>

        <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto figtree ">
          <div className="grid grid-cols-3  xl:mt-12 xl:pb-4 ">
            <div className="border-3 bg-white shadow-xl rounded-md xl:w-11/12 xl:pb-6 xl:ml-6">
              <div class=" ">
                <div class="">
                  <img
                    class="xl:w-10/12 rounded-md xl:mt-6 xl:ml-9 object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/07/crs_img_2-380x277.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex xl:mt-6">
                <div className="border-3 xl:w-3/12 rounded-md xl:ml-9 ">
                  <p class="xl:text-base xl:ml-1">Technology</p>
                </div>

                <div className="flex xl:mt-1">
                  <p class="xl:text-xs xl:ml-26">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                </div>
              </div>

              <p class="xl:text-xl text-darkblack font-bold xl:ml-9 xl:pt-4 blue-on-hover">
                The Complete Android Java Developer Course
              </p>

              <div className="flex xl:mt-6">
                <div class="">
                  <img
                    class="w-8 h-8 xl:ml-9"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                    alt=""
                  />
                </div>
                <p class="xl:text-base xl:ml-4 blue-on-hover  xl:mt-1">
                  Millar Richard
                </p>
              </div>

              <div className="flex xl:mt-4">
                <p class="text-black xl:ml-9 xl:text-base1">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">19</p>
                <p class="xl:text-base1 xl:ml-6 text-black">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">8 ls</p>
                <p class="xl:text-base1 text-blue font-bold xl:ml-30 ">
                  $55.00
                </p>
                <p></p>
              </div>
            </div>

            <div className=" border-3 bg-white shadow-xl rounded-md  xl:w-11/12 xl:ml-3">
              <div class=" ">
                <div class="">
                  <img
                    class="xl:w-10/12 rounded-md xl:mt-6 xl:ml-9 object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/07/crs_img_1-380x277.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex xl:mt-6">
                <div className="border-3 xl:w-3/12 rounded-md xl:ml-9 ">
                  <p class="xl:text-base xl:ml-4 ">Business</p>
                </div>

                <div className="border-3 xl:w-2/12 rounded-md xl:ml-2 ">
                  <p class="xl:text-base xl:ml-1">Finance</p>
                </div>
                <div className="flex xl:mt-1">
                  <p class="xl:text-xs xl:ml-10">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                </div>
              </div>

              <p class="xl:text-xl text-darkblack font-bold xl:ml-9 xl:pt-4 blue-on-hover">
                The Complete Android Development Course
              </p>

              <div className="flex xl:mt-6">
                <div class="">
                  <img
                    class="w-8 h-8 xl:ml-9"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/4/5a084ec8c7fddeff9644d1ba46c5eb06.jpeg"
                    alt=""
                  />
                </div>
                <p class="xl:text-base xl:ml-4 blue-on-hover xl:mt-1">
                  Floyd Miles
                </p>
              </div>

              <div className="flex xl:mt-4">
                <p class="text-black xl:ml-9 xl:text-base1">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">8</p>
                <p class="xl:text-base1 xl:ml-6 text-black">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">6 ls</p>
                <p class="xl:text-base1 text-blue font-bold xl:ml-30 ">
                  $39.00
                </p>
                <p></p>
              </div>
            </div>

            <div className="border-3 bg-white shadow-xl rounded-md xl:w-11/12">
              <div class=" ">
                <div class="">
                  <img
                    class="xl:w-10/12 rounded-md xl:mt-6 xl:ml-9 object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/07/crs_img_3-380x277.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex xl:mt-6">
                <div className="border-3 xl:w-3/12 rounded-md xl:ml-9 ">
                  <p class="xl:text-base ">Development</p>
                </div>

                <div className="flex xl:mt-1">
                  <p class="xl:text-xs xl:ml-26">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                  <p class="xl:text-xs xl:ml-2">⭐</p>
                </div>
              </div>

              <p class="xl:text-xl text-darkblack font-bold xl:ml-9 xl:pt-4 blue-on-hover">
                The Complete WoodPress Plugin Development Course
              </p>

              <div className="flex xl:mt-6">
                <div class="">
                  <img
                    class="w-8 h-8 xl:ml-9"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/6/dc87ed52efaf1f8df3504ad27ea93859.jpeg"
                    alt=""
                  />
                </div>
                <p class="xl:text-base xl:ml-4 blue-on-hover xl:mt-1">
                  Jenny Wilson
                </p>
              </div>

              <div className="flex xl:mt-4">
                <p class="text-black xl:ml-9 xl:text-base1">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">7</p>
                <p class="xl:text-base1 xl:ml-6 text-black">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">6 ls</p>
                <p class="xl:text-base1 text-blue font-bold xl:ml-30 ">
                  $120.00
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto xl:mt-20 figtree">
        <div className="grid grid-cols-2">
          <div className=" rounded-lg bg-newshade39 xl:pb-8">
            <div className="flex">
              <div className="center-button">
                <img
                  class="xl:ml-8 xl:mt-8"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/video_banner_02.png.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-white font-bold xl:text-2xl xl:mt-8 xl:ml-10">
                  Achieve Your Goals With Quiklearn
                </p>
                <p class="xl:text-base xl:pt-2 text-grey xl:ml-10">
                  When An Unknown Printer Took A Galley
                </p>
                <p class="xl:text-base text-grey xl:ml-10">
                  Offer Area Type Make Specimen Book Has
                </p>
                <p class="xl:text-base text-grey xl:ml-10">
                  Survived Type Make.
                </p>

                <a href="/">
                  <button class="xl:px-6 xl:py-3 text-white rounded-full xl:mt-4 xl:ml-10 border-6 red-on-hover3 ">
                    Lets Discover ➔
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className=" rounded-lg bg-newshade33 xl:pb-8 xl:ml-4">
            <div className="flex">
              <div>
                <div className="center-button ">
                  <img
                    class="xl:ml-8 xl:mt-8 rounded-full"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/video_banner_01.png"
                    alt=""
                  />
                </div>
                <a href="/">
                  <img
                    class="w-16 xl:-mt-36 xl:ml-28 absolute bg-white rounded-full red-on-hover4 "
                    src={videoplay}
                  />
                </a>
              </div>
              <div>
                <p class="text-white font-bold xl:text-2xl xl:mt-8 xl:ml-10">
                  Expert Instructor Live Classes Here!
                </p>
                <p class="xl:text-base xl:pt-2 text-grey xl:ml-10">
                  When An Unknown Printer Took A Galley
                </p>
                <p class="xl:text-base text-grey xl:ml-10">
                  Offer Area Type Make Specimen Book Has
                </p>
                <p class="xl:text-base text-grey xl:ml-10">
                  Survived Type Make.
                </p>

                <a href="/">
                  <button class="xl:px-12 xl:py-3 text-blue rounded-full xl:mt-4 xl:ml-10 border-4 bg-white red-on-hover4 ">
                    Take Your Service ➔
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto xl:mt-20 figtree">
        <div className="grid grid-cols-2">
          <div>
            <img
              class="xl:w-10/12"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img1.png"
              alt=""
            />
          </div>

          <div>
            <p class="xl:text-base xl:mt-16 font-semibold text-darkblue">
              LEARNERS AND STUDENTS
            </p>
            <p class="xl:text-4xl text-darkblack font-bold xl:pt-2">
              We Teach The Fundamentals Of
            </p>
            <p class="xl:text-4xl text-darkblack font-bold xl:pt-2">
              Art While Encouraging Creativity,
            </p>
            <div>
              <img
                class="xl:w-3/12 xl:ml-96 xl:-mt-2"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
                alt=""
              />
            </div>
            <p class="xl:text-4xl text-darkblack font-bold">
              Curiosity, And Individuality.
            </p>

            <p class="xl:text-base xl:mt-6">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To<br></br> Make A Type Specimen Book Has Survived When
              An Unknown Printer Took A<br></br> Galley Offer Area Type And
              Scrambled To Make.
            </p>

            <a href="/">
              <button class="xl:px-12 xl:py-3 text-white rounded-full xl:mt-4  border-7 bg-newshade33 red-on-hover4 ">
                Explore All Courses ➔
              </button>
            </a>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden bg-newshade39 xl:mt-20 figtree">
        <section className="container mx-auto">
          <div className="grid grid-cols-2 xl:pt-24 xl:pb-24">
            <div>
              <p class="xl:text-base text-grey font-semibold">WHY CHOOSE US</p>
              <p class="xl:text-4xl text-white font-bold xl:pt-2">
                Dont Know How To Start With
              </p>
              <div>
                <img
                  class="xl:w-4/12 xl:ml-52 "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
                  alt=""
                />
              </div>
              <p class="xl:text-4xl text-white font-bold">QuickLearn Courses</p>
              <p class="xl:text-base text-grey xl:pt-4">
                When An Unknown Printer Took A Galley Offer Area Type And
                <br></br> Scrambled To Make A Type Specimen Book .
              </p>

              <div className="flex xl:mt-6">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base text-white xl:ml-2 font-bold">
                  Special Lessons And Courses
                </p>

                <div className="bg-newshade33 rounded-full w-6 h-6 xl:ml-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base text-white xl:ml-2 font-bold">
                  Get Every General Answers
                </p>
              </div>

              <div className="flex xl:mt-4">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base text-white xl:ml-2 font-bold">
                  World Largest Language
                </p>

                <div className="bg-newshade33 rounded-full w-6 h-6 xl:ml-17">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base text-white xl:ml-2 font-bold">
                  A Residential Campus
                </p>
              </div>

              <div className="flex xl:mt-4">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base text-white xl:ml-2 font-bold">
                  15 Language For Beginners
                </p>

                <div className="bg-newshade33 rounded-full w-6 h-6 xl:ml-13">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base text-white xl:ml-2 font-bold">
                  A Residential Campus
                </p>
              </div>

              <a href="/">
                <button class="xl:px-12 xl:py-3 text-darkblack rounded-full xl:mt-8  bg-white red-on-hover3 ">
                  Explore All Courses ➔
                </button>
              </a>
            </div>

            <div>
              <img
                class="xl:w-12/12"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/09/h1about-768x557.png"
                alt=""
              />
            </div>
          </div>
        </section>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto xl:mt-20 figtree">
        <p class="text-center  xl:text-base font-semibold text-darkblue">
          OUR INSTRUCTORS
        </p>
        <p class="text-darkblack text-center xl:pt-2 font-bold xl:text-4xl">
          From The Quiklearn Community
        </p>
        <div className="center-button">
          <img
            class="xl:w-2/12  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center xl:text-base xl:mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen<br></br>
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="grid grid-cols-4 xl:mt-12 ">
          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl ">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/9/a87cce35aa3a4cb374ad14c92334a35e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute xl:mt-6 xl:ml-56"
                onMouseEnter={() => setShowIcons(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="xl:mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="xl:text-base1 text-center text-blue font-bold xl:mt-8 black-on-hover">
                Richard David
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                Marketing Expert
              </p>

              <div className="flex xl:mt-6 xl:pb-4">
                <p class="text-black xl:ml-6 xl:text-base1">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  2 courses
                </p>
                <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  16 students
                </p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl  xl:ml-4">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute xl:mt-6 xl:ml-56"
                onMouseEnter={() => setShowIcons1(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons1(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons1 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="xl:mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="xl:text-base1 text-center text-blue font-bold xl:mt-12 black-on-hover">
                Millar Richard
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">Business</p>

              <div className="flex xl:mt-6">
                <p class="text-black xl:ml-6 xl:text-base1">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  2 courses
                </p>
                <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  23 students
                </p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl  xl:ml-4">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/8/8d69d3fc4b9ecc16660fc9e32192e9e1.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute xl:mt-6 xl:ml-56"
                onMouseEnter={() => setShowIcons2(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons2(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons2 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="xl:mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="xl:text-base1 text-center text-blue font-bold xl:mt-12 black-on-hover">
                Kristin Watson
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">UX/UI Expert</p>

              <div className="flex xl:mt-6">
                <p class="text-black xl:ml-6 xl:text-base1">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  1 course
                </p>
                <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  1 student
                </p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl  xl:ml-4">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/7/84ce06432355d2548c4c2d534bb8873e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute xl:mt-6 xl:ml-56"
                onMouseEnter={() => setShowIcons3(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons3(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons3 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="xl:mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="xl:text-base1 text-center text-blue font-bold xl:mt-12 black-on-hover">
                Jacob Jones
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                UX/UI Designer
              </p>

              <div className="flex xl:mt-6">
                <p class="text-black xl:ml-6 xl:text-base1">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  2 courses
                </p>
                <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  8 students
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 bg-newshade33">
        <section className="container mx-auto figtree">
          <div className="grid grid-cols-3">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 xl:mt-16"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-shape1.svg"
                  alt=""
                />
              </div>
              <div className="absolute">
                <img
                  class="xl:w-7/12 xl:mt-4 xl:ml-26 "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-action-img1.png"
                  alt=""
                />
              </div>
            </div>

            <div>
              <p class="text-white xl:text-3xl font-bold xl:mt-24 xl:-ml-4">
                Affordable Online Courses & Learning Opportunities For You
              </p>
            </div>

            <div>
              <a href="/">
                <button className="xl:px-6 xl:py-3 rounded-full bg-white red-on-hover4 xl:mt-28 xl:ml-20 text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>
        </section>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto xl:mt-28 figtree">
        <div className="grid grid-cols-2">
          <div>
            <p class="text-darkblue xl:text-base font-semibold ">
              BECOME AN INSTRUCTOR
            </p>
            <p class="text-darkblack xl:text-4xl font-bold xl:pt-4">
              Let’s Join Us & Spread Your Knowledge
            </p>
            <p class="xl:text-base xl:pt-4">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A<br></br> Type Specimen Bookan Unknown Printer
              Took Alley Ffer Area Typey And Scrambled<br></br> To Make A Type
              Specimen Book Hass
            </p>

            <a href="/">
              <button className="xl:px-6 xl:py-3 rounded-full bg-newshade33 text-white xl:mt-6 red-on-hover4  text-blue">
                Start Learning Today ➔
              </button>
            </a>
          </div>

          <div>
            <img
              class="xl:w-11/12 xl:ml-6"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/become-instructor-600x385.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-24 bg-newshade29 figtree">
        <div>
          <p class="text-darkblue text-center xl:pt-24 font-semibold">
            OUR CLIENTS
          </p>
          <p class="text-darkblack text-center xl:pt-2 text-center xl:text-4xl font-bold">
            What’s Our Real Client Strories About Our <br></br>Work & Passion
          </p>
          <div className="center-button">
            <img
              class="xl:w-2/12 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="xl:text-base text-center xl:pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>

        <div className="md:block hidden ">
          <h2 className="md:mt-10">
            <logo-slider>
              <div className="px-2 ">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-sm pt-4 ml-1">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Neel Eonathon
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className=" pt-12 "
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_04-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal ml-1">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Robert Fox
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12  "
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_02-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal ml-1">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Richard Whalen
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_03-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold head1 text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Neel Eonathon
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_04-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Esther Howard
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_02-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Richard Whalen
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_03-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              {/* newly added */}
            </logo-slider>
          </h2>

          {/* LOGO SLIDER */}
          <h2 className="">
            <logo-slider2>
              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Robert Fox
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_02-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Richard Whalen
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_03-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Neel Eonathon
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_04-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold head1 text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Esther Howard
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12 "
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_02-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Neel Eonathon
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_04-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Richard Whalen
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_03-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>
            </logo-slider2>
          </h2>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden bg-newshade39  xl:mt-24">
        <div className="grid grid-cols-2 container mx-auto">
          <div className=" figtree">
            <p class="text-grey xl:text-base font-semibold xl:pt-24">
              AVAILABLE ON ANDROID & APP STORE
            </p>
            <p class="text-white font-bold xl:text-3xl xl:pt-2">
              Build A Beautiful, Professional-Looking
            </p>
            <p class="text-white font-bold xl:text-3xl ">
              Online Course With Quiklearn
            </p>

            <div className="flex">
              <div className="flex xl:mt-6 border-7 rounded-md  xl:px-4 xl:py-2">
                <div className="">
                  <img class="w-8 " src={playstore} />
                </div>
                <p class="text-white xl:text-base font-bold xl:mt-1 xl:ml-2">
                  Google Play
                </p>
              </div>

              <div className="flex xl:mt-6 border-7 rounded-md  xl:px-4 xl:py-2 xl:ml-4">
                <div className="">
                  <img class="w-8 " src={apple} />
                </div>
                <p class="text-white xl:text-base font-bold xl:mt-2 xl:ml-2">
                  Apple Store
                </p>
              </div>
            </div>
          </div>

          <div>
            <img
              class="xl:w-12/12 xl:pt-24"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/app-download-img1.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-24">
        <div>
          <p class="text-darkblue text-center  font-semibold">TOP ARTICLES</p>
          <p class="text-darkblack text-center xl:pt-2 text-center xl:text-4xl font-bold">
            Want To Learn More? Read Blog
          </p>
          <div className="center-button">
            <img
              class="xl:w-2/12 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="xl:text-base text-center xl:pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-24 container mx-auto xl:pb-24">
        <div className="grid grid-cols-4 xl:gap-2">
          <div className="border-3 bg-white shadow-xl rounded-md xl:pb-6">
            <div class=" ">
              <div class="">
                <img
                  class="xl:w-11/12 rounded-md xl:ml-3 xl:mt-2  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_03-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex xl:mt-8">
              <div className="border-3 xl:w-4/12 rounded-md xl:ml-4 bg-newshade29  ">
                <p class="xl:text-base xl:ml-1">Data Science</p>
              </div>
            </div>

            <p class="xl:text-xl text-darkblack font-bold xl:ml-4 xl:pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex xl:mt-4">
              <p class="text-black xl:ml-4 xl:text-base1">📆</p>
              <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                August 10, 2023
              </p>
              <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
              <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">By Admin</p>
            </div>
          </div>

          <div className="border-3 bg-white shadow-xl rounded-md xl:pb-6 ">
            <div class=" ">
              <div class="">
                <img
                  class="xl:w-11/12 rounded-md xl:ml-3 xl:mt-2  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_04-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex xl:mt-8">
              <div className="border-3 xl:w-4/12 rounded-md xl:ml-4 bg-newshade29  ">
                <p class="xl:text-base xl:ml-2">Art&design</p>
              </div>
            </div>

            <p class="xl:text-xl text-darkblack font-bold xl:ml-4 xl:pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex xl:mt-4">
              <p class="text-black xl:ml-4 xl:text-base1">📆</p>
              <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                June 21, 2023
              </p>
              <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
              <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">By Admin</p>
            </div>
          </div>

          <div className="border-3 bg-white shadow-xl rounded-md xl:pb-6 ">
            <div class=" ">
              <div class="">
                <img
                  class="xl:w-11/12 rounded-md xl:ml-3 xl:mt-2  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_06-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex xl:mt-8">
              <div className="border-3 xl:w-3/12 rounded-md xl:ml-4 bg-newshade29  ">
                <p class="xl:text-base xl:ml-2">Business</p>
              </div>
            </div>

            <p class="xl:text-xl text-darkblack font-bold xl:ml-4 xl:pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex xl:mt-4">
              <p class="text-black xl:ml-4 xl:text-base1">📆</p>
              <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                June 21, 2023
              </p>
              <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
              <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">By Admin</p>
            </div>
          </div>

          <div className="border-3 bg-white shadow-xl rounded-md xl:pb-6">
            <div class=" ">
              <div class="">
                <img
                  class="xl:w-11/12 rounded-md xl:ml-3 xl:mt-2  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex xl:mt-8">
              <div className="border-3 xl:w-4/12 rounded-md xl:ml-4 bg-newshade29  ">
                <p class="xl:text-base xl:ml-1">Data Science</p>
              </div>
            </div>

            <p class="xl:text-xl text-darkblack font-bold xl:ml-4 xl:pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex xl:mt-4">
              <p class="text-black xl:ml-4 xl:text-base1">📆</p>
              <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                June 21, 2023
              </p>
              <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
              <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">By Admin</p>
            </div>
          </div>
        </div>
      </section>

      {/*LAND SCAPE VIEW*/}

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden bg-newshade32 figtree">
        <div className="container mx-auto">
          <p class="text-white text-center text-base1 pt-12 ">
            100% QUALITY COURSES
          </p>
          <p class="text-white text-5xl font-bold pt-4 text-center">
            Find Your Perfect Course
          </p>
          <div class="center-button">
            <img
              class="w-32 ml-90"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/underline-shape.svg"
              alt=""
            />
          </div>
          <p class="text-white text-5xl font-bold pt-2 text-center">
            And Improve Your Skills
          </p>
        </div>

        <div className="border w-10/12 bg-white rounded-full mt-6 p-2 container mx-auto">
          <div className="flex ">
            <p class="text-base font-bold ml-4 mt-3">All Categories</p>

            <div class="custom-select">
              <div class="select-icon">
                <img
                  class=" ml-4 mt-2 "
                  src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%2371717a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e"
                  alt=""
                />
              </div>

              <select class="hidden-select">
                <option value="1">All</option>
                <option value="2">Art & Design</option>
                <option value="3">Business</option>
                <option value="3">Data Science</option>
                <option value="3">Development</option>
                <option value="3">Finance</option>
              </select>
            </div>

            <div class="vl-4"></div>
            <div>
              <p class="text-xs text-grey font-bold ml-4 mt-4">
                Find Your Courses...
              </p>
            </div>

            <div className="border-4 bg-newshade8  rounded-full p-2 ml-104 red-on-hover2">
              <div className="flex">
                <div>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                      />
                    </svg>
                  </p>
                </div>
                Search
              </div>
            </div>
          </div>
        </div>

        <div className="flex mt-12 ">
          <div>
            <img
              class="w-10/12 ml-12 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/hero-shape-17.svg"
              alt=""
            />
          </div>
          <div>
            <img
              class="w-12/12 -ml-60"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/hero-img-3.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden container mx-auto absolute">
        <div className="rounded-md bg-white shadow-xl -mt-16  container mx-auto w-10/12 pb-12">
          <div className="grid grid-cols-2">
            <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-12 w-10/12 ">
                <div className="flex">
                  <div className="bg-newshade34 rounded-full h-16 w-16 mt-4 ml-12">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={art} />
                    </div>
                  </div>
                  <div>
                    <p class="text-xl  font-semibold mt-6 ml-8 ">Art&Design</p>
                    <p class="text-base ml-8">1 Course</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-6 w-10/12 ">
                <div className="flex">
                  <div className="bg-newshade35 rounded-full h-16 w-16 mt-4 ml-12">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={business} />
                    </div>
                  </div>
                  <div>
                    <p class="text-xl  font-semibold mt-6 ml-8 ">Business</p>
                    <p class="text-base ml-8">1 Course</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 mt-6">
            <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-12 w-10/12 ">
                <div className="flex">
                  <div className="bg-newshade36 rounded-full h-16 w-16 mt-4 ml-12">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={development} />
                    </div>
                  </div>
                  <div>
                    <p class="text-xl  font-semibold mt-6 ml-8 ">Development</p>
                    <p class="text-base ml-8">1 Course</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-6 w-10/12 ">
                <div className="flex">
                  <div className="bg-newshade37 rounded-full h-16 w-16 mt-4 ml-12">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={financialsupport} />
                    </div>
                  </div>
                  <div>
                    <p class="text-xl  font-semibold mt-6 ml-8 ">Finance</p>
                    <p class="text-base ml-8">1 Course</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-70">
            <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4  w-6/12  ">
              <div className="flex">
                <div className="bg-newshade38 rounded-full h-16 w-16 mt-4 ml-4">
                  <div className="center-button">
                    <img class="w-8 pt-4" src={technology} />
                  </div>
                </div>
                <div>
                  <p class="text-xl  font-semibold mt-6 ml-4 ">Technology</p>
                  <p class="text-base ml-4">3 Course</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden  figtree  bg-newshade29 pb-16 mt-108 ">
        <p class="text-center text-darkblue text-base font-bold pt-12 ">
          FEATURED COURSES
        </p>
        <p class="text-center text-4xl font-bold text-darkblack pt-2">
          Find Yours From The Featured
        </p>
        <div className="justify-center center-button">
          <img
            class="w-32"
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-base text-center mt-4">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen
        </p>
        <p class="text-base text-center pt-2">
          Book It Has Survived Not Only Five Centuries
        </p>

        <section className="xl:hidden lg:block md:hidden sm:hidden hidden">
          <div className="container mx-auto">
            <div className="grid grid-cols-2 mt-12">
              <div className="border-3 w-10/12 shadow-xl rounded-md  ml-16 pb-8">
                <div class=" ">
                  <div class="">
                    <img
                      class="w-10/12 rounded-md mt-6 ml-8 object-cover hover-scale transition-transform duration-300 ease-in-out"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/07/crs_img_2-380x277.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex mt-6">
                  <div className="border-3 w-3/12 rounded-md ml-8 ">
                    <p class="text-base ml-1">Technology</p>
                  </div>

                  <div className="flex mt-1">
                    <p class="text-xs ml-20">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                  </div>
                </div>

                <p class="text-xl text-darkblack font-bold ml-8 pt-4 blue-on-hover">
                  The Complete Android <br></br>Java Developer Course
                </p>

                <div className="flex mt-6">
                  <div class="">
                    <img
                      class="w-8 h-8 ml-8"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                      alt=""
                    />
                  </div>
                  <p class="text-base ml-4 blue-on-hover  mt-1">
                    Millar Richard
                  </p>
                </div>

                <div className="flex mt-4">
                  <p class="text-black ml-8 text-base1">👤</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">19</p>
                  <p class="text-base1 ml-4 text-black">📄</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">8 ls</p>
                  <p class="text-base1 text-blue font-bold ml-20 ">$55.00</p>
                  <p></p>
                </div>
              </div>

              <div className="border-3 w-10/12 shadow-xl rounded-md ">
                <div class=" ">
                  <div class="">
                    <img
                      class="w-10/12 rounded-md mt-6 ml-8 object-cover hover-scale transition-transform duration-300 ease-in-out"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/07/crs_img_1-380x277.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex mt-6">
                  <div className="border-3 w-3/12 rounded-md ml-8 ">
                    <p class="text-base ml-1">Technology</p>
                  </div>

                  <div className="flex mt-1">
                    <p class="text-xs ml-20">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                  </div>
                </div>

                <p class="text-xl text-darkblack font-bold ml-8 pt-4 blue-on-hover">
                  The Complete Android <br></br>Java Developer Course
                </p>

                <div className="flex mt-6">
                  <div class="">
                    <img
                      class="w-8 h-8 ml-8"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                      alt=""
                    />
                  </div>
                  <p class="text-base ml-4 blue-on-hover  mt-1">
                    Millar Richard
                  </p>
                </div>

                <div className="flex mt-4">
                  <p class="text-black ml-8 text-base1">👤</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">19</p>
                  <p class="text-base1 ml-4 text-black">📄</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">8 ls</p>
                  <p class="text-base1 text-blue font-bold ml-20 ">$55.00</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-24 container mx-auto">
        <div className=" rounded-lg bg-newshade39 pb-4 w-10/12 ml-20">
          <div className="flex">
            <div className="center-button">
              <img
                class="ml-8 mt-8"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/video_banner_02.png.png"
                alt=""
              />
            </div>
            <div>
              <p class="text-white font-bold text-2xl mt-8 ml-10">
                Achieve Your Goals With Quiklearn
              </p>
              <p class="text-base pt-2 text-grey ml-10">
                When An Unknown Printer Took A Galley
              </p>
              <p class="text-base text-grey ml-10">
                Offer Area Type Make Specimen Book Has
              </p>
              <p class="text-base text-grey ml-10">Survived Type Make.</p>

              <a href="/">
                <button class="px-12 py-3 text-white rounded-full mt-4 ml-10 border-6 red-on-hover3 ">
                  Lets Discover ➔
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className=" rounded-lg bg-newshade33 pb-4 w-10/12 ml-20 mt-12">
          <div className="flex">
            <div>
              <div className="center-button ">
                <img
                  class="ml-8 mt-8 rounded-full"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/video_banner_01.png"
                  alt=""
                />
              </div>
              <a href="/">
                <img
                  class="w-16 -mt-32 ml-28 absolute bg-white rounded-full red-on-hover4 "
                  src={videoplay}
                />
              </a>
            </div>
            <div>
              <p class="text-white font-bold text-2xl mt-8 ml-10">
                Expert Instructor Live Classes Here!
              </p>
              <p class="text-base pt-2 text-grey ml-10">
                When An Unknown Printer Took A Galley
              </p>
              <p class="text-base text-grey ml-10">
                Offer Area Type Make Specimen Book Has
              </p>
              <p class="text-base text-grey ml-10">Survived Type Make.</p>

              <a href="/">
                <button class="px-12 py-3 text-blue rounded-full mt-4 ml-10 border-4 bg-white red-on-hover4 ">
                  Take Your Service ➔
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16">
        <div className="center-button">
          <img
            class="w-8/12"
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img1.png"
            alt=""
          />
        </div>
      </div>

      <div className=" xl:hidden lg:block md:hidden sm:hidden hidden mt-8 ml-12 figtree">
        <div>
          <p class="text-base mt-16 font-semibold text-darkblue">
            LEARNERS AND STUDENTS
          </p>
          <p class="text-4xl text-darkblack font-bold pt-4">
            We Teach The Fundamentals Of Art While Encouraging
            Creativity,Curiosity, And Individuality.
          </p>

          <div className="center-button">
            <img
              class="w-40 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>

          <p class="text-base mt-6">
            When An Unknown Printer Took A Galley Offer Area Type And Scrambled
            To Make A Type Specimen Book Has Survived When An Unknown Printer
            Took A Galley Offer Area Type And Scrambled To Make.
          </p>

          <a href="/">
            <button class="px-12 py-3 text-white rounded-full mt-4  border-7 bg-newshade33 red-on-hover4 ">
              Explore All Courses ➔
            </button>
          </a>
        </div>
      </div>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden bg-newshade39 mt-20 figtree pb-12">
        <section className="container mx-auto">
          <div className="pt-12">
            <div>
              <p class="text-base text-grey font-semibold text-center">
                WHY CHOOSE US
              </p>
              <p class="text-4xl text-white font-bold pt-2 text-center">
                Dont Know How To Start With
              </p>
              <div className="center-button">
                <img
                  class="w-52  "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
                  alt=""
                />
              </div>
              <p class="text-4xl text-white font-bold text-center">
                QuickLearn Courses
              </p>
              <p class="text-base text-grey pt-4 text-center">
                When An Unknown Printer Took A Galley Offer Area Type And
                <br></br> Scrambled To Make A Type Specimen Book .
              </p>

              <div className="flex mt-8 justify-center">
                <div className="bg-newshade33 rounded-full w-8 h-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold">
                  Special Lessons And Courses
                </p>

                <div className="bg-newshade33 rounded-full w-8 h-8 ml-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold">
                  Get Every General Answers
                </p>
              </div>

              <div className="flex mt-4 justify-center">
                <div className="bg-newshade33 rounded-full w-8 h-8 ">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold">
                  Special Lessons And Courses
                </p>

                <div className="bg-newshade33 rounded-full w-8 h-8 ml-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold">
                  Get Every General Answers
                </p>
              </div>

              <div className="flex mt-4 justify-center">
                <div className="bg-newshade33 rounded-full w-8 h-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold">
                  Special Lessons And Courses
                </p>

                <div className="bg-newshade33 rounded-full w-8 h-8 ml-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold">
                  Get Every General Answers
                </p>
              </div>

              <div className="center-button">
                <a href="/">
                  <button class="px-12 py-3 text-darkblack rounded-full mt-8  bg-white red-on-hover3 ">
                    Explore All Courses ➔
                  </button>
                </a>
              </div>
            </div>

            <div className="center-button">
              <img
                class="w-10/12"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/09/h1about-768x557.png"
                alt=""
              />
            </div>
          </div>
        </section>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden container mx-auto mt-24 figtree">
        <p class="text-center  text-base font-semibold text-darkblue">
          OUR INSTRUCTORS
        </p>
        <p class="text-darkblack text-center pt-2 font-bold text-4xl">
          From The Quiklearn Community
        </p>
        <div className="center-button">
          <img
            class="w-32  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center text-base mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen<br></br>
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="grid grid-cols-4 mt-12 ">
          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl ml-4 ">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/9/a87cce35aa3a4cb374ad14c92334a35e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-40"
                onMouseEnter={() => setShowIcons(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-8 black-on-hover">
                Richard David
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                Marketing Expert
              </p>

              <div className="flex mt-10 ">
                <p class="text-black ml-6 text-base1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>
              </div>
              <div className="flex  pb-4">
                <p class="text-base1 ml-6 text-black">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">16 students</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl  ml-2">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-40"
                onMouseEnter={() => setShowIcons1(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons1(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons1 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Millar Richard
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">Business</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>
              </div>
              <div className="flex pb-4">
                <p class="text-base1 ml-6 text-black">👤</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">23 students</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl ml-2 ">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/8/8d69d3fc4b9ecc16660fc9e32192e9e1.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-40"
                onMouseEnter={() => setShowIcons2(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons2(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons2 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Kristin Watson
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">UX/UI Expert</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 course</p>
              </div>
              <div className="flex">
                <p class="text-base1 ml-6 text-black">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 student</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl ml-2 mr-4">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/7/84ce06432355d2548c4c2d534bb8873e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-40"
                onMouseEnter={() => setShowIcons3(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons3(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons3 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Jacob Jones
              </p>
              <p class="text-grey-600 text-sm text-center ">UX/UI Designer</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1">📄</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">2 courses</p>
              </div>
              <div className="flex">
                <p class="text-base1 ml-6 text-black">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">8 students</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 bg-newshade33">
        <section className="container mx-auto figtree">
          <div className="grid grid-cols-3">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 mt-16"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-shape1.svg"
                  alt=""
                />
              </div>
              <div className="absolute">
                <img
                  class="w-6/12 mt-4 ml-26 "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-action-img1.png"
                  alt=""
                />
              </div>
            </div>

            <div>
              <p class="text-white text-3xl font-bold mt-12 ">
                Affordable Online Courses & Learning Opportunities For You
              </p>
            </div>

            <div>
              <a href="/">
                <button className="px-6 py-3 rounded-full bg-white red-on-hover4 mt-16 ml-16 text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>
        </section>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden container mx-auto mt-20 figtree">
        <div className="">
          <div>
            <p class="text-darkblue text-base font-semibold text-center">
              BECOME AN INSTRUCTOR
            </p>
            <p class="text-darkblack text-4xl font-bold pt-4 text-center">
              Let’s Join Us & Spread Your Knowledge
            </p>
            <p class="text-base pt-4 text-center">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A<br></br> Type Specimen Bookan Unknown Printer
              Took Alley Ffer Area Typey And Scrambled<br></br> To Make A Type
              Specimen Book Hass
            </p>

            <div className="center-button">
              <a href="/">
                <button className="px-6 py-3 rounded-full bg-newshade33 text-white mt-6 red-on-hover4  text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>

          <div className="center-button">
            <img
              class="w-10/12 mt-12"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/become-instructor-600x385.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-24 bg-newshade29 figtree pb-16">
        <div>
          <p class="text-darkblue text-center pt-16 font-semibold">
            OUR CLIENTS
          </p>
          <p class="text-darkblack text-center pt-2 text-center text-4xl font-bold">
            What’s Our Real Client Strories About Our <br></br>Work & Passion
          </p>
          <div className="center-button">
            <img
              class="w-32 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="text-base text-center pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden bg-newshade39  mt-24 pb-16">
        <div className="container mx-auto">
          <div className=" figtree">
            <p class="text-grey text-base font-semibold pt-16 text-center">
              AVAILABLE ON ANDROID & APP STORE
            </p>
            <p class="text-white font-bold text-3xl pt-2 text-center">
              Build A Beautiful, Professional-Looking
            </p>
            <p class="text-white font-bold text-3xl  text-center">
              Online Course With Quiklearn
            </p>

            <div className="flex justify-center">
              <div className="flex mt-6 border-7 rounded-md  px-4 py-2">
                <div className="">
                  <img class="w-8 " src={playstore} />
                </div>
                <p class="text-white text-base font-bold mt-1 ml-2">
                  Google Play
                </p>
              </div>

              <div className="flex mt-6 border-7 rounded-md  px-4 py-2 ml-4">
                <div className="">
                  <img class="w-8 " src={apple} />
                </div>
                <p class="text-white text-base font-bold mt-2 ml-2">
                  Apple Store
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-24">
        <div>
          <p class="text-darkblue text-center  font-semibold">TOP ARTICLES</p>
          <p class="text-darkblack text-center pt-2 text-center text-4xl font-bold">
            Want To Learn More? Read Blog
          </p>
          <div className="center-button">
            <img
              class="w-32 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="text-base text-center pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden xl:mt-24 container mx-auto xl:pb-24">
        <div className="grid grid-cols-2 mt-12 ">
          <div className=" bg-white shadow-xl rounded-md pb-6 ml-6">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-10 mt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_03-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-2">Data Science</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 text-base1 pt-1">📆</p>
              <p class="ml-2 xl:text-base text-grey-600 mt-1">
                August 10, 2023
              </p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>

          <div className=" bg-white shadow-xl rounded-md pb-6  ml-4 mr-4">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-10 mt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_04-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-2">Art&design</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 text-base1 pt-1">📆</p>
              <p class="ml-2 xl:text-base text-grey-600 mt-1">June 21, 2023</p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-12 pb-16">
          <div className=" bg-white shadow-xl rounded-md pb-6 ml-6 ">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-10 mt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_06-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-2">Business</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 xl:text-base1 pt-1 ">📆</p>
              <p class="ml-2 text-base text-grey-600 mt-1">June 21, 2023</p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>

          <div className=" bg-white shadow-xl rounded-md pb-6 ml-4 mr-4">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-10 mt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-1">Data Science</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 xl:text-base1 pt-1">📆</p>
              <p class="ml-2 xl:text-base text-grey-600 mt-1">June 21, 2023</p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>
        </div>
      </section>

      {/*TAB VIEW*/}
      <section className="xl:hidden lg:hidden md:block sm:hidden hidden bg-newshade32 figtree">
        <div className="container mx-auto">
          <p class="text-white text-center text-base1 pt-12 ">
            100% QUALITY COURSES
          </p>
          <p class="text-white text-5xl font-bold pt-4 text-center">
            Find Your Perfect Course
          </p>
          <div class="center-button">
            <img
              class="w-32 ml-90"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/underline-shape.svg"
              alt=""
            />
          </div>
          <p class="text-white text-5xl font-bold pt-2 text-center">
            And Improve Your Skills
          </p>
        </div>

        <div className="border w-10/12 bg-white rounded-full mt-6 p-2 container mx-auto">
          <div className="flex ">
            <p class="text-base font-bold ml-4 mt-3">All Categories</p>

            <div class="custom-select">
              <div class="select-icon">
                <img
                  class=" ml-4 mt-2 "
                  src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%2371717a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e"
                  alt=""
                />
              </div>

              <select class="hidden-select">
                <option value="1">All</option>
                <option value="2">Art & Design</option>
                <option value="3">Business</option>
                <option value="3">Data Science</option>
                <option value="3">Development</option>
                <option value="3">Finance</option>
              </select>
            </div>

            <div class="vl-4"></div>
            <div>
              <p class="text-xs text-grey font-bold ml-4 mt-4">
                Find Your Courses...
              </p>
            </div>

            <div className="border-4 bg-newshade8  rounded-full p-2 ml-52 red-on-hover2">
              <div className="flex">
                <div>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                      />
                    </svg>
                  </p>
                </div>
                Search
              </div>
            </div>
          </div>
        </div>

        <div className="flex mt-12 ">
          <div>
            <img
              class="w-8/12 ml-16 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/hero-shape-17.svg"
              alt=""
            />
          </div>
          <div>
            <img
              class="w-12/12 -ml-60"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/hero-img-3.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto absolute">
        <div className="rounded-md bg-white shadow-xl -mt-16  container mx-auto w-10/12 pb-12">
          <div className="grid grid-cols-2">
            <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-12 w-10/12 ">
                <div className="flex">
                  <div className="bg-newshade34 rounded-full h-16 w-16 mt-4 ml-8">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={art} />
                    </div>
                  </div>
                  <div>
                    <p class="text-xl  font-semibold mt-6 ml-6 ">Art&Design</p>
                    <p class="text-base ml-6">1 Course</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-6 w-10/12 ">
                <div className="flex">
                  <div className="bg-newshade35 rounded-full h-16 w-16 mt-4 ml-8">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={business} />
                    </div>
                  </div>
                  <div>
                    <p class="text-xl  font-semibold mt-6 ml-6 ">Business</p>
                    <p class="text-base ml-6">1 Course</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 mt-6">
            <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-12 w-10/12 ">
                <div className="flex">
                  <div className="bg-newshade36 rounded-full h-16 w-16 mt-4 ml-8">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={development} />
                    </div>
                  </div>
                  <div>
                    <p class="text-xl  font-semibold mt-6 ml-6 ">Development</p>
                    <p class="text-base ml-6">1 Course</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-6 w-10/12 ">
                <div className="flex">
                  <div className="bg-newshade37 rounded-full h-16 w-16 mt-4 ml-8">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={financialsupport} />
                    </div>
                  </div>
                  <div>
                    <p class="text-xl  font-semibold mt-6 ml-6 ">Finance</p>
                    <p class="text-base ml-6">1 Course</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-52">
            <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4  w-6/12  ">
              <div className="flex">
                <div className="bg-newshade38 rounded-full h-16 w-16 mt-4 ml-4">
                  <div className="center-button">
                    <img class="w-8 pt-4" src={technology} />
                  </div>
                </div>
                <div>
                  <p class="text-xl  font-semibold mt-6 ml-4 ">Technology</p>
                  <p class="text-base ml-4">3 Course</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden  figtree  bg-newshade29 pb-16 mt-108 ">
        <p class="text-center text-darkblue text-base font-bold pt-12 ">
          FEATURED COURSES
        </p>
        <p class="text-center text-4xl font-bold text-darkblack pt-2">
          Find Yours From The Featured
        </p>
        <div className="justify-center center-button">
          <img
            class="w-32"
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-base text-center mt-4">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen
        </p>
        <p class="text-base text-center pt-2">
          Book It Has Survived Not Only Five Centuries
        </p>

        <section className="xl:hidden lg:hidden md:block sm:hidden hidden">
          <div className="container mx-auto">
            <div className="grid grid-cols-2 mt-12">
              <div className="border-3 w-10/12 shadow-xl rounded-md  ml-12 pb-8">
                <div class=" ">
                  <div class="">
                    <img
                      class="w-10/12 rounded-md mt-6 ml-8 object-cover hover-scale transition-transform duration-300 ease-in-out"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/07/crs_img_2-380x277.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex mt-6">
                  <div className="border-3 w-3/12 rounded-md ml-8 ">
                    <p class="text-base ml-1">Technology</p>
                  </div>

                  <div className="flex mt-1">
                    <p class="text-xs ml-12">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                  </div>
                </div>

                <p class="text-xl text-darkblack font-bold ml-8 pt-4 blue-on-hover">
                  The Complete Android <br></br>Java Developer Course
                </p>

                <div className="flex mt-6">
                  <div class="">
                    <img
                      class="w-8 h-8 ml-8"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                      alt=""
                    />
                  </div>
                  <p class="text-base ml-4 blue-on-hover  mt-1">
                    Millar Richard
                  </p>
                </div>

                <div className="flex mt-4">
                  <p class="text-black ml-8 text-base1 pt-1">👤</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">19</p>
                  <p class="text-base1 ml-4 text-black pt-1">📄</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">8 ls</p>
                  <p class="text-base1 text-blue font-bold ml-20 pt-1 ">
                    $55.00
                  </p>
                  <p></p>
                </div>
              </div>

              <div className="border-3 w-10/12 shadow-xl rounded-md ml-4 ">
                <div class=" ">
                  <div class="">
                    <img
                      class="w-10/12 rounded-md mt-6 ml-8 object-cover hover-scale transition-transform duration-300 ease-in-out"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/07/crs_img_1-380x277.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex mt-6">
                  <div className="border-3 w-3/12 rounded-md ml-8 ">
                    <p class="text-base ml-1">Technology</p>
                  </div>

                  <div className="flex mt-1">
                    <p class="text-xs ml-12">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                  </div>
                </div>

                <p class="text-xl text-darkblack font-bold ml-8 pt-4 blue-on-hover">
                  The Complete Android <br></br>Java Developer Course
                </p>

                <div className="flex mt-6">
                  <div class="">
                    <img
                      class="w-8 h-8 ml-8"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                      alt=""
                    />
                  </div>
                  <p class="text-base ml-4 blue-on-hover  mt-1">
                    Millar Richard
                  </p>
                </div>

                <div className="flex mt-4">
                  <p class="text-black ml-8 text-base1 pt-1">👤</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">19</p>
                  <p class="text-base1 ml-4 text-black pt-1">📄</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">8 ls</p>
                  <p class="text-base1 text-blue font-bold ml-20 pt-1 ">
                    $55.00
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-24 container mx-auto">
        <div className=" rounded-lg bg-newshade39 pb-4 w-10/12 ml-16">
          <div className="flex">
            <div className="center-button">
              <img
                class="ml-8 mt-8"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/video_banner_02.png.png"
                alt=""
              />
            </div>
            <div>
              <p class="text-white font-bold text-2xl mt-8 ml-10">
                Achieve Your Goals With Quiklearn
              </p>
              <p class="text-base pt-2 text-grey ml-10">
                When An Unknown Printer Took A Galley
              </p>
              <p class="text-base text-grey ml-10">
                Offer Area Type Make Specimen Book Has
              </p>
              <p class="text-base text-grey ml-10">Survived Type Make.</p>

              <a href="/">
                <button class="px-12 py-3 text-white rounded-full mt-4 ml-10 border-6 red-on-hover3 ">
                  Lets Discover ➔
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className=" rounded-lg bg-newshade33 pb-4 w-10/12 ml-16 mt-12">
          <div className="flex">
            <div>
              <div className="center-button ">
                <img
                  class="ml-8 mt-8 rounded-full"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/video_banner_01.png"
                  alt=""
                />
              </div>
              <a href="/">
                <img
                  class="w-16 -mt-32 ml-28 absolute bg-white rounded-full red-on-hover4 "
                  src={videoplay}
                />
              </a>
            </div>
            <div>
              <p class="text-white font-bold text-2xl mt-8 ml-10">
                Expert Instructor Live Classes Here!
              </p>
              <p class="text-base pt-2 text-grey ml-10">
                When An Unknown Printer Took A Galley
              </p>
              <p class="text-base text-grey ml-10">
                Offer Area Type Make Specimen Book Has
              </p>
              <p class="text-base text-grey ml-10">Survived Type Make.</p>

              <a href="/">
                <button class="px-12 py-3 text-blue rounded-full mt-4 ml-10 border-4 bg-white red-on-hover4 ">
                  Take Your Service ➔
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16">
        <div className="center-button">
          <img
            class="w-8/12"
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img1.png"
            alt=""
          />
        </div>
      </div>

      <div className=" xl:hidden lg:hidden md:block sm:hidden hidden mt-8 ml-12 figtree">
        <div>
          <p class="text-base mt-16 font-semibold text-darkblue">
            LEARNERS AND STUDENTS
          </p>
          <p class="text-4xl text-darkblack font-bold pt-4">
            We Teach The Fundamentals Of Art While<br></br> Encouraging
            Creativity,Curiosity, And<br></br> Individuality.
          </p>

          <div className="center-button">
            <img
              class="w-40 -mt-16"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>

          <p class="text-base mt-6">
            When An Unknown Printer Took A Galley Offer Area Type And Scrambled
            To Make A<br></br> Type Specimen Book Has Survived When An Unknown
            Printer Took A Galley Offer<br></br> Area Type And Scrambled To
            Make.
          </p>

          <a href="/">
            <button class="px-12 py-3 text-white rounded-full mt-6  border-7 bg-newshade33 red-on-hover4 ">
              Explore All Courses ➔
            </button>
          </a>
        </div>
      </div>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden bg-newshade39 mt-20 figtree pb-12">
        <section className="container mx-auto">
          <div className="pt-12">
            <div>
              <p class="text-base text-grey font-semibold text-center">
                WHY CHOOSE US
              </p>
              <p class="text-4xl text-white font-bold pt-2 text-center">
                Dont Know How To Start With
              </p>
              <div className="center-button">
                <img
                  class="w-52  "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
                  alt=""
                />
              </div>
              <p class="text-4xl text-white font-bold text-center">
                QuickLearn Courses
              </p>
              <p class="text-base text-grey pt-4 text-center">
                When An Unknown Printer Took A Galley Offer Area Type And
                <br></br> Scrambled To Make A Type Specimen Book .
              </p>

              <div className="flex mt-8 justify-center">
                <div className="bg-newshade33 rounded-full w-8 h-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold pt-1">
                  Special Lessons And Courses
                </p>

                <div className="bg-newshade33 rounded-full w-8 h-8 ml-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold pt-1">
                  Get Every General Answers
                </p>
              </div>

              <div className="flex mt-4 justify-center">
                <div className="bg-newshade33 rounded-full w-8 h-8 ">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold pt-1">
                  Special Lessons And Courses
                </p>

                <div className="bg-newshade33 rounded-full w-8 h-8 ml-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold pt-1">
                  Get Every General Answers
                </p>
              </div>

              <div className="flex mt-4 justify-center">
                <div className="bg-newshade33 rounded-full w-8 h-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold pt-1">
                  Special Lessons And Courses
                </p>

                <div className="bg-newshade33 rounded-full w-8 h-8 ml-8">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base1 text-white ml-2 font-bold pt-1">
                  Get Every General Answers
                </p>
              </div>

              <div className="center-button">
                <a href="/">
                  <button class="px-12 py-3 text-darkblack rounded-full mt-8  bg-white red-on-hover3 ">
                    Explore All Courses ➔
                  </button>
                </a>
              </div>
            </div>

            <div className="center-button">
              <img
                class="w-10/12"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/09/h1about-768x557.png"
                alt=""
              />
            </div>
          </div>
        </section>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto mt-24 figtree">
        <p class="text-center  text-base font-semibold text-darkblue">
          OUR INSTRUCTORS
        </p>
        <p class="text-darkblack text-center pt-2 font-bold text-4xl">
          From The Quiklearn Community
        </p>
        <div className="center-button">
          <img
            class="w-32  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center text-base mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen<br></br>
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="grid grid-cols-2 mt-12 ">
          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl ml-6 mr-4 ">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/9/a87cce35aa3a4cb374ad14c92334a35e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-8 black-on-hover">
                Richard David
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                Marketing Expert
              </p>

              <div className="flex mt-10 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">16 students</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl  ml-2 mr-4">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons1(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons1(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons1 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Millar Richard
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">Business</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">23 students</p>
              </div>
            </div>
          </div>
        </div>


        <div className="grid grid-cols-2 mt-12">
          <div className="  bg-newshade29 rounded-md  shadow-xl ml-6 mr-4 ">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/8/8d69d3fc4b9ecc16660fc9e32192e9e1.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons2(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons2(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons2 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Kristin Watson
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">UX/UI Expert</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 course</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 student</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl ml-2 mr-4">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/7/84ce06432355d2548c4c2d534bb8873e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons3(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons3(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons3 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Jacob Jones
              </p>
              <p class="text-grey-600 text-sm text-center ">UX/UI Designer</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">8 students</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20 bg-newshade33">
        <section className="container mx-auto figtree">
          <div className="">
            <div className="flex center-button">
              <div>
                <img
                  class="w-10/12 mt-16"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-shape1.svg"
                  alt=""
                />
              </div>
              <div className="absolute">
                <img
                  class="w-10/12 mt-4  "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-action-img1.png"
                  alt=""
                />
              </div>
            </div>

            <div>
              <p class="text-white text-2xl font-bold mt-12 text-center">
                Affordable Online Courses & Learning Opportunities For You
              </p>
            </div>

            <div className="center-button pb-8">
              <a href="/">
                <button className="px-6 py-3 rounded-full bg-white red-on-hover4 mt-12 ml-4 text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>
        </section>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto mt-20 figtree">
        <div className="">
          <div>
            <p class="text-darkblue text-base font-semibold text-center">
              BECOME AN INSTRUCTOR
            </p>
            <p class="text-darkblack text-4xl font-bold pt-4 text-center">
              Let’s Join Us & Spread Your Knowledge
            </p>
            <p class="text-base pt-4 text-center">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A<br></br> Type Specimen Bookan Unknown Printer
              Took Alley Ffer Area Typey And Scrambled<br></br> To Make A Type
              Specimen Book Hass
            </p>

            <div className="center-button">
              <a href="/">
                <button className="px-6 py-3 rounded-full bg-newshade33 text-white mt-6 red-on-hover4  text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>

          <div className="center-button">
            <img
              class="w-10/12 mt-12"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/become-instructor-600x385.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20 bg-newshade29 figtree pb-16">
        <div>
          <p class="text-darkblue text-center pt-16 font-semibold">
            OUR CLIENTS
          </p>
          <p class="text-darkblack text-center pt-2 text-center text-4xl font-bold">
            What’s Our Real Client Strories About Our <br></br>Work & Passion
          </p>
          <div className="center-button">
            <img
              class="w-32 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="text-base text-center pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden bg-newshade39  mt-20 pb-16">
        <div className="container mx-auto">
          <div className=" figtree">
            <p class="text-grey text-base font-semibold pt-16 text-center">
              AVAILABLE ON ANDROID & APP STORE
            </p>
            <p class="text-white font-bold text-3xl pt-2 text-center">
              Build A Beautiful, Professional-Looking
            </p>
            <p class="text-white font-bold text-3xl  text-center">
              Online Course With Quiklearn
            </p>

            <div className="flex justify-center">
              <div className="flex mt-6 border-7 rounded-md  px-4 py-2">
                <div className="">
                  <img class="w-8 " src={playstore} />
                </div>
                <p class="text-white text-base font-bold mt-1 ml-2">
                  Google Play
                </p>
              </div>

              <div className="flex mt-6 border-7 rounded-md  px-4 py-2 ml-4">
                <div className="">
                  <img class="w-8 " src={apple} />
                </div>
                <p class="text-white text-base font-bold mt-2 ml-2">
                  Apple Store
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-24">
        <div>
          <p class="text-darkblue text-center  font-semibold">TOP ARTICLES</p>
          <p class="text-darkblack text-center pt-2 text-center text-4xl font-bold">
            Want To Learn More? Read Blog
          </p>
          <div className="center-button">
            <img
              class="w-32 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="text-base text-center pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden xl:mt-24 container mx-auto xl:pb-24">
        <div className="grid grid-cols-2 mt-12 ">
          <div className=" bg-white shadow-xl rounded-md pb-6 ml-6">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-8 mt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_03-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-2">Data Science</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 text-base1 pt-1">📆</p>
              <p class="ml-2 xl:text-base text-grey-600 mt-1">
                August 10, 2023
              </p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>

          <div className=" bg-white shadow-xl rounded-md pb-6  ml-4 mr-4">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-8 mt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_04-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-2">Art&design</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 text-base1 pt-1">📆</p>
              <p class="ml-2 xl:text-base text-grey-600 mt-1">June 21, 2023</p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-12 pb-16">
          <div className=" bg-white shadow-xl rounded-md pb-6 ml-6 ">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-8 mt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_06-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-2">Business</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 xl:text-base1 pt-1 ">📆</p>
              <p class="ml-2 text-base text-grey-600 mt-1">June 21, 2023</p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>

          <div className=" bg-white shadow-xl rounded-md pb-6 ml-4 mr-4">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-8 mt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-1">Data Science</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 xl:text-base1 pt-1">📆</p>
              <p class="ml-2 xl:text-base text-grey-600 mt-1">June 21, 2023</p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>
        </div>
      </section>

      {/*MOBILE VIEW*/}
      <section className="xl:hidden lg:hidden md:hidden sm:block block container mx-auto bg-newshade33 figtree">
        <div>
          <p class="text-base text-white text-center pt-8 font-semibold">
            100% QUALITY COURSES
          </p>
          <p class="text-3xl text-white font-bold pt-2 text-center">
            Find Your Perfect<br></br> Course And Improve<br></br> Your Skills
          </p>
        </div>
        <div className="border-white bg-white rounded-md shadow-xl w-10/12 ml-8 pb-4 mt-2">
          <div className="pt-4 center-button">
            <div className="border-4 rounded-full w-8/12   ">
              <div className="flex p-2">
                <p class="text-sm font-semibold ml-6">All Categories</p>
              </div>
            </div>
          </div>

          <div className="pt-2 center-button">
            <div className="border-4 rounded-full w-8/12 p-2  ">
              <div className=" ">
                <p class="text-sm text-grey font-semibold ml-6">
                  Find Your Courses...
                </p>
              </div>
            </div>
          </div>

          <div className="pt-2 center-button">
           <div className="border-4 rounded-full w-8/12 p-2 bg-newshade8 red-on-hover2">
           <div className="flex center-button">
                      <div>
                        <p class="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                            />
                          </svg>
                        </p>
                      </div>
                      Search
                    </div>
           </div>
          </div>
        </div>

          
        <div>
          <img class="w-6/12 mt-8" src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/hero-shape-17.svg" alt=""/>
        </div>
        <div className="">
          <img class="w-10/12 -mt-24 ml-6" src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/hero-img-3.png" alt=""/>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block ">
        <div className=" ">
        <div className=" bg-white rounded-md shadow-xl -mt-20  w-10/12 absolute ml-8 pb-4">
        <div>
              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-6 w-10/12">
                <div className="flex">
                  <div className="bg-newshade34 rounded-full h-16 w-16 mt-4 ml-4">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={art} />
                    </div>
                  </div>
                  <div>
                    <p class="text-base1  font-semibold mt-6 ml-4 ">
                      Art&Design
                    </p>
                    <p class="text-base  ml-4">1 Course</p>
                  </div>
                </div>
              </div>


              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-6 w-10/12">
                <div className="flex">
                  <div className="bg-newshade35 rounded-full h-16 w-16 mt-4 ml-4">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={business} />
                    </div>
                  </div>
                  <div>
                    <p class="text-base1  font-semibold mt-6 ml-4 ">
                    Business
                    </p>
                    <p class="text-base  ml-4">3 Courses</p>
                  </div>
                </div>
              </div>

              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-6 w-10/12">
                <div className="flex">
                  <div className="bg-newshade36 rounded-full h-16 w-16 mt-4 ml-4">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={development} />
                    </div>
                  </div>
                  <div>
                    <p class="text-base1  font-semibold mt-6 ml-4 ">
                    Development
                    </p>
                    <p class="text-base  ml-4">2 Courses</p>
                  </div>
                </div>
              </div>

              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-6 w-10/12">
                <div className="flex">
                  <div className="bg-newshade37 rounded-full h-16 w-16 mt-4 ml-4">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={financialsupport} />
                    </div>
                  </div>
                  <div>
                    <p class="text-base1  font-semibold mt-6 ml-4 ">
                    Finance
                    </p>
                    <p class="text-base  ml-4">2 Courses</p>
                  </div>
                </div>
              </div>


              <div className="rounded-md bg-newshade25 red-on-hover4 mt-8 pb-4 ml-6 w-10/12">
                <div className="flex">
                  <div className="bg-newshade38 rounded-full h-16 w-16 mt-4 ml-4">
                    <div className="center-button">
                      <img class="w-8 pt-4" src={technology} />
                    </div>
                  </div>
                  <div>
                    <p class="text-base1  font-semibold mt-6 ml-4 ">
                    Technology
                    </p>
                    <p class="text-base  ml-4">3 Courses</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
      </section>


      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-160">
      <p class="text-center text-darkblue text-base font-bold pt-12 ">
          FEATURED COURSES
        </p>
        <p class="text-center text-3xl font-bold text-darkblack pt-2">
          Find Yours From The Featured
        </p>
        <div className="justify-center center-button">
          <img
            class="w-32"
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-sm text-center mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen
        </p>
        <p class="text-sm pt-1 text-center ">
          Book It Has Survived Not Only Five Centuries
        </p>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-8 container mx-auto">
          <div>
          <div className="border-3 w-10/12 shadow-xl rounded-md  ml-8 pb-8">
                <div class=" ">
                  <div class="">
                    <img
                      class="w-10/12 rounded-md mt-6 ml-8 object-cover hover-scale transition-transform duration-300 ease-in-out"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/07/crs_img_2-380x277.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex mt-6">
                  <div className="border-3 w-3/12 rounded-md ml-8 ">
                    <p class="text-base ml-1">Technology</p>
                  </div>

                  <div className="flex mt-1">
                    <p class="text-xs ml-16">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                    <p class="text-xs ml-2">⭐</p>
                  </div>
                </div>

                <p class="text-xl text-darkblack font-bold ml-8 pt-4 blue-on-hover">
                  The Complete Android <br></br>Java Developer Course
                </p>

                <div className="flex mt-6">
                  <div class="">
                    <img
                      class="w-8 h-8 ml-8"
                      src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                      alt=""
                    />
                  </div>
                  <p class="text-base ml-4 blue-on-hover  mt-1">
                    Millar Richard
                  </p>
                </div>

                <div className="flex mt-4">
                  <p class="text-black ml-8 text-base1 pt-1">👤</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">19</p>
                  <p class="text-base1 ml-4 text-black pt-1">📄</p>
                  <p class="ml-2 text-base text-grey-600 mt-1">8 ls</p>
                  <p class="text-base1 text-blue font-bold ml-20 pt-1 ">
                    $55.00
                  </p>
                  <p></p>
                </div>
              </div>
          </div>
      </section>


      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 container mx-auto bg-newshade39">
      <div className="center-button">
              <img
                class=" mt-8 w-10/12"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/video_banner_02.png.png"
                alt=""
              />
            </div>

            <div>
              <p class="text-white font-bold text-3xl mt-8 text-center ">
                Achieve Your Goals<br></br> With Quiklearn
              </p>
              <p class="text-base pt-2 text-grey text-center ">
                When An Unknown Printer Took A Galley
              </p>
              <p class="text-base text-grey text-center">
                Offer Area Type Make Specimen Book Has
              </p>
              <p class="text-base text-grey text-center">Survived Type Make.</p>

               <div className="center-button pb-8">
              <a href="/">
                <button class="px-12 py-3 text-white rounded-full mt-4  border-6 red-on-hover3 ">
                  Lets Discover ➔
                </button>
              </a>
              </div>
            </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 container mx-auto bg-newshade33">
      <div>
              <div className="center-button ">
                <img
                  class=" mt-8 rounded-full w-10/12"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/video_banner_01.png"
                  alt=""
                />
              </div>
              <a href="/">
                <img
                  class="w-16 -mt-48 ml-40 absolute bg-white rounded-full red-on-hover4 "
                  src={videoplay}
                />
              </a>

              <p class="text-white font-bold text-3xl mt-8 text-center ">
                Expert Instructor Live Classes Here!
              </p>
              <p class="text-base pt-2 text-grey text-center ">
                When An Unknown Printer Took A Galley
              </p>
              <p class="text-base text-grey text-center ">
                Offer Area Type Make Specimen Book Has
              </p>
              <p class="text-base text-grey  text-center">Survived Type Make.</p>
              
              <div className="center-button pb-8">
              <a href="/">
                <button class="px-12 py-3 text-blue rounded-full mt-4  border-4 bg-white red-on-hover4 ">
                  Take Your Service ➔
                </button>
              </a>
              </div>
            </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 container mx-auto">
          <div className="center-button">
            <img class="w-12/12 ml-4"src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img1.png" alt=""/>
          </div>
 
          <div className=" xl:hidden lg:hidden md:hidden sm:block block mt-8  figtree">
        <div>
          <p class="text-base mt-16 font-semibold text-darkblue text-center">
            LEARNERS AND STUDENTS
          </p>
          <p class="text-3xl text-darkblack font-bold pt-4 text-center">
            We Teach The Fundamentals Of Art<br></br> While Encouraging
            Creativity, Curiosity,<br></br> And Individuality.
          </p>

          <div className="center-button">
            <img
              class="w-40 -mt-16"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>

          <p class="text-sm mt-6 text-center px-8">
            When An Unknown Printer Took A Galley Offer Area Type And Scrambled
            To Make A Type Specimen Book Has Survived When An Unknown
            Printer Took A Galley Offer Area Type And Scrambled To
            Make.
          </p>
           
           <div className="center-button">
          <a href="/">
            <button class="px-12 py-3 text-white rounded-full mt-6  border-7 bg-newshade33 red-on-hover4 ">
              Explore All Courses ➔
            </button>
          </a>
          </div>
        </div>
      </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 container mx-auto bg-newshade39">
      <p class="text-base text-grey font-semibold text-center pt-12">
                WHY CHOOSE US
              </p>
              <p class="text-3xl text-white font-bold pt-2 text-center">
                Dont Know How To <br></br>Start With
              </p>
              <div className="center-button">
                <img
                  class="w-52  "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
                  alt=""
                />
              </div>
              <p class="text-3xl text-white font-bold text-center">
                QuickLearn Courses
              </p>
              <p class="text-sm text-grey pt-4 text-center px-8">
                When An Unknown Printer Took A Galley Offer Area Type And
                 Scrambled To Make A Type Specimen Book .
              </p>

              <div className="flex mt-6 justify-center">
              <div className="bg-newshade33 rounded-full w-6 h-6 ">
                  <p class="text-white font-bold xl:text-xs text-center ">
                    ✓
                  </p>
                </div>
                <p class="text-base text-white ml-2 font-bold">
                  Special Lessons And Courses
                </p>
            </div>

            <div className="flex mt-2 justify-center">
            <div className="bg-newshade33 rounded-full w-6 h-6 ">
                  <p class="text-white font-bold xl:text-xs text-center ">
                    ✓
                  </p>
                </div>
                <p class="text-base text-white ml-2 font-bold">
                  Special Lessons And Courses
                </p>
            </div>

            <div className="flex mt-2 justify-center">
            <div className="bg-newshade33 rounded-full w-6 h-6 ">
                  <p class="text-white font-bold xl:text-xs text-center ">
                    ✓
                  </p>
                </div>
                <p class="text-base text-white ml-2 font-bold">
                  Special Lessons And Courses
                </p>
            </div>

            <div className="flex mt-2 justify-center">
            <div className="bg-newshade33 rounded-full w-6 h-6 ">
                  <p class="text-white font-bold xl:text-xs text-center ">
                    ✓
                  </p>
                </div>
                <p class="text-base text-white ml-2 font-bold">
                  Special Lessons And Courses
                </p>
            </div>

            <div className="flex mt-2 justify-center">
            <div className="bg-newshade33 rounded-full w-6 h-6 ">
                  <p class="text-white font-bold xl:text-xs text-center ">
                    ✓
                  </p>
                </div>
                <p class="text-base text-white ml-2 font-bold">
                  Special Lessons And Courses
                </p>
            </div>

            <div className="flex mt-2 justify-center">
            <div className="bg-newshade33 rounded-full w-6 h-6 ">
                  <p class="text-white font-bold xl:text-xs text-center ">
                    ✓
                  </p>
                </div>
                <p class="text-base text-white ml-2 font-bold">
                Special Lessons And Courses
                </p>
                </div>


                  <div className="center-button">
                <a href="/">
                <button class="px-12 py-3 text-darkblack rounded-full mt-8  bg-white red-on-hover3 ">
                  Explore All Courses ➔
                </button>
              </a>
            </div>

            <div className="center-button mt-4 pb-8">
              <img
                class="w-10/12"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/09/h1about-768x557.png"
                alt=""
              />
            </div>
          </section>

          <section className="xl:hidden lg:hidden md:hidden sm:block block container mx-auto mt-20">
          <p class="text-center  text-base font-semibold text-darkblue">
          OUR INSTRUCTORS
        </p>
        <p class="text-darkblack text-center pt-2 font-bold text-4xl">
          From The Quiklearn Community
        </p>
        <div className="center-button">
          <img
            class="w-32  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center text-sm mt-2 px-8">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen
          Book It Has Survived Not Only Five Centuries
        </p>


          <div className="mt-8">
          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl ml-6 mr-4 ">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/9/a87cce35aa3a4cb374ad14c92334a35e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-8 black-on-hover">
                Richard David
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                Marketing Expert
              </p>

              <div className="flex mt-10 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">16 students</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl  ml-6 mr-4 mt-8">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons1(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons1(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons1 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Millar Richard
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">Business</p>

              <div className="flex mt-6 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">23 students</p>
              </div>
            </div>
          </div>


          <div className="  bg-newshade29 rounded-md  shadow-xl ml-6 mr-4 mt-8 ">
            <div className="flex">
              <div>
                <img
                  class="w-12/12  rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/8/8d69d3fc4b9ecc16660fc9e32192e9e1.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons2(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons2(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons2 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Kristin Watson
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">UX/UI Expert</p>

              <div className="flex mt-6 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 course</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 student</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl ml-6 mt-8 mr-4">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/7/84ce06432355d2548c4c2d534bb8873e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons3(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons3(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons3 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Jacob Jones
              </p>
              <p class="text-grey-600 text-sm text-center ">UX/UI Designer</p>

              <div className="flex mt-6 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">8 students</p>
              </div>
            </div>
          </div>
            </div>
          </section>


          <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 bg-newshade33">
        <section className="container mx-auto figtree">
          <div className="">
            <div className="flex center-button ml-8">
              <div>
                <img
                  class="w-10/12 mt-16"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-shape1.svg"
                  alt=""
                />
              </div>
              <div className="absolute">
                <img
                  class="w-10/12 mt-4  "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-action-img1.png"
                  alt=""
                />
              </div>
            </div>

            <div>
              <p class="text-white text-2xl font-bold mt-12 text-center">
                Affordable Online Courses & Learning Opportunities For You
              </p>
            </div>

            <div className="center-button pb-8">
              <a href="/">
                <button className="px-6 py-3 rounded-full bg-white red-on-hover4 mt-12  text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>
        </section>
      </section>
   

      <section className="xl:hidden lg:hidden md:hidden sm:block block container mx-auto mt-20 figtree">
        <div className="">
          <div>
            <p class="text-darkblue text-base font-semibold text-center">
              BECOME AN INSTRUCTOR
            </p>
            <p class="text-darkblack text-3xl font-bold pt-4 text-center">
              Let’s Join Us & Spread<br></br> Your Knowledge
            </p>
            <p class="text-base pt-4 text-center px-8">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A Type Specimen Bookan Unknown Printer
              Took Alley Ffer Area Typey And Scrambled To Make A Type
              Specimen Book Hass
            </p>

            <div className="center-button">
              <a href="/">
                <button className="px-6 py-3 rounded-full bg-newshade33 text-white mt-6 red-on-hover4  text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>

          <div className="center-button">
            <img
              class="w-10/12 mt-12"
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/become-instructor-600x385.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 bg-newshade29 figtree pb-16">
        <div>
          <p class="text-darkblue text-center pt-12 font-semibold">
            OUR CLIENTS
          </p>
          <p class="text-darkblack text-center pt-2 text-center text-3xl font-bold">
            What’s Our Real Client Strories About Our <br></br>Work & Passion
          </p>
          <div className="center-button">
            <img
              class="w-32 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="text-sm px-8 text-center pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade39  mt-20 pb-16">
        <div className="container mx-auto">
          <div className=" figtree">
            <p class="text-grey text-base font-semibold pt-16 text-center">
              AVAILABLE ON ANDROID & APP STORE
            </p>
            <p class="text-white font-bold text-3xl pt-2 text-center">
              Build A Beautiful, Professional-Looking
            </p>
            <p class="text-white font-bold text-3xl  text-center">
              Online Course With Quiklearn
            </p>

            <div className="flex justify-center">
              <div className="flex mt-6 border-7 rounded-md  px-4 py-2">
                <div className="">
                  <img class="w-8 " src={playstore} />
                </div>
                <p class="text-white text-base font-bold mt-1 ml-2">
                  Google Play
                </p>
              </div>

              <div className="flex mt-6 border-7 rounded-md  px-4 py-2 ml-4">
                <div className="">
                  <img class="w-8 " src={apple} />
                </div>
                <p class="text-white text-base font-bold mt-2 ml-2">
                  Apple Store
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 container mx-auto pb-8">

      <div className=" bg-white shadow-xl rounded-md pb-6 ml-4 mr-4">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-8 pt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_03-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-2">Data Science</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley<br></br> Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 text-base1 pt-1">📆</p>
              <p class="ml-2 xl:text-base text-grey-600 mt-1">
                August 10, 2023
              </p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>

          <div className=" bg-white shadow-xl rounded-md pb-6  ml-4 mr-4 mt-12 ">
            <div class=" ">
              <div class="">
                <img
                  class="w-10/12 rounded-md ml-8 pt-12  object-cover hover-scale transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_04-380x277.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="flex mt-8">
              <div className="border-3 w-32 rounded-md ml-6 bg-newshade29  ">
                <p class="text-base ml-2">Art&design</p>
              </div>
            </div>

            <p class="text-xl text-darkblack font-bold ml-6 pt-4 blue-on-hover">
              Take your Career Next Leveley Future Approach
            </p>

            <div className="flex mt-4">
              <p class="text-black ml-6 text-base1 pt-1">📆</p>
              <p class="ml-2 xl:text-base text-grey-600 mt-1">June 21, 2023</p>
              <p class="text-base1 ml-6 text-black pt-1">👤</p>
              <p class="ml-2 text-base text-grey-600 mt-1">By Admin</p>
            </div>
          </div>


        
        </section>
      {/*END*/}
      <Footer2 />
    </section>
  );
}
