import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cartoon5 from "assets/img/cartoon5.jpg";
import cartoon6 from "assets/img/cartoon6.jpg";
import cartoon7 from "assets/img/cartoon7.jpg";
import cartoon8 from "assets/img/cartoon8.jpg";

const PrevArrow = ({ onClick }) => (
  <div
    className="cursor-pointer text-white bg-orange-500 h-8 w-8 rounded-md flex justify-center items-center text-lg absolute right-0 top-0 transform z-10 -mt-16"
    onClick={onClick}
    style={{ right: "10px" }}
  >
    ←
  </div>
);

const NextArrow = ({ onClick }) => (
  <div
    className="cursor-pointer text-white bg-orange-500 h-8 w-8 rounded-md flex justify-center items-center text-lg absolute right-0 top-0 transform  z-10 -mt-16"
    onClick={onClick}
    style={{ right: "50px" }}
  >
    →
  </div>
);

const Landing = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 9000, // Control slide transition speed
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1000, // Slow down the autoplay (7 seconds per slide)
    cssEase: "linear", // Smooth continuous scrolling
    pauseOnHover: true, // Pause on hover
    draggable: true,
    swipeToSlide: true,
    touchMove: true,
    nextArrow: <NextArrow onClick={() => sliderRef.current.slickNext()} />,
    prevArrow: <PrevArrow onClick={() => sliderRef.current.slickPrev()} />,
  };

  return (
    <section className="relative">
      <Slider ref={sliderRef} {...settings}>
        <div className="center-button">
          <img
            className="xl:w-10/12 rounded-md shadow-xl border-4 border-white"
            style={{
              borderWidth: "15px",
              borderStyle: "solid",
              borderColor: "white",
            }}
            src={cartoon5}
            alt="cartoon5"
          />
        </div>

        <div className="center-button">
          <img
            className="xl:w-10/12 rounded-md shadow-xl border-4 border-white"
            style={{
              borderWidth: "15px",
              borderStyle: "solid",
              borderColor: "white",
            }}
            src={cartoon6}
            alt="cartoon6"
          />
        </div>

        <div className="center-button">
          <img
            className="xl:w-10/12 rounded-md shadow-xl border-4 border-white"
            style={{
              borderWidth: "15px",
              borderStyle: "solid",
              borderColor: "white",
            }}
            src={cartoon7}
            alt="cartoon7"
          />
        </div>

        <div className="center-button">
          <img
            className="xl:w-10/12 rounded-md shadow-xl border-4 border-white"
            style={{
              borderWidth: "15px",
              borderStyle: "solid",
              borderColor: "white",
            }}
            src={cartoon8}
            alt="cartoon8"
          />
        </div>

        <div className="center-button">
          <img
            className="xl:w-10/12 rounded-md shadow-xl border-4 border-white"
            style={{
              borderWidth: "15px",
              borderStyle: "solid",
              borderColor: "white",
            }}
            src={cartoon6}
            alt="cartoon6"
          />
        </div>

        <div className="center-button">
          <img
            className="xl:w-10/12 rounded-md shadow-xl border-4 border-white"
            style={{
              borderWidth: "15px",
              borderStyle: "solid",
              borderColor: "white",
            }}
            src={cartoon7}
            alt="cartoon6"
          />
        </div>
      </Slider>
    </section>
  );
};

export default Landing;
