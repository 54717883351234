import React, { useState } from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import blue from "assets/img/blue.jpeg";

export default function PrivacyPolicy() {
    return (
      <>
        <Indexnavbar2 fixed />

        <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:-mt-52 lg:-mt-84">
        <div>
          <div
            style={{
              backgroundImage: `url(${blue})`,
              width: "100%",
              height: "220px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            </div>
          </div>
          </section>

          <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto figtree xl:pb-24">
            <div className="">
               <p class="text-darkblack text-center font-bold xl:text-5xl xl:mt-24">Cancellation Policy</p>
            </div>

            <div className="xl:ml-16 xl:mt-16">
                <p class="xl:text-xl font-bold text-black">Subscription Services:</p>
                <p class="xl:text-base1 xl:pt-2">If RecruitmentPlus offers subscription-based services for employers or recruiters, the cancellation policy may detail<br></br> how users can cancel their subscription, whether there are any fees or penalties for cancellation, and any applicable<br></br> notice periods.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
                <p class="xl:text-xl font-bold text-black">Job Postings: </p>
                <p class="xl:text-base1 xl:pt-2">For individual job postings, there may be a policy outlining whether users can cancel or edit their postings, and if<br></br> there are any restrictions or fees associated with doing so.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
                <p class="xl:text-xl font-bold text-black">Service Agreements: </p>
                <p class="xl:text-base1 xl:pt-2">For larger contracts or agreements between RecruitmentPlus and businesses or agencies, the cancellation policy<br></br> may be outlined in the terms of the agreement. This might include details about termination clauses, notice periods,<br></br> and any associated fees or penalties.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
                <p class="xl:text-xl font-bold text-black">Refunds: </p>
                <p class="xl:text-base1 xl:pt-2"> Depending on the circumstances, there may be provisions for refunds if users cancel their services or subscriptions<br></br> within a certain timeframe or under specific conditions.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
                <p class="xl:text-xl font-bold text-black">Communication Channels: </p>
                <p class="xl:text-base1 xl:pt-2"> The cancellation policy may specify how users should request cancellations, whether it's through an online portal,<br></br> email, or phone, and the expected response time for processing cancellation requests.</p>
            </div>
            </section>



            <section className="xl:hidden lg:block md:hidden sm:hidden hidden lg:-mt-56">
        <div>
          <div
            style={{
              backgroundImage: `url(${blue})`,
              width: "100%",
              height: "240px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            </div>
          </div>
          </section>

          <section className="xl:hidden lg:block md:hidden sm:hidden hidden container mx-auto figtree lg:pb-24">
            <div className="">
               <p class="text-darkblack text-center font-bold lg:text-4xl lg:mt-16">Cancellation Policy</p>
            </div>

            <div className="ml-16 mt-16">
                <p class="text-xl font-bold text-black">Subscription Services:</p>
                <p class="text-base1 pt-2">If RecruitmentPlus offers subscription-based services for employers or recruiters, the cancellation policy may detail<br></br> how users can cancel their subscription, whether there are any fees or penalties for cancellation, and any applicable<br></br> notice periods.</p>
            </div>

            <div className="ml-16 mt-8">
                <p class="text-xl font-bold text-black">Job Postings: </p>
                <p class="text-base1 pt-2">For individual job postings, there may be a policy outlining whether users can cancel or edit their postings, and if<br></br> there are any restrictions or fees associated with doing so.</p>
            </div>

            <div className="ml-16 mt-8">
                <p class="text-xl font-bold text-black">Service Agreements: </p>
                <p class="text-base1 pt-2">For larger contracts or agreements between RecruitmentPlus and businesses or agencies, the cancellation policy<br></br> may be outlined in the terms of the agreement. This might include details about termination clauses, notice periods,<br></br> and any associated fees or penalties.</p>
            </div>

            <div className="ml-16 mt-8">
                <p class="text-xl font-bold text-black">Refunds: </p>
                <p class="text-base1 pt-2"> Depending on the circumstances, there may be provisions for refunds if users cancel their services or subscriptions<br></br> within a certain timeframe or under specific conditions.</p>
            </div>

            <div className="ml-16 mt-8 pb-8">
                <p class="text-xl font-bold text-black">Communication Channels: </p>
                <p class="text-base1 pt-2"> The cancellation policy may specify how users should request cancellations, whether it's through an online portal,<br></br> email, or phone, and the expected response time for processing cancellation requests.</p>
            </div>
            </section>


            {/*TAB VIEW*/}

            <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto figtree pb-24">
            <div className="">
               <p class="text-darkblack text-center font-bold text-4xl mt-16">Cancellation Policy</p>
            </div>

            <div className="ml-16 mt-16 mr-8">
                <p class="text-xl font-bold text-black">Subscription Services:</p>
                <p class="text-base1 pt-2">If RecruitmentPlus offers subscription-based services for employers or recruiters, the cancellation policy may detail how users can cancel their subscription, whether there are any fees or penalties for cancellation, and any applicable notice periods.</p>
            </div>

            <div className="ml-16 mt-8 mr-8">
                <p class="text-xl font-bold text-black">Job Postings: </p>
                <p class="text-base1 pt-2">For individual job postings, there may be a policy outlining whether users can cancel or edit their postings, and ifthere are any restrictions or fees associated with doing so.</p>
            </div>

            <div className="ml-16 mt-8 mr-8">
                <p class="text-xl font-bold text-black">Service Agreements: </p>
                <p class="text-base1 pt-2">For larger contracts or agreements between RecruitmentPlus and businesses or agencies, the cancellation policy may be outlined in the terms of the agreement. This might include details about termination clauses, notice periods, and any associated fees or penalties.</p>
            </div>

            <div className="ml-16 mt-8 mr-8">
                <p class="text-xl font-bold text-black">Refunds: </p>
                <p class="text-base1 pt-2"> Depending on the circumstances, there may be provisions for refunds if users cancel their services or subscriptions within a certain timeframe or under specific conditions.</p>
            </div>

            <div className="ml-16 mt-8 pb-8 mr-8">
                <p class="text-xl font-bold text-black">Communication Channels: </p>
                <p class="text-base1 pt-2"> The cancellation policy may specify how users should request cancellations, whether it's through an online portal, email, or phone, and the expected response time for processing cancellation requests.</p>
            </div>
            </section>


            {/*Mobile view*/}
            <section className="xl:hidden lg:hidden md:hidden sm:block block container mx-auto figtree pb-12">
            <div className="">
               <p class="text-darkblack text-center font-bold text-4xl mt-16">Cancellation Policy</p>
            </div>

            <div className="ml-8 mt-16 mr-8">
                <p class="text-xl font-bold text-black">Subscription Services:</p>
                <p class="text-base1 pt-2">If RecruitmentPlus offers subscription-based services for employers or recruiters, the cancellation policy may detail how users can cancel their subscription, whether there are any fees or penalties for cancellation, and any applicable notice periods.</p>
            </div>

            <div className="ml-8 mt-8 mr-8">
                <p class="text-xl font-bold text-black">Job Postings: </p>
                <p class="text-base1 pt-2">For individual job postings, there may be a policy outlining whether users can cancel or edit their postings, and ifthere are any restrictions or fees associated with doing so.</p>
            </div>

            <div className="ml-8 mt-8 mr-8">
                <p class="text-xl font-bold text-black">Service Agreements: </p>
                <p class="text-base1 pt-2">For larger contracts or agreements between RecruitmentPlus and businesses or agencies, the cancellation policy may be outlined in the terms of the agreement. This might include details about termination clauses, notice periods, and any associated fees or penalties.</p>
            </div>

            <div className="ml-8 mt-8 mr-8">
                <p class="text-xl font-bold text-black">Refunds: </p>
                <p class="text-base1 pt-2"> Depending on the circumstances, there may be provisions for refunds if users cancel their services or subscriptions within a certain timeframe or under specific conditions.</p>
            </div>

            <div className="ml-8 mt-8 pb-8 mr-8">
                <p class="text-xl font-bold text-black">Communication Channels: </p>
                <p class="text-base1 pt-2"> The cancellation policy may specify how users should request cancellations, whether it's through an online portal, email, or phone, and the expected response time for processing cancellation requests.</p>
            </div>
            </section>

        <Footer2/>
        </>
    );
}