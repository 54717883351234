import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import sc1 from "assets/img/sc1.svg";
import sc2 from "assets/img/sc2.svg";
import sc3 from "assets/img/sc3.svg";
import sc4 from "assets/img/sc4.svg";
import sc5 from "assets/img/sc5.svg";
import sc6 from "assets/img/sc6.jpg";
import sc7 from "assets/img/sc7.jpg";
import sc8 from "assets/img/sc8.png";
import sc9 from "assets/img/sc9.png";
import sc10 from "assets/img/sc10.png";
import usericon from "assets/img/usericon.png";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import Landing from "views/Landing";
import boy from "assets/img/boy.png";
import girl from "assets/img/girl.png";
import ed1 from "assets/img/ed1.png";
import ed2 from "assets/img/ed2.png";
import ed3 from "assets/img/ed3.png";
import teacher from "assets/img/teacher1.png";
import girl2 from "assets/img/girl2.png";
import girl3 from "assets/img/girl3.png";
import boy1 from "assets/img/boy2.png";
import group from "assets/img/group.png";
import flash from "assets/img/flash.png";
import Cloudslider from "views/Cloudslider";
import sc11 from "assets/img/sc11.png";
import bag1 from "assets/img/bag1.png";


export default function homapage2() {
  return (
    
    
    
      <section className="bg-2 figtree">
        <Indexnavbar2 />
        <section className="">
        <section className="xl:block lg:block md:hidden sm:hidden hidden container mx-auto">
          <h1 className="textt xl:text-5xl font-bold text-center xl:pt-36 ">
            Better Future For Your Kids
          </h1>
          <div className="grid grid-cols-3">
            <div>
              <div>
                <img class="xl:w-24 continuous-zoom" src={sc1} alt="" />
              </div>
            </div>
            <div>
              <div className="bg-white py-1 px-2 xl:mt-6">
                <p className="text-smm text-center textt font-semibold ">
                  Let the child be the director, and the actor in his own play
                </p>
              </div>
            </div>
            <div className="flex justify-end continuous-zoom">
              <img class="xl:w-24" src={sc2} alt="" />
            </div>
          </div>

          <div className="center-button continuous-zoom  ">
          <div className="flex justify-center xl:mt-4 bg-3 xl:w-36 xl:py-3 rounded-full px-2 shadow-xl">
            <div className=" text-white rounded-full  text-sm ">
              Get Started
            </div>
            <div className="bg-orange rounded-full h-4 w-4 xl:ml-2 mt-111">
            <p className="text-white text-base text-center -mt-1">›</p>
            </div>
          </div>
          </div>

          <div className="grid grid-cols-3 xl:mt-12 gap-4">
            <div className="">
              <div className="center-button xl:mt-10 animated-image">
                <img class="xl:w-11/12 rounded-md " src={girl} alt="" />
              </div>
            </div>

            <div>
              <div className="flex justify-center items-center ">
                <p className="text-underline-small mr-4"></p>
                <p className="xl:text-base textt font-semibold ">
                  We just don't give our students only lecture<br></br>
                  but real-life experimented filled experience<br></br>
                  throughout the year
                </p>
              </div>

              <div className="flex gap-4 xl:mt-12">
                <div className="h-12 w-12 rounded-full bg-white ">
                  <div className="flex justify-center">
                    <img className="w-6 pt-2" src={usericon} alt="" />
                  </div>
                </div>
                <div className="">
                  <h1 className="xl:text-3xl textt font-bold custom-serif">7.5+</h1>
                  <p className="xl:text-sm font-semibold textt ">
                    Total active students taking<br></br> gifted courses
                  </p>
                </div>
              </div>

              <div className="flex gap-4 xl:mt-12 justify-end">
                <div className="h-12 w-12 rounded-full bg-white ">
                  <div className="flex justify-center">
                    <img className="w-6 pt-2" src={flash} alt="" />
                  </div>
                </div>
                <div className="">
                  <h1 className="xl:text-3xl textt font-bold ">50+</h1>
                  <p className="xl:text-sm font-semibold textt ">
                    Total active students taking<br></br> gifted courses
                  </p>
                </div>
              </div>

              
            </div>

            <div>
              <div>
                <img class="xl:w-11/12 rounded-md animated-image" src={boy} alt="" />
              </div>
            </div>
          </div>

          {/* <div className="flex xl:mt-8">
            <div className="flex justify-start">
              <img className="w-32" src={sc5} alt="" />
            </div>
          </div>

          <div>
            <div className="flex justify-end">
              <img className="w-20" src={sc3} alt="" />
            </div>
          </div> */}
        </section>

        <section className="  xl:block lg:block md:hidden sm:hidden hidden container mx-auto xl:mt-12 xl:px-4 ">
          <div className="bg-1 rounded-md  xl:p-12 ">
            <div className="grid grid-cols-4  ">
              <div className="xl:p-6">
                <h1 className="xl:text-2xl font-bold text-white text-center xl:pt-8 ">
                  Programs
                </h1>
                <p className="xl:text-base text-white text-center ">
                  Our programs are designed to develop your children
                </p>
              </div>

              <div className="xl:p-6">
                <div className="flex justify-center ">
                  <img class="w-20" src={ed1} alt="" />
                </div>
                <p className="xl:text-lg font-semibold text-white text-center xl:pt-2 ">
                  Creative Thinking
                </p>
                <p className="text-white text-sm text-center ">
                  Learn more <span className="font-bold">+</span>
                </p>
              </div>

              <div className="bg-3 shadow-xl rounded-md xl:p-6 continuous-zoom">
                <div className="flex justify-center ">
                  <img class="w-20 shadow-xl" src={ed2} alt="" />
                </div>
                <p className="xl:text-lg font-semibold text-white text-center xl:pt-2 ">
                  Career Planning
                </p>
                <p className="text-white text-sm text-center ">
                  Learn more <span className="font-bold">+</span>
                </p>
              </div>


              

              <div className="xl:p-6">
                <div className="flex justify-center">
                  <img class="w-20" src={ed3} alt="" />
                </div>
                <p className="xl:text-lg font-semibold text-white text-center xl:pt-2 ">
                  Public Speaking
                </p>
                <p className="text-white text-sm text-center ">
                  Learn more <span className="font-bold">+</span>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="xl:block lg:block md:hidden sm:hidden hidden container mx-auto xl:mt-16">
          <div>
            <img class="w-24" src={sc1} alt="" />
          </div>
          <h1 className="textt xl:text-4xl font-bold text-center xl:-mt-16 ">
            Our Resources
          </h1>
          <p className="textt xl:text-lg font-semibold text-center ">
            We develop their confidence to make them sharper
          </p>

            <div className="xl:mt-8 xl:ml-8">
            <div>
              <Landing/>
            </div>
          </div>  
        </section>

        <section className="xl:block lg:block md:hidden sm:hidden hidden container mx-auto xl:mt-16 xl:px-6">
          <div className="grid grid-cols-2 gap-0 xl:ml-32 xl:px-12">
             <div>
              <h1 className="textt xl:text-4xl font-bold xl:pt-16 ">
                Traditional In person<br></br> classroom encouraged<br></br>{" "}
                students to
              </h1>

              <div className="flex xl:mt-4 mt-4">
                <div className="w-5 h-5 rounded-full bg-orange mt-11">
                  <p className="xl:text-sm text-white text-center font-bold text-sm">
                    ✓
                  </p>
                </div>
                <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2 ">
                  Focus for long period of time
                </p>
              </div>

              <div className="flex xl:mt-4 mt-4">
                <div className="w-5 h-5 rounded-full bg-orange mt-11">
                  <p className="xl:text-sm text-white text-center font-bold text-sm ">
                    ✓
                  </p>
                </div>
                <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2 ">
                  Engage with their peers
                </p>
              </div>

              <div className="flex xl:mt-4 mt-4">
                <div className="w-5 h-5 rounded-full bg-orange mt-11">
                  <p className="xl:text-sm text-white text-center font-bold text-sm">
                    ✓
                  </p>
                </div>
                <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2 ">
                  Understanding of complicated concepts
                </p>
              </div>
            </div>
            

            <div>
              <div>
                <img className="xl:w-12/12  rounded-md xl:-ml-12" src={sc11} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-16 container mx-auto rounded-md xl:px-4">
          <div className="grid grid-cols-2 rounded-md">
            <div className=" ">
              <div className="bg-orange radius1 ">
                <div className="flex">
                  <div>
                    <img class="w-16" src={sc2} alt="" />
                  </div>
                </div>
                <div className="flex justify-end">
                  <img class="w-20" src={sc1} alt="" />
                </div>

                <div className="flex justify-center">
                  <img class="xl:w-8/12  rounded-md" src={sc10} alt="" />
                </div>
              </div>
            </div>

            <div className="bg-1  radius2 xl:p-12 ">
              <h1 className="text-white xl:text-4xl font-bold  xl:pt-12">
                Traditional In person<br></br> classroom encouraged<br></br>{" "}
                students to
              </h1>
              <p className="xl:text-base text-white xl:pt-4 ">
                We just don't give our students only lecture<br></br>
                but real-life experimented filled experience<br></br>
                throughout the year
              </p>
            </div>
          </div>
        </section>

        {/* <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-12 container mx-auto">
         <div className="flex justify-between justify-center">
          <div className="xl:w-5/12">
            <h1 className="xl:text-4xl text-black font-bold">Our Octobers<br></br> Superstar</h1>
          </div>

          <div className="xl:w-5/12">
            <hr className="border-blue-50 xl:mt-12"></hr>
          </div>

          <div className="xl:w-5/12 xl:mt-6 ">
           <button className="px-8 py-2 bg-newshade17 rounded-full text-white">See All</button>
          </div>
         </div>
        </section> */}

<section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-12 container mx-auto">
  <div className="grid grid-cols-3 gap-8 xl:ml-12">
    
    <div className="flex items-center">
      <div className="xl:w-5/12">
        <img className="w-full rounded-md" src={girl2} alt="Lisa Ava" />
      </div>
      <div className="xl:mt-8">
        <h1 className="textt text-xl font-bold">Lisa Ava</h1>
        <p className="text-gray-600 text-base pt-1">Age: <span className="font-bold textt">6 years</span></p>
        <p className="text-gray-600 text-base pt-1">From: <span className="font-bold textt">Columbia</span></p>
      </div>
    </div>


    <div className="flex items-center">
      <div className="xl:w-6/12">
        <img className="w-full rounded-md" src={boy1} alt="Jaydn Niles" />
      </div>
      <div className="xl:mt-8">
        <h1 className="textt text-xl font-bold">Jaydn Niles</h1>
        <p className="text-gray-600 text-base pt-1">Age: <span className="font-bold textt">5 years</span></p>
        <p className="text-gray-600 text-base pt-1">From: <span className="font-bold textt">Ethiopia</span></p>
      </div>
    </div>

   
    <div className="flex items-center xl:ml-8">
      <div className="xl:w-6/12">
        <img className="xl:w-11/12 rounded-md" src={girl3} alt="Sophia Mia" />
      </div>
      <div className=" xl:mt-8">
        <h1 className="textt text-xl font-bold">Sophia Mia</h1>
        <p className="text-gray-600 text-base pt-1">Age: <span className="font-bold textt">8 years</span></p>
        <p className="text-gray-600 text-base pt-1">From: <span className="font-bold textt">Ireland</span></p>
      </div>
    </div>
  </div>
</section>



        <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-16 container mx-auto xl:pb-16 xl:px-4">
        <div className="grid grid-cols-2">
         <div className="">
          <div className="bg-4  radius1 xl:pt-15 xl:pl-16">
         <h1 className="textt xl:text-4xl font-bold ">
            Better Future For<br></br> Your Kids
          </h1>
          <p className="xl:text-base font-semibold textt xl:pt-4 ">Get your free e-book</p>

          <div className="bg-2 rounded-full xl:p-2 xl:w-6/12 xl:mt-4">
            <div className="flex justify-between justify-center">
             <p className="text-base font-semibold pt-2 text-center ">Your E-mail</p>
             <button className="text-white bg-3 rounded-full px-6 py-1 text-xl font-bold text-center ">›</button>
            </div>
          </div>
          <div className="flex justify-end xl:pr-6">
            <img class="w-24 " src={bag1} alt=""/>
          </div>
          </div>
         
         </div>

         <div className=" ">
          <div>
            <img className="xl:w-12/12  radius2" src={group} alt=""/>
          </div>
         </div>
        </div>
        </section>
        </section>
        <Footer2 />
      </section>
    
  );
}
