import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import rplogo from "assets/img/rplogo.png";
import rplogo2 from "assets/img/rplogo2.png";
import Footer2 from "components/Footers/Footer2";
import { createPopper } from '@popperjs/core';

export default function IndexNavbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const popoverDropdownRef = React.createRef();
  const btnDropdownRef = React.createRef();

  const [showFirstMenu, setShowFirstMenu] = useState(false);
  const handleFirstMenuHover = () => {
    setShowFirstMenu(true);
  };

  const handlePackagesMenuLeave = () => {
    setShowFirstMenu(false);
  };

  const [showSecondMenu, setShowSecondMenu] = useState(false);
  const handleSecondMenuHoverr = () => {
    setShowSecondMenu(true);
  };

  const handlePackagesMenuLeavee = () => {
    setShowSecondMenu(false);
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const [isDropdownOpenn, setDropdownOpenn] = useState(false);
  const handleDropdownTogglee = () => {
    setDropdownOpenn(!isDropdownOpenn);
  };

  useEffect(() => {
    if (isDropdownVisible) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });
    }
  }, [isDropdownVisible]);

  return (
    <section className="bg-transparent ">
      
     
      {/* <section className="xl:block lg:block md:hidden hidden">
        <div className="flex items-center  ">
          <img
            class=" flex xl:w-52 xl:ml-26 xl:mt-2 lg:-mt-2 lg:w-42 lg:ml-22 "
            src={rplogo2}
          />

          <div className="flex items-center justify-content-bewteen xl:mt-4 lg:mt-1">
            <ul class=" flex figtree">
              <li class="text-white xl:ml-8 font-bold figtree xl:text-lg red-on-hover lg:ml-4 lg:text-base">
                <a href="/homepage2">About Us</a>
              </li>
              <li class="text-white xl:ml-4 font-bold figtree xl:text-lg red-on-hover lg:ml-4 lg:text-base">
                <a href="/homepage2">Services</a>
              </li>
              <li class="text-white xl:ml-4 font-bold figtree xl:text-lg red-on-hover lg:ml-4 lg:text-base">
                <a href="/homepage2">Candidate</a>
              </li>
              <li class="text-white xl:ml-4 font-bold figtree xl:text-lg red-on-hover lg:ml-4 lg:text-base">
                <a href="/homepage2">Employer</a>
              </li>
            </ul>
          </div>

          <div className="flex xl:mt-8 xl:-ml-12 lg:mt-6">
            <a class="" href="/homepage2">
              <p className="xl:ml-116  bg-transparent xl:text-5xl lg:ml-56  ">
                💬
              </p>
            </a>
            <a class="" href="/homepage2">
              <p className="text-white figtree xl:text-base">
                Have any questions?<br></br>
                +0123456789
              </p>
            </a>
          </div>
        </div>
      </section> */}

      {/*TAB AND MOBILE VIEW
      <section className=" container mx-auto xl:hidden lg:hidden md:block block sm:block ">
        <div className="w-full relative flex justify-between">
          <div className={`px-0 ${navbarOpen ? "hidden" : ""}`}>
            <span>
              <a href="/homepage2">
                <img
                  src={rplogo}
                  className="   md:w-40 md:h-45 md:mt-4 w-48   mt-2  cursor-pointer  lg:pr-0 md:pr-0   text-1xl md:ml-4 h-8 ml-4 "
                />
              </a>
            </span>
          </div>
          <button
            className="bg-transparent cursor-pointer text-lg text-black font-bold leading-none border-transparent rounded  ml-12 block  outline-none focus:outline-none md:mr-4 mr-8 md:mt-4 mt-2"
            type="button  "
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? (
              <i className=""></i>
            ) : (
              <i className="fas fa-bars"></i>
            )}
          </button>
        </div>
      </section>

      <div className="xl:hidden lg:hidden md:block sm:block block ">
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none py-3 xl:pt-1 xl:py-0" +
            (navbarOpen ? " block bg-white" : " hidden")
          }
          id="example-navbar-warning"
        >
          <div className="px-0">
            <div className="xl:w-full relative flex justify-between lg:w-auto lg:static lg:justify-start">
              <div className="pr-4 ">
                <span>
                  <a href="/">
                    <img
                      src={rplogo}
                      alt=""
                      className=" md:w-40 md:h-45 md:mt-0 h-8  cursor-pointer pr-0  md:pr-0   md:ml-12 mt-2 ml-6  w-32"
                    />
                  </a>
                </span>
              </div>
              <button
                className="cursor-pointer text-3xl text-black md:mr-12 font-semibold leading-none block  outline-none focus:outline-none mr-8"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                x
              </button>
            </div>

            <div className="xl:hidden lg:hidden md:block block">
              <a href="/Homepage2">
                <p className=" cursor-pointer md:pl-6 pl-2  md:pb-3 pb-0  md:pt-4 pt-5 md:text-base font  uppercamd:border-b-longse text-black md:ml-10 bg-white figtree font-semibold text-sm ml-6">
                  About Us
                </p>
              </a>
            </div>
            <div className="border md:ml-14 md:mr-12 ml-6 mr-8 mt-1"></div>

            <div className="xl:hidden lg:hidden md:block block ">
              <a href="/Homepage2">
                <p className=" cursor-pointer md:pl-6 pl-2  md:pb-3 pb-0  md:pt-4 pt-5 md:text-base font  uppercase text-black md:ml-10 bg-white figtree font-semibold text-sm ml-6">
                  services
                </p>
              </a>
            </div>
            <div className="border md:ml-14 md:mr-12 ml-6 mr-8 mt-1"></div>

            <div className="xl:hidden lg:hidden md:block block ">
              <a href="/Homepage2">
                <p className=" cursor-pointer md:pl-6 pl-2  md:pb-3 pb-0  md:pt-4 pt-5 md:text-base font  uppercase text-black md:ml-10 bg-white figtree font-semibold text-sm ml-6">
                  Employer
                </p>
              </a>
            </div>
            <div className="border md:ml-14 md:mr-12 ml-6 mr-8 mt-1"></div>

            <div className="xl:hidden lg:hidden md:block block">
              <a href="/Homepage2">
                <p className=" cursor-pointer md:pl-6 pl-2  md:pb-3 pb-0  md:pt-4 pt-5 md:text-base font  uppercase text-black md:ml-10 bg-white figtree font-semibold text-sm ml-6">
                  Candidate
                </p>
              </a>
            </div>

            <div className="xl:hidden lg:hidden md:block block md:mt-20 mt-12 ">
              <a href="/">
                <button className="md:px-4 md:py-1 md:ml-14 bg-black text-white rounded-md px-4 py-1 ml-6 ">
                  Contact US
                </button>
              </a>
            </div>

            <div className="flex xl:hidden lg:hidden md:block block md:mt-16 pb-56 pl-0 mt-8 ">
              <a href="homepage2/">
                <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-14 ml-6">
                  <i className="fab fa-facebook"></i>
                </button>
              </a>

              <a href="homepage2/">
                <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-linkedin"></i>
                </button>
              </a>

              <a href="homepage2/">
                <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-instagram"></i>
                </button>
              </a>

              <a href="homepage2/">
                <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-youtube"></i>
                </button>
              </a>
            </div>
          </div>
        </div>
        </div>*/}
        </section>
  );
}
