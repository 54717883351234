import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Cloudslider Component
const Cloudslider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 6000, // Duration of the transition (in milliseconds)
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Speed of autoplay (in milliseconds)
    cssEase: "linear", // Smooth continuous scrolling
    pauseOnHover: true, // Pause on hover
    responsive: [
      {
        breakpoint: 1280, // desktop breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 1.9,
        },
      },
      {
        breakpoint: 900, // tablet landscape breakpoint
        settings: {
          slidesToShow: 1.35,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1.25,
        },
      },
    ],
  };

  return (
    <section className="relative">
      <div className="relative">
        <Slider {...settings}>
          <div>
            <h1 className="textt xl:text-4xl font-bold xl:pt-16">
              Traditional In person<br></br> classroom encouraged<br></br>{" "}
              students to
            </h1>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-orange mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">✓</p>
              </div>
              <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2">
                Focus for long period of time
              </p>
            </div>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-orange mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">✓</p>
              </div>
              <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2">
                Engage with their peers
              </p>
            </div>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-orange mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">✓</p>
              </div>
              <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2">
                Understanding of complicated concepts
              </p>
            </div>
          </div>

          <div>
            <h1 className="textt xl:text-4xl font-bold xl:pt-16">
              Traditional In person<br></br> classroom encouraged<br></br>{" "}
              students to
            </h1>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-orange mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">✓</p>
              </div>
              <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2">
                Focus for long period of time
              </p>
            </div>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-orange mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">✓</p>
              </div>
              <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2">
                Engage with their peers
              </p>
            </div>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-orange mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">✓</p>
              </div>
              <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2">
                Understanding of complicated concepts
              </p>
            </div>
          </div>


          <div>
            <h1 className="textt xl:text-4xl font-bold xl:pt-16">
              Traditional In person<br></br> classroom encouraged<br></br>{" "}
              students to
            </h1>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-orange mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">✓</p>
              </div>
              <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2">
                Focus for long period of time
              </p>
            </div>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-orange mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">✓</p>
              </div>
              <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2">
                Engage with their peers
              </p>
            </div>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-orange mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">✓</p>
              </div>
              <p className="xl:text-base font-semibold textt xl:ml-2 text-base ml-2">
                Understanding of complicated concepts
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Cloudslider;
