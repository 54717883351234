import React from "react";
import AccordionFooter from "./AccordionFooter";
import rplogo2 from "assets/img/rplogo2.png";
import facebook from "assets/img/facebook.png";
import instagram from "assets/img/instagram.png";
import instagram1 from "assets/img/instagram1.png";
import linkdin from "assets/img/linkedin.png";
import location from "assets/img/location.png";

export default function Footer2() {
  {
    return (
      <>
        {/* <section className="xl:block lg:block md:hidden hidden sm:hidden bg-ivory-black">

          <section className="container mx-auto  " >
            <div className="grid grid-cols-2">
                 <div className="xl:w-4/12 lg:w-4/12">
                  <img class=" xl:pt-16 xl:ml-12  lg:pt-12 lg:ml-16" src={rplogo2}/>
                 </div>
                 <div className="flex xl:pt-16 lg:pt-12">
                  <a href="homepage2/">
                  <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg xl:ml-92 lg:ml-68">
                  <i className="fab fa-facebook"></i>
                  </button>
                  </a>

                  <a href="homepage2/">
                  <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                  <i className="fab fa-linkedin"></i>
                  </button>
                  </a>

                  <a href="homepage2/">
                  <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                  <i className="fab fa-instagram"></i>
                  </button>
                  </a>

                  <a href="homepage2/">
                  <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                  <i className="fab fa-youtube"></i>
                  </button>
                  </a>

                  </div>
            </div>
            </section>

            <section className="container mx-auto">
              <div className="grid grid-cols-2 xl:mt-12 lg:mt-8">

               <div>
                <a href="homepage2/">
                <p class="xl:text-base1 text-white figtree xl:ml-14 lg:text-lg lg:ml-22" >Services</p>
                </a>
                 
                 <div class="ftDropDown xl:ml-14 lg:ml-22"></div>
                <div className="flex xl:mt-4 lg:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-14 red-on-hover lg:ml-22 lg:text-base " >Cyber Security</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-20 red-on-hover lg:ml-16 lg:text-base" >AI Engineering</p>
                </a>
                </div>

                <div className="flex xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-14 red-on-hover lg:ml-22 lg:text-base" >Cloud Engineering</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-14 red-on-hover lg:ml-10 lg:text-base" >Infrastructure Engineering</p>
                </a>
                </div>
                
                <div className="xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-14 red-on-hover lg:ml-22 lg:text-base" > Data Engineering</p>
                </a>
                </div>
               </div>

               <div className="">
              <a href="homepage2/">
                <p class="xl:text-base1 text-white figtree xl:ml-56 lg:ml-8 lg:text-lg" >About Us</p>
                </a>

                <div class="ftDropDown xl:ml-56 lg:ml-8"></div>

                <div className="flex xl:mt-4 lg:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-56 red-on-hover lg:text-base lg:ml-8 " >Our Company</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-20 red-on-hover lg:text-base lg:ml-16" >Certification and Recognition</p>
                </a>
                </div>

                <div className="flex xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-56 red-on-hover lg:text-base lg:ml-8" >Why Marslab</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-22 red-on-hover lg:text-base lg:ml-22" >Home</p>
                </a>
                </div>

                <div className="flex xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-56 red-on-hover lg:text-base lg:ml-8" >Our Team</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-27 red-on-hover lg:text-base lg:ml-24" >Contact Us</p>
                </a>
                </div>

                <div className="flex xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-56 red-on-hover lg:text-base lg:ml-8" >Careers</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-30 red-on-hover lg:text-base lg:ml-28" >Enquiry Us</p>
                </a>
                </div>
              </div>
              </div>
    </section>

           <hr class="xl:mb-4 xl:ml-40 xl:mt-12 xl:mr-40 border-blueGray-300  lg:ml-22 lg:mr-20 lg:mt-6 lg:mb-4"></hr>

             <p class="figtree text-center text-white xl:text-base xl:pt-4 xl:pb-8 lg:pt-4 lg:pb-4">©2023 Recrutement Plus Inc, a company. All rights reserved.</p>
    </section>*/}

        {/*TAB AND MOBILE VIEW*/}
      {/*} <footer className="bg-ivory-black pt-8 pb-6 mb-36 xl:hidden lg:hidden md:block sm:block block">
          <div className="xl:hidden lg:hidden md:block block sm:block ">
            <div className="app px-4">
              {accordionData.map((item, index) => (
                <AccordionFooter
                  key={index}
                  title={item.title}
                  content={item.content}
                />
              ))}
            </div>
          </div>
            </footer>*/}


{/*DESKTOP VIEW*/}
        <section className="xl:block lg:hidden md:hidden sm:hidden hidden bg-ivory-black ">
          <section className="container mx-auto figtree">
            <div className="grid grid-cols-4 xl:pt-16 xl:pb-12">
              <div>
                <p class="xl:text-xl text-white font-bold ">About Us</p>
                <p class="xl:pt-8 text-grey xl:text-base">
                  When An Unknown Printer Took Galley Of
                </p>
                <p class="text-grey xl:text-base">
                  Type And Scrambled It To Make
                </p>
                <p class="text-grey xl:text-base">Pspecimen Bookt Has.</p>

                <div className="flex ">
                  <div>
                    <p class="text-white xl:mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                        />
                      </svg>
                    </p>
                  </div>
                  <p class="text-grey xl:ml-4 xl:text-base xl:mt-4">
                    463 7th Ave, NY 10018, USA
                  </p>
                </div>

                <div className="flex ">
                  <div>
                    <p class="text-white xl:mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>
                    </p>
                  </div>
                  <p class="text-grey xl:ml-4 xl:text-base xl:mt-4">
                    +123-88-9900-456
                  </p>
                </div>

                <div className="flex xl:mt-6">
                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ">
                      <i className="fab fa-facebook"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full xl:ml-4 lg:ml-2">
                      <i className="fab fa-linkedin"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full xl:ml-4 lg:ml-2">
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full xl:ml-4 lg:ml-2">
                      <i className="fab fa-youtube"></i>
                    </button>
                  </a>
                </div>
              </div>

              <div className="xl:ml-12">
                <p class="xl:text-xl text-white font-bold">Resources</p>
                <div class="class">
                  <div class="class p:hover">
                    <p class="xl:pt-8 text-grey xl:text-base">About</p>
                    <p class="xl:pt-3 text-grey xl:text-base">Contact</p>
                    <p class="xl:pt-3 text-grey xl:text-base">Refund</p>
                    <p class="xl:pt-3 text-grey xl:text-base">HelpCenter</p>
                    <p class="xl:pt-3 text-grey xl:text-base">Returnpolicies</p>
                    <p class="xl:pt-3 text-grey xl:text-base">TermConditions</p>
                  </div>
                </div>
              </div>

              <div className="xl:ml-12">
                <p class="xl:text-xl text-white font-bold">Courses</p>
                <div class="class">
                  <div class="class p:hover">
                    <p class="xl:pt-8 text-grey xl:text-base">Life Coach</p>
                    <p class="xl:pt-3 text-grey xl:text-base">BusinessCoach</p>
                    <p class="xl:pt-3 text-grey xl:text-base">HealthCoach</p>
                    <p class="xl:pt-3 text-grey xl:text-base">Development</p>
                    <p class="xl:pt-3 text-grey xl:text-base">Web Design</p>
                    <p class="xl:pt-3 text-grey xl:text-base">SEOOptimize</p>
                  </div>
                </div>
              </div>

              <div className="xl:ml-6">
                <p class="xl:text-xl text-white font-bold">Join Our Newsletter</p>
                <p class="xl:pt-8 xl:text-base text-grey">Nown Printer Took Galley Type And Scrambled It To Make Following With Us</p>
                <div className="flex">
                <input class="xl:mt-4 xl:w-12/12 border-6 bg-ivory-black text-grey rounded-md p-4" type="text" id="text" name="text" placeholder="Your Email Address"/>
                 <div>
                 <p class="text-center text-white   font-bold xl:text-base rounded-md bg-newshade33  xl:-ml-20 xl:mt-8 xl:mr-4 xl:px-2">Sign Up</p>
                 </div>
                </div>
                <p class="xl:text-base text-grey xl:pt-2">We Only Send Interesting And Relevant Emails.</p>
          </div>
            </div>

            <hr class=" my-6 border-blue-50"></hr>
          </section>

          <div className="flex justify-between container mx-auto xl:mt-2 xl:pb-8">
            <div>
            <img class="xl:w-10/12 " src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/logo-light.svg" alt=""/>
            </div>

            <div className="flex xl:mt-4">
              <a href="/">
               <p class="xl:text-base text-grey">Privacy Policy</p>
               </a>
               <a href="/">
               <p class="xl:text-base text-grey">.</p>
               </a>
               <a href="/">
               <p class="xl:text-base text-grey">Term Conditions</p>
               </a>
              </div>

              <div>
              <p class="xl:text-base text-grey xl:mt-4">© 2024 quiklearn. All Rights Reserved by RadiusTheme</p>
              </div>
          </div>
        </section>


        {/*LANSCAPE VIEW*/}

        <section className="xl:hidden lg:block md:hidden sm:hidden hidden bg-ivory-black ">
          <section className="container mx-auto figtree">
            <div className="grid grid-cols-3 pt-16 pb-12">
              <div className="ml-20">
                <p class="text-xl text-white font-bold ">About Us</p>
                <p class="pt-8 text-grey text-base">
                  When An Unknown Printer Took  
                </p>
                <p class="text-grey text-base">
                 Type And Scrambled It To Make
                </p>
                <p class="text-grey text-base">Pspecimen Bookt Has.</p>

                <div className="flex ">
                  <div>
                    <p class="text-white mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                        />
                      </svg>
                    </p>
                  </div>
                  <p class="text-grey ml-4 text-base mt-4">
                    463 7th Ave, NY 10018, USA
                  </p>
                </div>

                <div className="flex ">
                  <div>
                    <p class="text-white mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>
                    </p>
                  </div>
                  <p class="text-grey ml-4 text-base mt-4">
                    +123-88-9900-456
                  </p>
                </div>

                <div className="flex mt-6">
                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ">
                      <i className="fab fa-facebook"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ml-4 ">
                      <i className="fab fa-linkedin"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ml-4 ">
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ml-4 ">
                      <i className="fab fa-youtube"></i>
                    </button>
                  </a>
                </div>
              </div>



              <div className="ml-32">
                <p class="text-xl text-white font-bold">Resources</p>
                <div class="class">
                  <div class="class p:hover">
                    <p class="pt-8 text-grey text-base">About</p>
                    <p class="pt-3 text-grey text-base">Contact</p>
                    <p class="pt-3 text-grey text-base">Refund</p>
                    <p class="pt-3 text-grey text-base">HelpCenter</p>
                    <p class="pt-3 text-grey text-base">Returnpolicies</p>
                    <p class="pt-3 text-grey text-base">TermConditions</p>
                  </div>
                </div>
              </div>

              <div className="ml-20">
                <p class="text-xl text-white font-bold">Courses</p>
                <div class="class">
                  <div class="class p:hover">
                    <p class="pt-8 text-grey text-base">Life Coach</p>
                    <p class="pt-3 text-grey text-base">BusinessCoach</p>
                    <p class="pt-3 text-grey text-base">HealthCoach</p>
                    <p class="pt-3 text-grey text-base">Development</p>
                    <p class="pt-3 text-grey text-base">Web Design</p>
                    <p class="pt-3 text-grey text-base">SEOOptimize</p>
                  </div>
                </div>
              </div>
               </div>
               
               
              <div className="">
                <p class="text-xl text-white font-bold text-center">Join Our Newsletter</p>
                <p class="pt-4 text-base text-grey text-center">Nown Printer Took Galley Type And Scrambled It To Make Following With Us</p>
                <div className="flex justify-center">
                <input class="mt-4 w-6/12 border-6 bg-ivory-black text-grey rounded-md p-4" type="text" id="text" name="text" placeholder="Your Email Address"/>
                 <div>
                 <p class="text-center text-white   font-bold text-base rounded-md bg-newshade33  -ml-28 mt-6 mr-4 px-4 py-2">Sign Up</p>
                 </div>
                </div>
                <p class="text-base text-grey pt-2 text-center">We Only Send Interesting And Relevant Emails.</p>
          </div>
            

            <hr class=" my-6 border-blue-50"></hr>
          </section>

          <div className="flex justify-between container mx-auto pb-8">
            <div>
            <img class="w-10/12 " src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/logo-light.svg" alt=""/>
            </div>

            <div className="flex mt-4">
              <a href="/">
               <p class="text-base text-grey">Privacy Policy</p>
               </a>
               <a href="/">
               <p class="text-base text-grey">.</p>
               </a>
               <a href="/">
               <p class="text-base text-grey">Term Conditions</p>
               </a>
              </div>

              <div>
              <p class="text-base text-grey mt-4">© 2024 quiklearn.All Rights Reserved by RadiusTheme</p>
              </div>
          </div>
        </section>

        {/*TAB VIEW*/}
        <section className="bg-ivory-black">
        <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto bg-ivory-black figtree pb-8">
          <div>
         <div className="grid grid-cols-2 pt-12">
           <div>
           <div className="ml-20">
                <p class="text-xl text-white font-bold ">About Us</p>
                <p class="pt-4 text-grey text-base">
                  When An Unknown Printer Took  
                </p>
                <p class="text-grey text-base">
                 Type And Scrambled It To Make
                </p>
                <p class="text-grey text-base">Pspecimen Bookt Has.</p>

                <div className="flex ">
                  <div>
                    <p class="text-white mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                        />
                      </svg>
                    </p>
                  </div>
                  <p class="text-grey ml-4 text-base mt-4">
                    463 7th Ave, NY 10018, USA
                  </p>
                </div>

                <div className="flex ">
                  <div>
                    <p class="text-white mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>
                    </p>
                  </div>
                  <p class="text-grey ml-4 text-base mt-4">
                    +123-88-9900-456
                  </p>
                </div>

                <div className="flex mt-6">
                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ">
                      <i className="fab fa-facebook"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ml-4 ">
                      <i className="fab fa-linkedin"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ml-4 ">
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ml-4 ">
                      <i className="fab fa-youtube"></i>
                    </button>
                  </a>
                </div>
              </div>
              </div>


              <div className="ml-16">
                <p class="text-xl text-white font-bold">Resources</p>
                <div class="class">
                  <div class="class p:hover">
                    <p class="pt-4 text-grey text-base">About</p>
                    <p class="pt-2 text-grey text-base">Contact</p>
                    <p class="pt-2 text-grey text-base">Refund</p>
                    <p class="pt-2 text-grey text-base">HelpCenter</p>
                    <p class="pt-2 text-grey text-base">Returnpolicies</p>
                    <p class="pt-2 text-grey text-base">TermConditions</p>
                  </div>
                </div>
              </div>
              </div>

              
              </div>
        </section>

        <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto bg-ivory-black ">
                  <div className="grid grid-cols-2 mt-8">
                 <div>
                 <div className="ml-26">
                <p class="text-xl text-white font-bold">Courses</p>
                <div class="">
                  <div class="">
                    <p class="pt-4 text-grey text-base">Life Coach</p>
                    <p class="pt-2 text-grey text-base">BusinessCoach</p>
                    <p class="pt-2 text-grey text-base">HealthCoach</p>
                    <p class="pt-2 text-grey text-base">Development</p>
                    <p class="pt-2 text-grey text-base">Web Design</p>
                    <p class="pt-2 text-grey text-base">SEOOptimize</p>
                  </div>
                </div>
              </div>
                  </div>


                  <div className="ml-16">
                <p class="text-xl text-white font-bold ">Join Our Newsletter</p>
                <p class="pt-4 text-base text-grey ">Nown Printer Took Galley Type And<br></br> Scrambled It To Make Following With Us</p>
                <div className="flex ">
                <input class="mt-4 w-10/12 border-6 bg-ivory-black text-grey rounded-md p-4" type="text" id="text" name="text" placeholder="Your Email Address"/>
                 <div>
                 <p class="text-center text-white font-bold text-base rounded-md bg-newshade33  -ml-28 mt-6 mr-4 px-4 py-2">Sign Up</p>
                 </div>
                </div>
                <p class="text-base text-grey pt-2 ">We Only Send Interesting And<br></br> Relevant Emails.</p>
          </div>
              </div>

              <hr class=" my-6 border-blue-50"></hr>
          

          <div className=" container mx-auto pb-8">
            <div className="center-button">
            <img class="w-40 " src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/logo-light.svg" alt=""/>
            </div>

            <div className="flex mt-2 justify-center">
              <a href="/">
               <p class="text-base text-grey">Privacy Policy</p>
               </a>
               <a href="/">
               <p class="text-base text-grey">.</p>
               </a>
               <a href="/">
               <p class="text-base text-grey">Term Conditions</p>
               </a>
              </div>

              <div>
              <p class="text-base text-grey mt-2 text-center">© 2024 quiklearn.All Rights Reserved by RadiusTheme</p>
              </div>
          </div>
        </section>
        </section>


        {/*MOBILE VIEW*/}
        <section className="xl:hidden lg:hidden md:hidden sm:block block bg-ivory-black figtree justify-center">
            <div>
            <div className="">
                <p class="text-xl text-white font-bold pt-12 text-center">About Us</p>
                <p class="pt-4 text-grey text-base text-center">
                  When An Unknown Printer Took  
                </p>
                <p class="text-grey text-base text-center">
                 Type And Scrambled It To Make
                </p>
                <p class="text-grey text-base text-center">Pspecimen Bookt Has.</p>

                <div className="flex justify-center">
                  <div>
                    <p class="text-white mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                        />
                      </svg>
                    </p>
                  </div>
                  <p class="text-grey ml-4 text-base mt-4">
                    463 7th Ave, NY 10018, USA
                  </p>
                </div>

                <div className="flex justify-center">
                  <div>
                    <p class="text-white mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>
                    </p>
                  </div>
                  <p class="text-grey ml-4 text-base mt-4">
                    +123-88-9900-456
                  </p>
                </div>

                <div className="flex mt-6 justify-center">
                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ">
                      <i className="fab fa-facebook"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ml-4 ">
                      <i className="fab fa-linkedin"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ml-4 ">
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>

                  <a href="homepage2/">
                    <button className="bg-newshade33 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ml-4 ">
                      <i className="fab fa-youtube"></i>
                    </button>
                  </a>
                </div>
              </div>
              

              <div>
              <div className="mt-8">
                <p class="text-xl text-white font-bold text-center">Resources</p>
                <div class="">
                  <div class="">
                    <p class="pt-4 text-grey text-base text-center">About</p>
                    <p class="pt-2 text-grey text-base text-center">Contact</p>
                    <p class="pt-2 text-grey text-base text-center">Refund</p>
                    <p class="pt-2 text-grey text-base text-center">HelpCenter</p>
                    <p class="pt-2 text-grey text-base text-center">Returnpolicies</p>
                    <p class="pt-2 text-grey text-base text-center">TermConditions</p>
                  </div>
                </div>
              </div>
                </div>

                <div className="mt-8">
                <p class="text-xl text-white font-bold text-center">Join Our Newsletter</p>
                <p class="pt-4 text-base text-grey text-center">Nown Printer Took Galley Type And<br></br> Scrambled It To Make Following With Us</p>
                <div className="flex justify-center">
                <input class="mt-4 w-8/12 border-6 bg-ivory-black text-grey rounded-md p-4" type="text" id="text" name="text" placeholder="Your Email Address"/>
                 <div>
                 <p class="text-center text-white font-bold text-base rounded-md bg-newshade33  -ml-28 mt-6 mr-4 px-4 py-2">Sign Up</p>
                 </div>
                </div>
                <p class="text-base text-grey pt-2 text-center">We Only Send Interesting And<br></br> Relevant Emails.</p>
          </div>

          <hr class=" my-6 border-blue-50"></hr>
          

          <div className=" container mx-auto pb-8">
            <div className="center-button">
            <img class="w-40 " src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/logo-light.svg" alt=""/>
            </div>

            <div className="flex mt-2 justify-center">
              <a href="/">
               <p class="text-base text-grey">Privacy Policy</p>
               </a>
               <a href="/">
               <p class="text-base text-grey">.</p>
               </a>
               <a href="/">
               <p class="text-base text-grey">Term Conditions</p>
               </a>
              </div>

              <div>
              <p class="text-base text-grey mt-2 text-center">© 2024 quiklearn.All Rights Reserved<br></br> by RadiusTheme</p>
              </div>
          </div>
            </div>
        </section>
      </>
    );
  }
}
