import React, {useState} from "react";

import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import banner from "assets/img/banner.jpg";
import discuss from "assets/img/discuss.png";
import accurate from "assets/img/accurate.png";
import revenue from "assets/img/revenue.png";
import calendar from "assets/img/calendar.png";
import bestconsulting from "assets/img/bestconsulting.png";
import peoplecohension from "assets/img/peoplecohension.png";
import projectreporting from "assets/img/projectreporting.png";
import businessanalysis from "assets/img/businessanalysis.png";
import softwaredevelopment from "assets/img/softwaredevelopment.png";
import webdevelopment from "assets/img/webdevelopment.png";
import seoexpert from "assets/img/seoexpert.png";
import datacollection from "assets/img/datacollection.png";
import worksetup from "assets/img/worksetup.png";
import creative from "assets/img/creative.png";
import location from "assets/img/location.png";
import phone from "assets/img/phone.png";
import email from "assets/img/mail.png";
import dropdown from "assets/img/dropdown.png";


export default function Services2() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  
  return (
    <>
      <Indexnavbar2 fixed />

      <section className="xl:block lg:block md:block sm:block block xl:pt-20 ">
        <div class="page_banner">
          <div
            class="bg-newshade42 "
            style={{
              backgroundImage: `url("")`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto figtree">
              <p class="xl:text-5xl font-bold text-white text-center xl:pt-28 lg:pt-32 lg:text-4xl md:pt-32 md:text-4xl text-4xl pt-28">
                Services
              </p>
              <div className="flex justify-center xl:mt-4 lg:mt-2 md:mt-2 mt-2">
                <a href="/homepage1">
                  <p class="xl:text-base text-white blue-on-hover lg:text-base md:text-base text-base">Home</p>
                </a>
                <p class="xl:text-base text-white xl:ml-2 lg:text-base lg:ml-2 md:text-base md:ml-2 text-base ml-2">/</p>
                <p class="xl:text-base text-white xl:ml-2 lg:text-base lg:ml-2  md:text-base md:ml-2 text-base ml-2">Services</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden  figtree bg-newshade41 xl:pb-24">
        <p class="xl:text-base text-blue text-center font-bold xl:pt-24">
          What We Do
        </p>
        <p class="xl:text-5xl text-darkblack font-bold text-center xl:pt-4">
          Services for You
        </p>

        <div className="grid grid-cols-3 container mx-auto xl:mt-12 ">
          <div>
            <div className="border xl:w-10/12 rounded-lg  hover:border-blue-500 xl:ml-10">
              <div className="center-button">
                <div className="oval  bg-newshade16 xl:mt-12 ">
                  <div className="center-button">
                    <img class="w-20 xl:pt-4" src={projectreporting} alt="" />
                  </div>
                </div>
              </div>
              <p class="xl:text-xl text-center text-darkblack font bold xl:pt-6 blue-on-hover">
                Project Reporting
              </p>
              <p class=" xl:text-base text-center xl:pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center">data center database.</p>

              <div className="center-button xl:pb-8">
                <div className="w-16 bg-newshade16 rounded-full xl:mt-6 p-2 red-on-hover5">
                  <p class="xl:text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border xl:w-10/12 rounded-lg  hover:border-blue-500 xl:ml-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 xl:mt-12 ">
                  <div className="center-button">
                    <img class="w-20 xl:pt-4" src={businessanalysis} alt="" />
                  </div>
                </div>
              </div>
              <p class="xl:text-xl text-center text-darkblack font bold xl:pt-6 blue-on-hover">
                Business Analysis
              </p>
              <p class=" xl:text-base text-center xl:pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center">data center database.</p>

              <div className="center-button xl:pb-8">
                <div className="w-16 bg-newshade16 rounded-full xl:mt-6 p-2 red-on-hover5">
                  <p class="xl:text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>



          <div>
            <div className="border  xl:w-10/12 rounded-lg  hover:border-blue-500">
              <div className="center-button">
                <div className="oval  bg-newshade16 xl:mt-12 ">
                  <div className="center-button">
                    <img
                      class="w-20 xl:pt-4"
                      src={softwaredevelopment}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <p class="xl:text-xl text-center text-darkblack font bold xl:pt-6 blue-on-hover">
                Software Development
              </p>
              <p class=" xl:text-base text-center xl:pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center">data center database.</p>

              <div className="center-button xl:pb-8">
                <div className="w-16 bg-newshade16 rounded-full xl:mt-6 p-2 red-on-hover5">
                  <p class="xl:text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 container mx-auto xl:mt-12">
          <div>
            <div className="border rounded-lg  hover:border-blue-500 xl:w-10/12 xl:ml-10">
              <div className="center-button">
                <div className="oval  bg-newshade16 xl:mt-12 ">
                  <div className="center-button">
                    <img class="w-20 xl:pt-4" src={webdevelopment} alt="" />
                  </div>
                </div>
              </div>
              <p class="xl:text-xl text-center text-darkblack font bold xl:pt-6 blue-on-hover">
                Web Development
              </p>
              <p class=" xl:text-base text-center xl:pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center">data center database.</p>

              <div className="center-button xl:pb-8">
                <div className="w-16 bg-newshade16 rounded-full xl:mt-6 p-2 red-on-hover5">
                  <p class="xl:text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border rounded-lg  hover:border-blue-500 xl:w-10/12 xl:ml-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 xl:mt-12 ">
                  <div className="center-button">
                    <img class="w-20 xl:pt-4" src={seoexpert} alt="" />
                  </div>
                </div>
              </div>
              <p class="xl:text-xl text-center text-darkblack font bold xl:pt-6 blue-on-hover">
                SEO Optimized Expert
              </p>
              <p class=" xl:text-base text-center xl:pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center">data center database.</p>

              <div className="center-button xl:pb-8">
                <div className="w-16 bg-newshade16 rounded-full xl:mt-6 p-2 red-on-hover5">
                  <p class="xl:text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border rounded-lg  hover:border-blue-500 xl:w-10/12">
              <div className="center-button">
                <div className="oval  bg-newshade16 xl:mt-12 ">
                  <div className="center-button">
                    <img class="w-20 xl:pt-4" src={peoplecohension} alt="" />
                  </div>
                </div>
              </div>
              <p class="xl:text-xl text-center text-darkblack font bold xl:pt-6 blue-on-hover">
                Analytic Solutions
              </p>
              <p class=" xl:text-base text-center xl:pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="xl:text-base text-center">data center database.</p>

              <div className="center-button xl:pb-8">
                <div className="w-16 bg-newshade16 rounded-full xl:mt-6 p-2 red-on-hover5">
                  <p class="xl:text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-24 container mx-auto figtree">
        <p class="xl:text-base text-blue font-bold text-center">
          Our Work Process
        </p>
        <p class="xl:text-5xl font-bold xl:pt-4 text-center text-darkblack">
          We Complete Every<br></br> Step Carefully
        </p>

        <div className="grid grid-cols-3 xl:mt-12">
          <div>
            <div className="rounded-lg bg-newshade39 xl:pb-8 red-on-hover5">
              <div>
                <p class="xl:text-9xl font-bold xl:ml-8 xl:pt-8">01</p>
              </div>
              <div className="center-button xl:mt-4">
                <img class="w-32 " src={datacollection} />
              </div>

              <p class="xl:text-xl font-bold text-white xl:pt-12 text-center">
                Collecting Info Data
              </p>
            </div>
          </div>

          <div>
            <div className="rounded-lg bg-newshade39 xl:pb-8 red-on-hover5 xl:ml-4">
              <div>
                <p class="xl:text-9xl font-bold xl:ml-8 xl:pt-8">02</p>
              </div>
              <div className="center-button xl:mt-4">
                <img class="w-32 " src={worksetup} />
              </div>

              <p class="xl:text-xl font-bold text-white xl:pt-12 text-center">
                Setup Work Target
              </p>
            </div>
          </div>

          <div>
            <div className="rounded-lg bg-newshade39 xl:pb-8 red-on-hover5 xl:ml-4">
              <div>
                <p class="xl:text-9xl font-bold xl:ml-8 xl:pt-8">03</p>
              </div>
              <div className="center-button xl:mt-4">
                <img class="w-32 " src={creative} />
              </div>

              <p class="xl:text-xl font-bold text-white xl:pt-12 text-center">
                Creative Ideas
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-24 bg-newshade42">
        <div
          style={{
            backgroundImage: `url("https://psdtowpwork.com/wp/graptor/main/wp-content/uploads/2021/11/4-1.png")`,
            width: "100%",
            height: "800px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className=" grid grid-cols-2 container mx-auto figtree xl:pt-24">
            <div className="rounded-lg bg-newshade33 xl:w-10/12">
              <p class="xl:text-5xl font-bold text-white xl:ml-8 xl:pt-16">
                Contact Us
              </p>
              <div className="flex xl:mt-8 ">
                <div className="h-16 w-16 rounded-md border-9 xl:ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={location} />
                  </div>
                </div>
                <div>
                  <p class="xl:text-base1 font-bold text-white xl:ml-6">
                    Our Address
                  </p>
                  <p class="xl:text-base text-grey xl:pt-2 xl:ml-6 font-semibold">
                    PSD Building, 2 AlBahr St, Loskia
                  </p>
                  <p class="xl:text-base text-grey  xl:ml-6 font-semibold">
                    sripur, jamukara.
                  </p>
                </div>
              </div>

              <div className="flex xl:mt-6 ">
                <div className="h-16 w-16 rounded-md border-9 xl:ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={phone} />
                  </div>
                </div>
                <div>
                  <p class="xl:text-base1 font-bold text-white xl:ml-6">
                    {" "}
                    Our Phone
                  </p>
                  <p class="xl:text-base text-grey xl:pt-2 xl:ml-6 font-semibold">
                    Telephone : 0029129102320
                  </p>
                  <p class="xl:text-base text-grey  xl:ml-6 font-semibold">
                    Mobile : 000 2324 39493
                  </p>
                </div>
              </div>

              <div className="flex xl:mt-6 xl:pb-24">
                <div className="h-16 w-16 rounded-md border-9 xl:ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={email} />
                  </div>
                </div>
                <div>
                  <p class="xl:text-base1 font-bold text-white xl:ml-6">
                    Our Email
                  </p>
                  <p class="xl:text-base text-grey xl:pt-2 xl:ml-6 font-semibold">
                    Main Email : name@website.com
                  </p>
                  <p class="xl:text-base text-grey  xl:ml-6 font-semibold">
                    Inquiries : Info@orex.com
                  </p>
                </div>
              </div>
            </div>

            <div className="xl:-ml-16">
              <p className="xl:text-5xl font-bold text-white">
                Request A Quote
              </p>
              <p className="xl:text-base text-white xl:pt-2">
                We will be happy to answer your questions.
              </p>
              <div>
                <form>
                  <div className="flex xl:mt-6">
                    <input
                      class="border-8 rounded-md xl:w-6/12 p-4  xl:text-base"
                      type="text"
                      id="text"
                      placeholder="Full Name"
                    />
                    <input
                      class="border-8  rounded-md xl:w-6/12 p-4  xl:text-base xl:ml-2"
                      type="text"
                      id="text"
                      placeholder="Your Email"
                    />
                  </div>
                  <input
                    class="border-8  rounded-md xl:w-12/12 p-4  xl:text-base xl:mt-6"
                    type="text"
                    id="text"
                    placeholder="Subject"
                  />
                  <textarea
                    class=" border-8 xl:mt-6 xl:text-base xl:w-12/12 rounded-md"
                    id="w3review"
                    name="w3review"
                    placeholder="Message"
                    rows="6"
                    cols="0"
                  ></textarea>
                  <div className="flex xl:mt-4">
                    <input
                      class=""
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                    />
                    <p class="xl:text-base -mt-2 xl:ml-2 text-white">
                      Also subscribe us.
                    </p>
                    <button class="  bg-newshade33 text-center rounded-md xl:py-2 xl:px-12 text-white xl:text-base xl:ml-96">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-12 container mx-auto figtree">
        <div className="flex ">
          <div>
            <p class="xl:text-5xl font-bold text-darkblack">
              Looking for the Best IT Business Solutions?
            </p>
            <p class="xl:text-base1 xl:pt-2">
              As a app web business expert, We will help to organize.
            </p>
          </div>
          <div>
            <button class="bg-newshade33 text-white xl:px-12 xl:py-3 rounded-md xl:ml-16 xl:mt-6 xl:text-base font-bold red-on-hover4">
              Get A Quote ➔
            </button>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden hidden sm:hidden xl:mt-24  figtree bg-newshade40 xl:pb-24">
        <div className="grid grid-cols-2 container mx-auto">
          <div>
            <p class="xl:text-base font-bold text-white xl:pt-16">
              IMPROVED HOSTING
            </p>
            <p class="xl:text-5xl font-bold text-white xl:pt-4">
              Faster, better hosting is<br></br> right here
            </p>
            <div>
              <img
                class="xl:w-10/12 xl:mt-8"
                src="https://hoka.peerduck.com/wp-content/uploads/2022/06/lrkgm-1024x788.png"
                alt=""
              />
            </div>
          </div>

          <div>
            <p class="xl:text-base1 text-grey xl:pt-16 ">
              We offer a wide range of hosting solutions, from web hosting to
            </p>
            <p class="xl:text-base1 text-grey">
              dedicated servers. Everything you need is right here.
            </p>

            <div className="flex xl:mt-12 rounded-lg bg-newshade43 p-8">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/gkmb.png"
                  alt=""
                />
              </div>
              <div>
                <p class="xl:text-2xl font-bold text-white xl:ml-8">
                  Next level
                </p>
                <p class="xl:text-base text-grey xl:pt-2 xl:ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>

            <div className="flex xl:mt-6 rounded-lg p-8" id="box1">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/tgmt.png"
                  alt=""
                />
              </div>
              <div>
                <p class="xl:text-2xl font-bold text-white xl:ml-8">Security</p>
                <p class="xl:text-base text-grey xl:pt-2 xl:ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>

            <div className="flex xl:mt-6 rounded-lg p-8" id="box1">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/lrgk.png"
                  alt=""
                />
              </div>
              <div>
                <p class="xl:text-2xl font-bold text-white xl:ml-8">
                  Reliable hosting
                </p>
                <p class="xl:text-base text-grey xl:pt-2 xl:ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-newshade33 xl:mt-12 container mx-auto rounded-lg xl:w-10/12 p-4">
          <div className="grid grid-cols-2 figtree ">
            <div className="xl:ml-28">
              <p class="xl:text-4xl text-white font-bold xl:pt-16">
                Sign up now and get 30-day
              </p>
              <p class="xl:text-4xl text-white font-bold  ">
                money-back guarantee
              </p>
              <p class="xl:text-base1 font-bold text-white xl:pt-5">
                We are happy to assist you.
              </p>
            </div>

            <div>
              <div>
                <img
                  class="xl:w-8/12  xl:mt-8 rounded-md"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/kgmtk.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 figtree container mx-auto xl:mt-20">
          <div className="xl:ml-8">
            <p class="xl:text-base text-white font-bold ">SUPPORT</p>
            <p class="xl:text-5xl text-white font-bold xl:pt-4">
              Dedicated support team <br></br>available 24/7
            </p>
            <p class="xl:text-base1 xl:pt-4 text-grey">
              Using a chat, ticket or phone, you can reach brilliant minds
              <br></br> with years of experience. We offer a wide range of
              hosting<br></br> solutions, from web hosting to dedicated servers.
              Everything<br></br> you need is right here.
            </p>
            <p class="xl:text-base font-bold text-white xl:pt-4 blue-on-hover">
              <a href="/homepage1">Learn More ➔</a>
            </p>
          </div>

          <div>
            <img
              class="xl:w-10/12"
              src="https://hoka.peerduck.com/wp-content/uploads/2022/06/kjef-1024x831.png"
              alt=""
            />
          </div>
        </div>

        <div className="xl:mt-16">
          <p class="xl:text-base text-white font-bold text-center">
            KNOWLEDGE BASE
          </p>
          <p class="xl:text-5xl text-white font-bold text-center xl:pt-4">
            Still have questions?
          </p>
          <p class="xl:text-base1 text-grey xl:pt-4 text-center">
            We offer a wide range of hosting solutions, from web hosting
            <br></br> to dedicated servers. Everything you need is right here.
          </p>
        </div>


          
          <div className=" container mx-auto xl:mt-16  xl:mr-52 ">
          
          
          <div className="flex justify-between  ">
      <p className="xl:text-2xl text-white font-semibold xl:ml-32">What is web hosting?</p>
      <p
        className="xl:text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen5(!isOpen5)}
      >
        ﹀
      </p>
      </div>
      {isOpen5 && (
        <p className="xl:text-base text-grey pt-2 xl:ml-32">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

            
            <div className="flex justify-between xl:mt-8">
      <p className="xl:text-2xl text-white font-semibold xl:ml-32">How do I create a website?</p>
      <p
        className="xl:text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        ﹀
      </p>
      </div>
      {isOpen && (
        <p className="xl:text-base text-grey pt-2 xl:ml-32">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
     
    

    <div className="flex justify-between xl:mt-8">
      <p className="xl:text-2xl text-white font-semibold xl:ml-32">How can I transfer my website?</p>
      <p
        className="xl:text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen1(!isOpen1)}
      >
        ﹀
      </p>
      </div>
      {isOpen1 && (
        <p className="xl:text-base text-grey pt-2 xl:ml-32">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
         


           
           <div className="flex justify-between xl:mt-8 ">
      <p className="xl:text-2xl text-white font-semibold xl:ml-32">How to set up a WooCommerce site?</p>
      <p
        className="xl:text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen2(!isOpen2)}
      >
        ﹀
      </p>
      </div>
      {isOpen2 && (
        <p className="xl:text-base text-grey pt-2 xl:ml-32">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

<div className="flex justify-between xl:mt-8">
      <p className="xl:text-2xl text-white font-semibold xl:ml-32">When will I be billed for renewal?</p>
      <p
        className="xl:text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen3(!isOpen3)}
      >
        ﹀
      </p>
      </div>
      {isOpen3 && (
        <p className="xl:text-base text-grey pt-2 xl:ml-32">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

<div className="flex justify-between xl:mt-8">
      <p className="xl:text-2xl text-white font-semibold xl:ml-32">What is a nameserver and DNS?</p>
      <p
        className="xl:text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen4(!isOpen4)}
      >
        ﹀
      </p>
      </div>
      {isOpen4 && (
        <p className="xl:text-base text-grey pt-2 xl:ml-32">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
           
       </div>
      </section>


      {/*LANDSCAPE VIEW*/}
      <section className="xl:hidden lg:block md:hidden sm:hidden hidden  figtree bg-newshade41 pb-24">
        <p class="xl:text-base text-blue text-center font-bold pt-20">
          What We Do
        </p>
        <p class="text-4xl text-darkblack font-bold text-center pt-4">
          Services for You
        </p>

        <div className="grid grid-cols-3 container mx-auto mt-12 ">
          <div>
            <div className="border w-10/12 rounded-lg  hover:border-blue-500 ml-10">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={projectreporting} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Project Reporting
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border w-10/12 rounded-lg  hover:border-blue-500 ml-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={businessanalysis} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Business Analysis
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border  w-10/12 rounded-lg  hover:border-blue-500">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img
                      class="w-20 pt-4"
                      src={softwaredevelopment}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Software Development
              </p>
              <p class="text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 container mx-auto mt-12">
          <div>
            <div className="border rounded-lg  hover:border-blue-500 w-10/12 ml-10">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={webdevelopment} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Web Development
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border rounded-lg  hover:border-blue-500 w-10/12 ml-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={seoexpert} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                SEO Optimized Expert
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border rounded-lg  hover:border-blue-500 w-10/12">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={peoplecohension} alt="" />
                  </div>
                </div>
              </div>
              <p class="xl:text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Analytic Solutions
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 container mx-auto figtree">
        <p class="text-base text-blue font-bold text-center">
          Our Work Process
        </p>
        <p class="text-4xl font-bold pt-4 text-center text-darkblack">
          We Complete Every<br></br> Step Carefully
        </p>

        <div className="grid grid-cols-3 mt-12">
          <div>
            <div className="rounded-lg bg-newshade39 pb-8 red-on-hover5 ml-4">
              <div>
                <p class="text-9xl font-bold ml-8 pt-8">01</p>
              </div>
              <div className="center-button mt-4">
                <img class="w-32 " src={datacollection} />
              </div>

              <p class="text-xl font-bold text-white pt-12 text-center">
                Collecting Info Data
              </p>
            </div>
          </div>

          <div>
            <div className="rounded-lg bg-newshade39 pb-8 red-on-hover5 ml-4">
              <div>
                <p class="text-9xl font-bold ml-8 pt-8">02</p>
              </div>
              <div className="center-button mt-4">
                <img class="w-32 " src={worksetup} />
              </div>

              <p class="text-xl font-bold text-white pt-12 text-center">
                Setup Work Target
              </p>
            </div>
          </div>

          <div>
            <div className="rounded-lg bg-newshade39 pb-8 red-on-hover5 ml-4 mr-4">
              <div>
                <p class="text-9xl font-bold ml-8 pt-8">03</p>
              </div>
              <div className="center-button mt-4">
                <img class="w-32 " src={creative} />
              </div>

              <p class="text-xl font-bold text-white pt-12 text-center">
                Creative Ideas
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 bg-newshade42">
        <div
          style={{
            backgroundImage: `url("https://psdtowpwork.com/wp/graptor/main/wp-content/uploads/2021/11/4-1.png")`,
            width: "100%",
            height: "700px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className=" grid grid-cols-2 container mx-auto figtree pt-24">
            <div className="rounded-lg bg-newshade33 w-10/12 ml-4">
              <p class="text-4xl font-bold text-white ml-8 pt-16">
                Contact Us
              </p>
              <div className="flex mt-8 ">
                <div className="h-16 w-16 rounded-md border-9 ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={location} />
                  </div>
                </div>
                <div>
                  <p class="text-base1 font-bold text-white ml-6">
                    Our Address
                  </p>
                  <p class="text-base text-grey pt-2 ml-6 font-semibold">
                    PSD Building, 2 AlBahr St, Loskia
                  </p>
                  <p class="text-base text-grey ml-6 font-semibold">
                    sripur, jamukara.
                  </p>
                </div>
              </div>

              <div className="flex mt-6 ">
                <div className="h-16 w-16 rounded-md border-9 ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={phone} />
                  </div>
                </div>
                <div>
                  <p class="text-base1 font-bold text-white ml-6">
                    {" "}
                    Our Phone
                  </p>
                  <p class="text-base text-grey pt-2 ml-6 font-semibold">
                    Telephone : 0029129102320
                  </p>
                  <p class="text-base text-grey ml-6 font-semibold">
                    Mobile : 000 2324 39493
                  </p>
                </div>
              </div>

              <div className="flex mt-6 pb-24">
                <div className="h-16 w-16 rounded-md border-9 ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={email} />
                  </div>
                </div>
                <div>
                  <p class="text-base1 font-bold text-white ml-6">
                    Our Email
                  </p>
                  <p class="text-base text-grey pt-2 ml-6 font-semibold">
                    Main Email : name@website.com
                  </p>
                  <p class="text-base text-grey ml-6 font-semibold">
                    Inquiries : Info@orex.com
                  </p>
                </div>
              </div>
            </div>

            <div className="-ml-12 mr-8">
              <p className="text-4xl font-bold text-white">
                Request A Quote
              </p>
              <p className="text-base text-white pt-2">
                We will be happy to answer your questions.
              </p>
              <div>
                <form>
                  <div className="flex mt-6">
                    <input
                      class="border-8 rounded-md w-6/12 p-4  text-base"
                      type="text"
                      id="text"
                      placeholder="Full Name"
                    />
                    <input
                      class="border-8  rounded-md w-6/12 p-4 text-base ml-2"
                      type="text"
                      id="text"
                      placeholder="Your Email"
                    />
                  </div>
                  <input
                    class="border-8  rounded-md w-12/12 p-4 text-base mt-6"
                    type="text"
                    id="text"
                    placeholder="Subject"
                  />
                  <textarea
                    class=" border-8 mt-6 text-base w-12/12 rounded-md"
                    id="w3review"
                    name="w3review"
                    placeholder="Message"
                    rows="6"
                    cols="0"
                  ></textarea>
                  <div className="flex mt-4">
                    <input
                      class=""
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                    />
                    <p class="text-base -mt-1 ml-2 text-white">
                      Also subscribe us.
                    </p>
                    <button class="  bg-newshade33 text-center rounded-md py-2 px-12 text-white text-base ml-52">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-12 container mx-auto figtree">
        <div className="flex ">
          <div>
            <p class="text-4xl font-bold text-darkblack ml-6">
              Looking for the Best IT Business Solutions?
            </p>
            <p class="text-base1 pt-2 ml-6">
              As a app web business expert, We will help to organize.
            </p>
          </div>
          <div>
            <button class="bg-newshade33 text-white px-12 py-3 rounded-md ml-8 mt-4 xl:text-base font-bold red-on-hover4">
              Get A Quote ➔
            </button>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden hidden sm:hidden mt-20  figtree bg-newshade40 pb-20">
        <div className="grid grid-cols-2 container mx-auto">
          <div className="ml-6">
            <p class="text-base font-bold text-white pt-16">
              IMPROVED HOSTING
            </p>
            <p class="text-4xl font-bold text-white pt-4">
              Faster, better hosting is<br></br> right here
            </p>
            <div>
              <img
                class="w-12/12 mt-8 "
                src="https://hoka.peerduck.com/wp-content/uploads/2022/06/lrkgm-1024x788.png"
                alt=""
              />
            </div>
          </div>

          <div className="mr-8">
            <p class="text-base1 text-grey pt-16 ">
              We offer a wide range of hosting solutions, from web hosting to
            </p>
            <p class="text-base1 text-grey">
              dedicated servers. Everything you need is right here.
            </p>

            <div className="flex mt-12 rounded-lg bg-newshade43 p-8">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/gkmb.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-2xl font-bold text-white ml-8">
                  Next level
                </p>
                <p class="text-base text-grey pt-2 ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>

            <div className="flex mt-6 rounded-lg p-8" id="box1">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/tgmt.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-2xl font-bold text-white ml-8">Security</p>
                <p class="text-base text-grey pt-2 ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>

            <div className="flex mt-6 rounded-lg p-8" id="box1">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/lrgk.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-2xl font-bold text-white ml-8">
                  Reliable hosting
                </p>
                <p class="text-base text-grey pt-2 ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-newshade33 mt-12 container mx-auto rounded-lg w-10/12 p-4">
          <div className="grid grid-cols-2 figtree ">
            <div className="ml-4">
              <p class="text-3xl text-white font-bold pt-12">
                Sign up now and get 30-day
              </p>
              <p class="text-3xl text-white font-bold  ">
                money-back guarantee
              </p>
              <p class="text-base1 font-bold text-white pt-4">
                We are happy to assist you.
              </p>
            </div>

            <div>
              <div>
                <img
                  class="w-10/12  mt-6 rounded-md ml-8"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/kgmtk.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 figtree container mx-auto mt-20">
          <div className="ml-8">
            <p class="text-base text-white font-bold ">SUPPORT</p>
            <p class="text-4xl text-white font-bold pt-4">
              Dedicated support team <br></br>available 24/7
            </p>
            <p class="text-base1 pt-4 text-grey">
              Using a chat, ticket or phone, you can reach brilliant minds
              <br></br> with years of experience. We offer a wide range of
              hosting<br></br> solutions, from web hosting to dedicated servers.
              Everything<br></br> you need is right here.
            </p>
            <p class="text-base font-bold text-white pt-4 blue-on-hover">
              <a href="/homepage1">Learn More ➔</a>
            </p>
          </div>

          <div>
            <img
              class="w-10/12"
              src="https://hoka.peerduck.com/wp-content/uploads/2022/06/kjef-1024x831.png"
              alt=""
            />
          </div>
        </div>

        <div className="mt-16">
          <p class="text-base text-white font-bold text-center">
            KNOWLEDGE BASE
          </p>
          <p class="text-4xl text-white font-bold text-center pt-4">
            Still have questions?
          </p>
          <p class="text-base1 text-grey pt-4 text-center ">
            We offer a wide range of hosting solutions, from web hosting
            <br></br> to dedicated servers. Everything you need is right here.
          </p>
        </div>


          
          <div className=" container mx-auto mt-16  ">
          
          
          <div className="flex justify-between ml-16 mr-20  ">
      <p className="text-2xl text-white font-semibold ">What is web hosting?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen5(!isOpen5)}
      >
        ﹀
      </p>
      </div>
      {isOpen5 && (
        <p className="text-base text-grey pt-2 ml-16 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

            
            <div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold">How do I create a website?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        ﹀
      </p>
      </div>
      {isOpen && (
        <p className="text-base text-grey pt-2 ml-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
     
    

    <div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">How can I transfer my website?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen1(!isOpen1)}
      >
        ﹀
      </p>
      </div>
      {isOpen1 && (
        <p className="text-base text-grey pt-2 ml-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
         


           
           <div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">How to set up a WooCommerce site?</p>
      <p
        className="xl:text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen2(!isOpen2)}
      >
        ﹀
      </p>
      </div>
      {isOpen2 && (
        <p className="text-base text-grey pt-2 ml-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

<div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">When will I be billed for renewal?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen3(!isOpen3)}
      >
        ﹀
      </p>
      </div>
      {isOpen3 && (
        <p className="text-base text-grey pt-2 ml-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

<div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">What is a nameserver and DNS?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen4(!isOpen4)}
      >
        ﹀
      </p>
      </div>
      {isOpen4 && (
        <p className="text-base text-grey pt-2 ml-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
           
       </div>
      </section>


      {/*TAB VIEW*/}

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden  bg-newshade41">
      <p class="text-base text-blue text-center font-bold pt-20">
          What We Do
        </p>
        <p class="text-4xl text-darkblack font-bold text-center pt-2">
          Services for You
        </p>

        <div className="grid grid-cols-2 mt-12">
         <div>
            <div className="border w-10/12 rounded-lg  hover:border-blue-500 ml-10">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={projectreporting} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Project Reporting
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border w-10/12 rounded-lg  hover:border-blue-500 ml-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={businessanalysis} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Business Analysis
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>
       </div>


       <div className="grid grid-cols-2 mt-12">

       <div>
            <div className="border  w-10/12 rounded-lg  hover:border-blue-500 ml-10">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img
                      class="w-20 pt-4"
                      src={softwaredevelopment}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Software Development
              </p>
              <p class="text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>


          <div>
            <div className="border rounded-lg  hover:border-blue-500 w-10/12 ml-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={webdevelopment} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Web Development
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>
     </div>

     <div className="grid grid-cols-2 mt-12">
     <div>
            <div className="border rounded-lg  hover:border-blue-500 w-10/12 ml-10">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={seoexpert} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                SEO Optimized Expert
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border rounded-lg  hover:border-blue-500 w-10/12 ml-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={peoplecohension} alt="" />
                  </div>
                </div>
              </div>
              <p class="xl:text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Analytic Solutions
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>
     </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20">
      <p class="text-base text-blue font-bold text-center">
          Our Work Process
        </p>
        <p class="text-4xl font-bold pt-2 text-center text-darkblack">
          We Complete Every<br></br> Step Carefully
        </p>
      <div className="grid grid-cols-2 mt-12">
        <div>
            <div className="rounded-lg bg-newshade39 pb-8 red-on-hover5 ml-4">
              <div>
                <p class="text-9xl font-bold ml-8 pt-8">01</p>
              </div>
              <div className="center-button mt-4">
                <img class="w-32 " src={datacollection} />
              </div>

              <p class="text-xl font-bold text-white pt-12 text-center">
                Collecting Info Data
              </p>
            </div>
          </div>

          <div>
            <div className="rounded-lg bg-newshade39 pb-8 red-on-hover5 ml-4 mr-4">
              <div>
                <p class="text-9xl font-bold ml-8 pt-8">02</p>
              </div>
              <div className="center-button mt-4">
                <img class="w-32 " src={worksetup} />
              </div>

              <p class="text-xl font-bold text-white pt-12 text-center">
                Setup Work Target
              </p>
            </div>
          </div>
      </div>
       
       <div className="w-6/12 ml-52">
            <div className="rounded-lg bg-newshade39 pb-8 red-on-hover5  mt-12">
              <div>
                <p class="text-9xl font-bold ml-8 pt-8">03</p>
              </div>
              <div className="center-button mt-4">
                <img class="w-32 " src={creative} />
              </div>

              <p class="text-xl font-bold text-white pt-12 text-center">
                Creative Ideas
              </p>
            </div>
          </div>
     </section>

     <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20 bg-newshade42">
        <div
          style={{
            backgroundImage: `url("https://psdtowpwork.com/wp/graptor/main/wp-content/uploads/2021/11/4-1.png")`,
            width: "100%",
            height: "1125px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="  container mx-auto figtree pt-16 ">
            <div className="rounded-lg bg-newshade33 w-8/12 ml-32 pb-12">
              <p class="text-4xl font-bold text-white ml-8 pt-16 ">
                Contact Us
              </p>
              <div className="flex mt-8 ">
                <div className="h-16 w-16 rounded-md border-9 ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={location} />
                  </div>
                </div>
                <div>
                  <p class="text-base1 font-bold text-white ml-6">
                    Our Address
                  </p>
                  <p class="text-base text-grey pt-2 ml-6 font-semibold">
                    PSD Building, 2 AlBahr St, Loskia
                  </p>
                  <p class="text-base text-grey ml-6 font-semibold">
                    sripur, jamukara.
                  </p>
                </div>
              </div>

              <div className="flex mt-6 ">
                <div className="h-16 w-16 rounded-md border-9 ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={phone} />
                  </div>
                </div>
                <div>
                  <p class="text-base1 font-bold text-white ml-6">
                    {" "}
                    Our Phone
                  </p>
                  <p class="text-base text-grey pt-2 ml-6 font-semibold">
                    Telephone : 0029129102320
                  </p>
                  <p class="text-base text-grey ml-6 font-semibold">
                    Mobile : 000 2324 39493
                  </p>
                </div>
              </div>

              <div className="flex mt-6 ">
                <div className="h-16 w-16 rounded-md border-9 ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={email} />
                  </div>
                </div>
                <div>
                  <p class="text-base1 font-bold text-white ml-6">
                    Our Email
                  </p>
                  <p class="text-base text-grey pt-2 ml-6 font-semibold">
                    Main Email : name@website.com
                  </p>
                  <p class="text-base text-grey ml-6 font-semibold">
                    Inquiries : Info@orex.com
                  </p>
                </div>
              </div>
            </div>

            <div className="ml-6 mr-8 mt-12">
              <p className="text-4xl font-bold text-white">
                Request A Quote
              </p>
              <p className="text-base text-white pt-2">
                We will be happy to answer your questions.
              </p>
              <div>
                <form>
                  <div className="flex mt-6">
                    <input
                      class="border-8 rounded-md w-6/12 p-4  text-base"
                      type="text"
                      id="text"
                      placeholder="Full Name"
                    />
                    <input
                      class="border-8  rounded-md w-6/12 p-4 text-base ml-2"
                      type="text"
                      id="text"
                      placeholder="Your Email"
                    />
                  </div>
                  <input
                    class="border-8  rounded-md w-12/12 p-4 text-base mt-6"
                    type="text"
                    id="text"
                    placeholder="Subject"
                  />
                  <textarea
                    class=" border-8 mt-6 text-base w-12/12 rounded-md"
                    id="w3review"
                    name="w3review"
                    placeholder="Message"
                    rows="6"
                    cols="0"
                  ></textarea>
                  <div className="flex mt-4">
                    <input
                      class=""
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                    />
                    <p class="text-base -mt-1 ml-2 text-white">
                      Also subscribe us.
                    </p>
                    <button class="  bg-newshade33 text-center rounded-md py-2 px-12 text-white text-base ml-100">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-12 container mx-auto figtree">
        <div className="flex ">
          <div>
            <p class="text-4xl font-bold text-darkblack ml-6">
              Looking for the Best<br></br> IT Business Solutions?
            </p>
            <p class="text-base1 pt-2 ml-6">
              As a app web business expert, We will help to organize.
            </p>
          </div>
          <div>
            <button class="bg-newshade33 text-white px-8 py-3 rounded-md ml-8 mt-4 xl:text-base font-bold red-on-hover4">
              Get A Quote ➔
            </button>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block hidden sm:hidden mt-20  figtree bg-newshade40 pb-20">
        <div className=" container mx-auto">
          <div className="">
            <p class="text-base font-bold text-white pt-16 text-center">
              IMPROVED HOSTING
            </p>
            <p class="text-4xl font-bold text-white pt-4 text-center">
              Faster, better hosting is right here
            </p>
            <div className="center-button">
              <img
                class="w-10/12 mt-8 "
                src="https://hoka.peerduck.com/wp-content/uploads/2022/06/lrkgm-1024x788.png"
                alt=""
              />
            </div>
          </div>

          <div className="mr-8 ml-8 mt-8">
            <p class="text-base1 text-grey pt-16 text-center">
              We offer a wide range of hosting solutions,<br></br> from web hosting to dedicated servers.
            </p>
            <p class="text-base1 text-grey text-center">
               Everything you need is right here.
            </p>

            <div className="flex mt-12 rounded-lg bg-newshade43 p-8">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/gkmb.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-2xl font-bold text-white ml-8">
                  Next level
                </p>
                <p class="text-base text-grey pt-2 ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>

            <div className="flex mt-6 rounded-lg p-8" id="box1">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/tgmt.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-2xl font-bold text-white ml-8">Security</p>
                <p class="text-base text-grey pt-2 ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>

            <div className="flex mt-6 rounded-lg p-8" id="box1">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/lrgk.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-2xl font-bold text-white ml-8">
                  Reliable hosting
                </p>
                <p class="text-base text-grey pt-2 ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-newshade33 mt-12 container mx-auto rounded-lg w-10/12 p-4 pb-8">
          <div className="grid grid-cols-2 figtree ">
            <div className="ml-4">
              <p class="text-2xl text-white font-bold pt-4">
                Sign up now and<br></br> get 30-day
              </p>
              <p class="text-2xl text-white font-bold  ">
                money-back guarantee
              </p>
              <p class="text-base font-bold text-white pt-4">
                We are happy to assist you.
              </p>
            </div>

            <div>
              <div>
                <img
                  class="w-10/12  mt-4 rounded-md ml-8"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/kgmtk.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 figtree container mx-auto mt-20">
          <div className="ml-8">
            <p class="text-base text-white font-bold ">SUPPORT</p>
            <p class="text-3xl text-white font-bold pt-4">
              Dedicated support team <br></br>available 24/7
            </p>
            <p class="text-base pt-4 text-grey">
              Using a chat, ticket or phone, you can reach brilliant minds
               with years of experience. We offer a wide range of
              hosting solutions, from web hosting to dedicated servers.
              Everything you need is right here.
            </p>
            <p class="text-base font-bold text-white pt-4 blue-on-hover">
              <a href="/homepage1">Learn More ➔</a>
            </p>
          </div>

          <div>
            <img
              class="w-10/12 ml-4"
              src="https://hoka.peerduck.com/wp-content/uploads/2022/06/kjef-1024x831.png"
              alt=""
            />
          </div>
        </div>

        <div className="mt-16">
          <p class="text-base text-white font-bold text-center">
            KNOWLEDGE BASE
          </p>
          <p class="text-4xl text-white font-bold text-center pt-4">
            Still have questions?
          </p>
          <p class="text-base1 text-grey pt-4 text-center">
            We offer a wide range of hosting solutions, from web hosting
            <br></br> to dedicated servers. Everything you need is right here.
          </p>
        </div>


          
          <div className=" container mx-auto mt-16  ">
          
          
          <div className="flex justify-between ml-16 mr-20  ">
      <p className="text-2xl text-white font-semibold ">What is web hosting?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen5(!isOpen5)}
      >
        ﹀
      </p>
      </div>
      {isOpen5 && (
        <p className="text-base text-grey pt-2 ml-16 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

            
            <div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold">How do I create a website?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        ﹀
      </p>
      </div>
      {isOpen && (
        <p className="text-base text-grey pt-2 ml-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
     
    

    <div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">How can I transfer my website?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen1(!isOpen1)}
      >
        ﹀
      </p>
      </div>
      {isOpen1 && (
        <p className="text-base text-grey pt-2 ml-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
         


           
           <div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">How to set up a WooCommerce site?</p>
      <p
        className="xl:text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen2(!isOpen2)}
      >
        ﹀
      </p>
      </div>
      {isOpen2 && (
        <p className="text-base text-grey pt-2 ml-16 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

<div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">When will I be billed for renewal?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen3(!isOpen3)}
      >
        ﹀
      </p>
      </div>
      {isOpen3 && (
        <p className="text-base text-grey pt-2 ml-16 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

<div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">What is a nameserver and DNS?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen4(!isOpen4)}
      >
        ﹀
      </p>
      </div>
      {isOpen4 && (
        <p className="text-base text-grey pt-2 ml-16 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          <br />
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
           
       </div>
      </section>



      {/*MOBILE VIEW*/}
       <section className="xl:hidden lg:hidden md:hidden sm:block block  bg-newshade41">
          <p class="text-base text-blue text-center font-bold pt-12">
          What We Do
        </p>
        <p class="text-4xl text-darkblack font-bold text-center pt-2">
          Services for You
        </p>

        <div>
            <div className="border w-10/12 rounded-lg  hover:border-blue-500 ml-8 mt-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={projectreporting} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Project Reporting
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

                    <div>
            <div className="border w-10/12 rounded-lg  hover:border-blue-500 ml-8 mt-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={businessanalysis} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Business Analysis
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

                 <div>
            <div className="border  w-10/12 rounded-lg  hover:border-blue-500 ml-8 mt-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img
                      class="w-20 pt-4"
                      src={softwaredevelopment}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Software Development
              </p>
              <p class="text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>


          <div>
            <div className="border rounded-lg  hover:border-blue-500 w-10/12 ml-8 mt-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={webdevelopment} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Web Development
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

           <div>
            <div className="border rounded-lg  hover:border-blue-500 w-10/12 ml-8 mt-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={seoexpert} alt="" />
                  </div>
                </div>
              </div>
              <p class="text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                SEO Optimized Expert
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border rounded-lg  hover:border-blue-500 w-10/12 ml-8 mt-8">
              <div className="center-button">
                <div className="oval  bg-newshade16 mt-8 ">
                  <div className="center-button">
                    <img class="w-20 pt-4" src={peoplecohension} alt="" />
                  </div>
                </div>
              </div>
              <p class="xl:text-xl text-center text-darkblack font bold pt-6 blue-on-hover">
                Analytic Solutions
              </p>
              <p class=" text-sm text-center pt-4">
                At vero eos et accusamus etiusto odio
              </p>
              <p class="text-sm text-center ">
                praesentium accusamus etiusto odio
              </p>
              <p class="text-sm text-center">data center database.</p>

              <div className="center-button pb-8">
                <div className="w-16 bg-newshade16 rounded-full mt-6 p-2 red-on-hover5">
                  <p class="text-xl font-bold  text-center">➔</p>
                </div>
              </div>
            </div>
          </div>
   </section>

     <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20">
     <p class="text-base text-blue font-bold text-center">
          Our Work Process
        </p>
        <p class="text-4xl font-bold pt-4 text-center text-darkblack">
          We Complete Every<br></br> Step Carefully
        </p>

        <div>
            <div className="rounded-lg bg-newshade39 pb-8 red-on-hover5 w-10/12 ml-8 mt-12">
              <div>
                <p class="text-9xl font-bold ml-8 pt-8">01</p>
              </div>
              <div className="center-button mt-4">
                <img class="w-32 " src={datacollection} />
              </div>

              <p class="text-xl font-bold text-white pt-12 text-center">
                Collecting Info Data
              </p>
            </div>
          </div>


       <div>
            <div className="rounded-lg bg-newshade39 pb-8 red-on-hover5 w-10/12 ml-8 mt-8 ">
              <div>
                <p class="text-9xl font-bold ml-8 pt-8">02</p>
              </div>
              <div className="center-button mt-4">
                <img class="w-32 " src={worksetup} />
              </div>

              <p class="text-xl font-bold text-white pt-12 text-center">
                Setup Work Target
              </p>
            </div>
          </div>
      
       
       <div className="w-10/12 ml-8 mt-8">
            <div className="rounded-lg bg-newshade39 pb-8 red-on-hover5  mt-12">
              <div>
                <p class="text-9xl font-bold ml-8 pt-8">03</p>
              </div>
              <div className="center-button mt-4">
                <img class="w-32 " src={creative} />
              </div>

              <p class="text-xl font-bold text-white pt-12 text-center">
                Creative Ideas
              </p>
            </div>
          </div>
     </section>

     <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 bg-newshade42 pb-4">
        <div
          style={{
            backgroundImage: `url("https://psdtowpwork.com/wp/graptor/main/wp-content/uploads/2021/11/4-1.png")`,
            width: "100%",
            height: "1200px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="  container mx-auto figtree pt-16 ">
            <div className="rounded-lg bg-newshade33 w-10/12 ml-8 pb-12 ">
              <p class="text-4xl font-bold text-white ml-8 pt-16 ">
                Contact Us
              </p>
              <div className="flex mt-8 ">
                <div className="h-16 w-16 rounded-md border-9 ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={location} />
                  </div>
                </div>
                <div>
                  <p class="text-base1 font-bold text-white ml-6">
                    Our Address
                  </p>
                  <p class="text-base text-grey pt-2 ml-6 font-semibold">
                    PSD Building, 2 AlBahr<br></br> St, Loskia 
                  </p>
                  <p class="text-base text-grey ml-6 font-semibold">
                    sripur, jamukara.
                  </p>
                </div>
              </div>

              <div className="flex mt-6 ">
                <div className="h-16 w-16 rounded-md border-9 ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={phone} />
                  </div>
                </div>
                <div>
                  <p class="text-base1 font-bold text-white ml-6">
                    {" "}
                    Our Phone
                  </p>
                  <p class="text-base text-grey pt-2 ml-6 font-semibold">
                    Telephone : 0029129102320
                  </p>
                  <p class="text-base text-grey ml-6 font-semibold">
                    Mobile : 000 2324 39493
                  </p>
                </div>
              </div>

              <div className="flex mt-6 ">
                <div className="h-16 w-16 rounded-md border-9 ml-8 ">
                  <div className="center-button">
                    <img class="w-8 pt-3" src={email} />
                  </div>
                </div>
                <div>
                  <p class="text-base1 font-bold text-white ml-6">
                    Our Email
                  </p>
                  <p class="text-base text-grey pt-2 ml-6 font-semibold">
                    Main Email : name@website.com
                  </p>
                  <p class="text-base text-grey ml-6 font-semibold">
                    Inquiries : Info@orex.com
                  </p>
                </div>
              </div>
            </div>

            <div className="ml-6 mr-8 mt-12">
              <p className="text-4xl font-bold text-white">
                Request A Quote
              </p>
              <p className="text-base text-white pt-2">
                We will be happy to answer your questions.
              </p>
              <div>
                <form>
                  <div className="flex mt-6">
                    <input
                      class="border-8 rounded-md w-6/12 p-4  text-base"
                      type="text"
                      id="text"
                      placeholder="Full Name"
                    />
                    <input
                      class="border-8  rounded-md w-6/12 p-4 text-base ml-2"
                      type="text"
                      id="text"
                      placeholder="Your Email"
                    />
                  </div>
                  <input
                    class="border-8  rounded-md w-12/12 p-4 text-base mt-6"
                    type="text"
                    id="text"
                    placeholder="Subject"
                  />
                  <textarea
                    class=" border-8 mt-6 text-base w-12/12 rounded-md"
                    id="w3review"
                    name="w3review"
                    placeholder="Message"
                    rows="6"
                    cols="0"
                  ></textarea>
                  <div className="flex mt-4">
                    <input
                      class=""
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                    />
                    <p class="text-base -mt-1 ml-2 text-white">
                      Also subscribe us.
                    </p>
                    <button class="  bg-newshade33 text-center rounded-md py-2 px-4 text-white text-base ml-20">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-12 container mx-auto figtree">
        <div className=" ">
          <div>
            <p class="text-4xl font-bold text-darkblack ml-6">
              Looking for the Best<br></br> IT Business<br></br> Solutions?
            </p>
            <p class="text-base pt-2 ml-6">
              As a app web business expert, We will help to organize.
            </p>
          </div>
          <div>
            <button class="bg-newshade33 text-white px-12 py-3 rounded-md ml-6 mt-6 xl:text-base font-bold red-on-hover4">
              Get A Quote ➔
            </button>
          </div>
        </div>
      </section>


      <section className="xl:hidden lg:hidden md:hidden block sm:block mt-20  figtree bg-newshade40 pb-20">
        <div className=" container mx-auto">
          <div className="">
            <p class="text-base font-bold text-white pt-16 text-center">
              IMPROVED HOSTING
            </p>
            <p class="text-4xl font-bold text-white pt-4 text-center">
              Faster, better hosting is right here
            </p>
            <div className="center-button">
              <img
                class="w-10/12 mt-8 "
                src="https://hoka.peerduck.com/wp-content/uploads/2022/06/lrkgm-1024x788.png"
                alt=""
              />
            </div>
          </div>

          <div className="mr-8 ml-8 mt-8">
            <p class="text-base1 text-grey pt-16 text-center">
              We offer a wide range of hosting solutions,<br></br> from web hosting to dedicated servers.
            </p>
            <p class="text-base1 text-grey text-center">
               Everything you need is right here.
            </p>

            <div className="flex mt-12 rounded-lg bg-newshade43 p-8">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/gkmb.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-2xl font-bold text-white ml-8">
                  Next level
                </p>
                <p class="text-base text-grey pt-2 ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>

            <div className="flex mt-6 rounded-lg p-8" id="box1">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/tgmt.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-2xl font-bold text-white ml-8">Security</p>
                <p class="text-base text-grey pt-2 ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>

            <div className="flex mt-6 rounded-lg p-8" id="box1">
              <div>
                <img
                  class="w-12 pt-4"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/lrgk.png"
                  alt=""
                />
              </div>
              <div>
                <p class="text-2xl font-bold text-white ml-8">
                  Reliable hosting
                </p>
                <p class="text-base text-grey pt-2 ml-8">
                  Nunc varius massa vel urna suscipit, eget mollis tellus
                  dapibus.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-newshade33 mt-12 container mx-auto rounded-lg w-10/12 p-4 pb-8">
          <div className=" figtree ">
            <div className="">
              <p class="text-2xl text-white font-bold pt-4 text-center">
                Sign up now and<br></br> get 30-day
              </p>
              <p class="text-2xl text-white font-bold  text-center">
                money-back guarantee
              </p>
              <p class="text-base font-bold text-white pt-4 text-center">
                We are happy to assist you.
              </p>
            </div>

            <div>
              <div>
                <img
                  class="w-10/12  mt-4 rounded-md ml-8"
                  src="https://hoka.peerduck.com/wp-content/uploads/2022/06/kgmtk.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className=" figtree container mx-auto mt-20">
          <div className="">
            <p class="text-base text-white font-bold text-center">SUPPORT</p>
            <p class="text-3xl text-white font-bold pt-4 text-center">
              Dedicated support team <br></br>available 24/7
            </p>
            <p class="text-base pt-4 text-grey text-center px-8">
              Using a chat, ticket or phone, you can reach brilliant minds
               with years of experience. We offer a wide range of
              hosting solutions, from web hosting to dedicated servers.
              Everything you need is right here.
            </p>
            <p class="text-base font-bold text-white pt-4 blue-on-hover text-center">
              <a href="/homepage1">Learn More ➔</a>
            </p>
          </div>

          <div className="center-button mt-8">
            <img
              class="w-8/12 "
              src="https://hoka.peerduck.com/wp-content/uploads/2022/06/kjef-1024x831.png"
              alt=""
            />
          </div>
        </div>

        <div className="mt-16">
          <p class="text-base text-white font-bold text-center">
            KNOWLEDGE BASE
          </p>
          <p class="text-4xl text-white font-bold text-center pt-4">
            Still have questions?
          </p>
          <p class="text-base1 text-grey pt-4 text-center px-8">
            We offer a wide range of hosting solutions, from web hosting
             to dedicated servers. Everything you need is right here.
          </p>
        </div>


          
          <div className=" container mx-auto mt-16  ">
          
          
          <div className="flex justify-between ml-16 mr-20  ">
      <p className="text-2xl text-white font-semibold ">What is web hosting?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen5(!isOpen5)}
      >
        ﹀
      </p>
      </div>
      {isOpen5 && (
        <p className="text-base text-grey pt-2 ml-8 px-8">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

            
            <div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold">How do I create a website?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        ﹀
      </p>
      </div>
      {isOpen && (
        <p className="text-base text-grey pt-2 ml-8 px-8">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
        
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
     
    

    <div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">How can I transfer my website?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen1(!isOpen1)}
      >
        ﹀
      </p>
      </div>
      {isOpen1 && (
        <p className="text-base text-grey pt-2 ml-8 px-8">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
         


           
           <div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">How to set up a WooCommerce site?</p>
      <p
        className="xl:text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen2(!isOpen2)}
      >
        ﹀
      </p>
      </div>
      {isOpen2 && (
        <p className="text-base text-grey pt-2 ml-8 px-8 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

<div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">When will I be billed for renewal?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen3(!isOpen3)}
      >
        ﹀
      </p>
      </div>
      {isOpen3 && (
        <p className="text-base text-grey pt-2 ml-8 px-8 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}

<div className="flex justify-between mt-8 ml-16 mr-20">
      <p className="text-2xl text-white font-semibold ">What is a nameserver and DNS?</p>
      <p
        className="text-lg text-white font-bold pt-2"
        onClick={() => setIsOpen4(!isOpen4)}
      >
        ﹀
      </p>
      </div>
      {isOpen4 && (
        <p className="text-base text-grey pt-2 ml-8 px-8 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
      )}
           
       </div>
      </section>

      {/*END*/}

      <Footer2 />
    </>
  );
}
