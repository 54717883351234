import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";

//about us
import Aboutt from "about/aboutpage/Aboutt.js";

// contact us
import Contactus from "about/aboutpage/Contactus";
import Applications from "components/Dropdowns/ApplicationsContent";
import Whymarslab from "components/Dropdowns/Whymarslab";
import Form from "components/Dropdowns/Form";
import Leadership from "components/Dropdowns/Leadership";
import Cloud1 from "components/Cloud/Cloud1";
import Cloudslider from "views/Cloudslider";
import Service from "components/Homepage/Service";
import CloudService from "components/Homepage/CloudService";
import PrivateCloudService from "components/Homepage/PrivateCloudService";
// import PublicCloudService from "components/Homepage/PublicCloudService";
import ModernOperations from "components/Homepage/ModernOperations";
import CloudMigration from "components/Homepage/CloudMigration";
import Cyber from "components/Service/Cyber.js";
import Cloud from "components/Service/Cloud.js";
import Data from "components/Service/Data.js";
import Ai from "components/Service/Ai.js";
import Infrastructure from "components/Service/Infrastructure.js";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Homepage2 from "components/Homepage/Homepage2"
import Footer2 from "components/Footers/Footer2";
import Services2 from "components/Homepage/Services2";
import About3 from "about/aboutpage/About3";
import Employer from "components/Homepage/Employer";
import PrivacyPolicy from "components/Homepage/PrivacyPolicy";
import TermsofUse from "components/Homepage/TermsofUse";
import CancellationPolicy from "components/Homepage/CancellationPolicy";
import ActiveSlider from "components/ActiveSlider";

// import { Form } from "formik";



ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      {/* <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} /> */}
      {/* <Route path="/aboutt" component={Aboutt} /> */}
      {/* add routes without layouts */}
      {/* <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} /> */}

      {/* Homepage */}

      {/* Aboutus page */}
      <Route path="/about-us" exact component={Aboutt} />
      <Route path="/form" exact component={Form}/>
      <Route path="/whymarslab" exact component={Whymarslab} />
      <Route path="/index" exact component={Index} />
      <Route path="/team" exact component={Leadership} />
      <Route path="/cloud" exact component={Cloud1} />
      <Route path="/cloudslider" exact component={Cloudslider} />
      <Route path="/Footer2" exact component={Footer2}/>

      {/* services */}
      <Route path="/service" exact component={Service} />
      <Route path="/cloudservice" exact component={CloudService} />
      <Route path="/privatecloudservice" exact component={PrivateCloudService} />
      {/* <Route path="/publiccloudservice" exact component={PublicCloudService} /> */}
      <Route path="/modernoperations" exact component={ModernOperations} />
      <Route path="/cloudmigration" exact component={CloudMigration} />

      {/* Contact us */}
      <Route path="/contact" exact component={Contactus} />
      <Route path="/" exact component={Index} />

      {/* Inside dropdown content  */}
      <Route path="/applications" exact component={Applications} />

      <Route path="/cyber-security" exact component={Cyber} />
      <Route path="/cloud-engineering" exact component={Cloud} />
      <Route path="/data-engineering" exact component={Data} />
      <Route path="/ai-engineering" exact component={Ai} />
      <Route path="/infrastructure-engineering" exact component={Infrastructure} />
      <Route path="/indexvavbar2" exact component={Indexnavbar2}/>
      <Route path="/Homepage2" exact component={Homepage2}/>
       <Route path="/Services2" exact component={Services2}/> 
      {/*<Route path="/recrutment-service" exact component={Services2}/>*/}
      <Route path="/About3" exact component={About3}/>
      <Route path="/Employer" exact component={Employer}/>
      <Route path="/PrivacyPolicy" exact component={PrivacyPolicy}/>
      <Route path="/terms-of-use" exact component={TermsofUse}/>
      <Route path="/cancellation-policy" exact component={CancellationPolicy}/>
      <Route path="/ActiveSlider" exact component={ActiveSlider}/>
      


      {/* add redirect for first page */}
      <Redirect from="/Homepage1" to="/" />
      
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
