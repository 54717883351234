import React, { useState } from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import blue from "assets/img/blue.jpeg";
import paper from "assets/img/paper.png";
import mail from "assets/img/mail.png";
import location from "assets/img/location.png";
import strategicpartners from "assets/img/strategicpartners.png";
import peoplecohension from "assets/img/peoplecohension.png";
import welfare from "assets/img/welfare.png";
import resources from "assets/img/resources.png";
import strategic1 from "assets/img/strategic1.png";
import resource1 from "assets/img/resource1.png";
import calender1 from "assets/img/calender1.png";
import event1 from "assets/img/event1.png";
import message1 from "assets/img/message1.png";
import quote from "assets/img/quote.png";
import search1 from "assets/img/search1.png";

export default function Employer() {
  return (
    <>
      <Indexnavbar2 fixed />

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden figtree bg-newshade29">
        <div
          style={{
            backgroundImage: `url("https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/counter_bg.png")`,
            width: "100%",
            height: "350px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className=" grid grid-cols-2 container mx-auto xl:pt-44">
            <div>
              <div className="flex  xl:ml-4">
                <a href="/homepage1">
                  <p class="xl:text-base text-grey font-bold ">Home</p>
                </a>
                <p class="xl:text-base  text-grey font-bold xl:ml-2">.</p>
                <p class="xl:text-base  text-grey font-bold xl:ml-2">
                  Our Blogs
                </p>
                <p class="xl:text-base  text-grey font-bold xl:ml-2">.</p>
                <p class="xl:text-base  text-grey font-bold xl:ml-2">
                  Data Science
                </p>
                <p class="xl:text-base  text-grey font-bold xl:ml-2">.</p>
                <p class="xl:text-base  text-darkblue font-bold xl:ml-2">
                  Take Your Career Next Leveley Future Approch
                </p>
              </div>
              <p class="xl:text-4xl text-darkblue font-bold xl:pt-8 xl:ml-6">
                Blog Details
              </p>
            </div>

            <div>
              <img
                class="xl:w-4/12 xl:ml-96"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/img/banner-shape.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-24 container mx-auto">
        <div className="flex xl:pb-24">
          <div>
            <div>
              <img
                class="xl:w-10/12 rounded-lg"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-1048x540.jpg"
                alt=""
              />
            </div>
            <p class="xl:text-5xl font-bold xl:pt-6 xl:ml-4 text-darkblack">
              Take Your Career Next Leveley Future <br></br>Approch
            </p>
            <div className="flex xl:mt-6 ">
              <div className="">
                <div className="center-button">
                  <img
                    class="w-12 rounded-full xl:ml-4"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/1/66204038516b2fe2f828703bc5e0423a.jpeg"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <p class="xl:text-sm xl:ml-1 xl:pt-4 text-black blue-on-hover">
                  -By Admin
                </p>
              </div>

              <div>
                <img class="w-6 xl:ml-4 pt-3" src={calender1} />
              </div>
              <div>
                <p class="xl:text-sm xl:ml-1 xl:pt-4 text-black blue-on-hover">
                  June 21, 2023
                </p>
              </div>

              <div>
                <img class="w-6 xl:ml-4 pt-3" src={event1} />
              </div>
              <div>
                <p class="xl:text-sm xl:ml-1 xl:pt-4 text-black blue-on-hover">
                  Data Science
                </p>
              </div>

              <div>
                <img class="w-6 xl:ml-4 pt-3" src={message1} />
              </div>
              <div>
                <p class="xl:text-sm xl:ml-1 xl:pt-4 text-black blue-on-hover">
                  0 Comments
                </p>
              </div>
            </div>

            <div className="border-3 xl:mt-6  xl:ml-2 xl:mr-36"></div>

            <p class="xl:mt-4 xl:ml-4 xl:text-base">
              Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada
              Tortor Non Turpis Semper Bibendum. Ut Ac Nisi Porta,<br></br>{" "}
              Malesuada Risus Nonrra Dolo Areay Vestibulum Ante Ipsum Primis In
              Faucibus Orci Luctus Et Ultrices Posuere Cubilia Curae In<br></br>{" "}
              Tristique Libero, Quis Ultrices Diamraesent Varius Diam Dui. Class
              Aptent Taciti Sociosqu Ad Litora Torquent Per Conubia<br></br>{" "}
              Nostra.Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed
              Malesuada Tortor Non Turpis Semper Bibendum. Ut Ac Nisi Porta,
              <br></br> Malesuada Risus NonVestibulum Ante Ipsum Primis
            </p>

            <div className="bg-newshade29 rounded-md xl:mt-8 xl:ml-2 xl:mr-36 border-s-slate-950">
              <p class="xl:text-base1 xl:pt-12  text-center">
                “ Maximus ligula eleifend id nisl quis interdum. Sed malesuada
                tortor non turpis dear<br></br> bibendum. Ut ac nisi porta,
                malesuada risus nonVestibulum ante ipsum primis in<br></br>{" "}
                faucibus orci luctus et ultrices posuere cubilia curae in
                tristique libero ”
              </p>
              <div className="grid grid-cols-2 xl:pb-4">
                <div>
                  <p class="xl:text-base text-grey font-bold xl:ml-16 xl:mt-4 ">
                    David Miller
                  </p>
                </div>
                <div>
                  <img class="w-12 xl:mt-8 xl:ml-72" src={quote} />
                </div>
              </div>
            </div>

            <p class="xl:mt-8 xl:ml-4 xl:text-base">
              Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada
              Tortor Non Turpis Semper Bibendum. Ut Ac Nisi Porta,<br></br>{" "}
              Malesuada Risus Nonrra Dolo Areay Vestibulum Ante Ipsum Primis In
              Faucibus Orci Luctus Et Ultrices Posuere Cubilia Curae In<br></br>{" "}
              Tristique Libero, Quis Ultrices Diamraesent Varius Diam Dui. Class
              Aptent Taciti Sociosqu Ad Litora Torquent Per Conubia<br></br>{" "}
              Nostra.Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed
              Malesuada Tortor Non Turpis Semper Bibendum. Ut Ac Nisi Porta,
              <br></br> Malesuada Risus NonVestibulum Ante Ipsum Primis
            </p>

            <p class="xl:text-3xl font-bold text-darkblack xl:mt-6 xl:ml-4">
              What Will I Learn From This Event?
            </p>
            <p class="xl:text-base xl:mt-6 xl:ml-4">
              Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada
              Tortor Non Turpis Semper Bibendum. Ut Ac Nisi Porta,<br></br>{" "}
              Malesuada Risus Non Viverra Dolor. Vestibulum Ante Ipsum Primis In
              Faucibus Orci Luctus Et Ultrices Posuere.
            </p>

            <div className="flex xl:mt-6 xl:ml-8">
              <div>
                <ul class="a">
                  <li>Become a UX designer.</li>
                </ul>
              </div>

              <div className="xl:ml-52">
                <ul class="a ">
                  <li>Build & test a full website design.</li>
                </ul>
              </div>
            </div>

            <div className="flex xl:mt-2 xl:ml-8">
              <div>
                <ul class="a">
                  <li>You will be able to add UX designe</li>
                </ul>
              </div>

              <div className="xl:ml-31">
                <ul class="a ">
                  <li>Create your first UX brief & persona.</li>
                </ul>
              </div>
            </div>

            <div className="flex xl:mt-2 xl:ml-8">
              <div>
                <ul class="a">
                  <li>Become a UI designer.</li>
                </ul>
              </div>

              <div className="xl:ml-53">
                <ul class="a ">
                  <li>How to use premade UI kits.</li>
                </ul>
              </div>
            </div>

            <p class="xl:mt-8 xl:ml-4 xl:text-base">
              Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada
              Tortor Non Turpis Semper Bibendum. Ut Ac Nisi Porta,<br></br>{" "}
              Malesuada Risus Nonrra Dolo Areay Vestibulum Ante Ipsum Primis In
              Faucibus Orci Luctus Et Ultrices Posuere Cubilia Curae In<br></br>{" "}
              Tristique Libero, Quis Ultrices Diamraesent Varius Diam Dui. Class
              Aptent Taciti Sociosqu Ad Litora Torquent Per Conubia<br></br>{" "}
              Nostra.Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed
              Malesuada Tortor Non Turpis Semper Bibendum. Ut Ac Nisi Porta,
              <br></br> Malesuada Risus NonVestibulum Ante Ipsum Primis
            </p>

            <div className="border-3 xl:mt-6  xl:ml-2 xl:mr-36"></div>
            <div className="flex xl:mt-4">
              <div>
                <p class="xl:text-base1 text-darkblack font-semibold xl:ml-4">
                  Tags:
                </p>
              </div>
              <div className="rounded-full bg-newshade29 xl:ml-3 p-2 ">
                <p class="xl:text-sm text-grey-600">Course</p>
              </div>

              <div className="rounded-full bg-newshade29 xl:ml-3 p-2 ">
                <p class="xl:text-sm text-grey-600">Education</p>
              </div>

              <div className="rounded-full bg-newshade29 xl:ml-3 p-2 ">
                <p class="xl:text-sm text-grey-600 ">Training</p>
              </div>

              <div>
                <p class="xl:text-base1 text-darkblack font-semibold xl:ml-88">
                  Share:
                </p>
              </div>

              <div>
                <a href="homepage1/">
                  <button className=" xl:ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-facebook"></i>
                  </button>
                </a>
              </div>

              <div>
                <a href="homepage1/">
                  <button className=" xl:ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-linkedin"></i>
                  </button>
                </a>
              </div>

              <div>
                <a href="homepage1/">
                  <button className=" xl:ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-instagram"></i>
                  </button>
                </a>
              </div>

              <div>
                <a href="homepage1/">
                  <button className=" xl:ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-youtube"></i>
                  </button>
                </a>
              </div>

              <div className="border-3 xl:mt-6  xl:ml-2 xl:mr-36"></div>
            </div>

            <div className="rounded-md bg-newshade29 xl:mt-8 xl:mr-36 p-8">
              <div className="flex">
                <div>
                  <img
                    class="w-52 rounded-full xl:ml-6 xl:mt-6"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/1/66204038516b2fe2f828703bc5e0423a.jpeg"
                    alt=""
                  />
                </div>
                <div>
                  <p class="xl:text-base1 font-bold text-darkblack xl:ml-8 xl:pt-8">
                    Admin
                  </p>
                  <p class="xl:text-base text-blue xl:pt-1 xl:ml-8">
                    Java Specialist
                  </p>
                  <p class="xl:text-base  xl:pt-1 xl:ml-8">
                    Maximus ligula eleifend id nisl quis interdum. Sed malesuada
                    tortor non turpis semper ac nisi porta, malesuada risus awra
                    nondolor. Vestibulum ante ipsum primis in faucibus orci
                    luctus et curae in tristique libero.Maximus ligula eleifend
                    id nisl quis interdum. Sed malesuada tortor no turpis semper
                    bibendumt ac nisi porta, malesuada risus awra nondolor.
                    Vestibulum ante ipsum primis in faucibus orci luctus et
                    ultrices posuere cubilia curae in tristique libero.
                  </p>
                  <div className="flex xl:mt-6">
                    <a href="homepage1/">
                      <button className=" xl:ml-8 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                        <i className="fab fa-facebook"></i>
                      </button>
                    </a>

                    <a href="homepage1/">
                      <button className=" xl:ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                        <i className="fab fa-linkedin"></i>
                      </button>
                    </a>

                    <a href="homepage1/">
                      <button className=" xl:ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                        <i className="fab fa-youtube"></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 xl:mt-8">
              <div className="border-4 rounded-md xl:w-10/12">
                <div className="flex xl:ml-6 xl:mt-4">
                  <a href="/">
                    <p class="xl:text-xl text-darkblack">← </p>
                  </a>
                  <p class="xl:text-base text-grey xl:ml-1 blue-on-hover">
                    Previous Post
                  </p>
                </div>
                <p class="xl:text-base text-darkblack font-semibold xl:ml-6 blue-on-hover xl:pt-2">
                  Take Your Career Next Leveley Future Approch
                </p>
              </div>

              <div className="border-4 rounded-md xl:w-10/12 xl:-ml-16">
                <div className="flex xl:ml-6 xl:mt-4">
                  <a href="/">
                    <p class="xl:text-base text-grey blue-on-hover xl:ml-80">
                      Next Post{" "}
                    </p>
                  </a>
                  <p class="xl:text-xl text-darkblack xl:ml-1 ">→</p>
                </div>
                <p class="xl:text-base text-darkblack font-semibold xl:ml-24 blue-on-hover xl:pt-2">
                  Take Your Career Next Leveley Future Approch
                </p>
              </div>
            </div>

            <div className="flex xl:mt-8 ">
              <div>
                <p class="xl:text-2xl font-bold xl:ml-4 text-darkblack">
                  You May Also Like
                </p>
              </div>

              <div className="flex">
                <div className="rounded-md h-8 w-8 bg-newshade29 xl:ml-140 red-on-hover4">
                  <a href="/">
                    <p class="xl:text-xl pt-1 ml-1">←</p>
                  </a>
                </div>
                <div className="rounded-md h-8 w-8 bg-newshade29 xl:ml-4 ">
                  <a href="/">
                    <p class="xl:text-xl pt-1 ml-1">→</p>
                  </a>
                </div>
              </div>
            </div>

            <div className="rounded-md bg-newshade29 xl:mr-36 xl:mt-8 xl:pb-12">
              <p class="xl:text-2xl font-bold xl:pt-12 xl:ml-6 text-darkblack">
                Write a Review
              </p>
              <p class="xl:text-base xl:pt-4 xl:ml-6">
                Your email address will not be published. Required fields are
                marked *
              </p>
              <div>
                <form>
                  <textarea
                    class=" border-4 xl:mt-6 xl:text-base xl:w-10/12 rounded-md xl:ml-6"
                    id="w3review"
                    name="w3review"
                    placeholder="Comment*"
                    rows="8"
                    cols="0"
                  ></textarea>
                </form>
              </div>
              <div className="flex xl:ml-6">
                <input
                  class="border-4 bg-white rounded-md xl:w-5/12 p-4  xl:text-base"
                  type="text"
                  id="text"
                  placeholder="Full Name"
                />
                <input
                  class="border-4 bg-white rounded-md xl:w-5/12 p-4  xl:text-base xl:ml-4"
                  type="text"
                  id="text"
                  placeholder="Your Email"
                />
              </div>

              <div className="flex xl:mt-6">
                <input
                  class="xl:ml-6"
                  type="checkbox"
                  id="checkbox"
                  name="checkbox"
                />
                <p class="xl:text-base -mt-2 xl:ml-2">
                  Save my name, email, and website in this browser for the next
                  time I comment.
                </p>
              </div>

              <button class="xl:ml-6 xl:px-8 xl:py-2 bg-newshade33 text-white rounded-md xl:mt-6">
                Post Comment
              </button>
            </div>
          </div>

          {/*2nd section of div*/}
          <div className="xl:-ml-28  ">
            <div className="border-3 xl:w-full rounded-md  figtree p-6">
              <div className="xl:pt-12 ">
                <div className="rounded-md bg-newshade29 xl:w-8/12 xl:ml-12 ">
                  <div className="flex">
                    <div>
                      <p class="text-grey xl:text-base ml-2">SearchHere..</p>
                    </div>
                    <div className="h-8 w-8 rounded-md bg-newshade33 xl:ml-16">
                      <div>
                        <img class="w-6 pt-1 ml-1" src={search1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-4 xl:mt-10 xl:ml-6 xl:mr-8"></div>

              <p className="xl:text-base1 font-bold text-darkblack xl:mt-6 xl:ml-6">
                Categories
              </p>

              <div className="flex xl:mt-6  xl:ml-6">
                <p class="xl:text-xl blue-on-hover">› </p>
                <p class="xl:text-base blue-on-hover ml-2">Art & Design</p>
                <p class="xl:text-base xl:ml-32">(1)</p>
              </div>
              <div className="border-3 xl:mt-6 xl:ml-6 xl:mr-8"></div>

              <div className="flex xl:mt-4  xl:ml-6">
                <p class="xl:text-xl blue-on-hover">› </p>
                <p class="xl:text-base blue-on-hover ml-2">Business</p>
                <p class="xl:text-base xl:ml-38">(3)</p>
              </div>
              <div className="border-3 xl:mt-6 xl:ml-6 xl:mr-8"></div>

              <div className="flex xl:mt-4  xl:ml-6">
                <p class="xl:text-xl blue-on-hover">› </p>
                <p class="xl:text-base blue-on-hover ml-2">Data Science</p>
                <p class="xl:text-base xl:ml-30">(3)</p>
              </div>
              <div className="border-3 xl:mt-6 xl:ml-6 xl:mr-8"></div>

              <div className="flex xl:mt-4  xl:ml-6">
                <p class="xl:text-xl blue-on-hover">› </p>
                <p class="xl:text-base blue-on-hover ml-2">Development</p>
                <p class="xl:text-base xl:ml-30">(2)</p>
              </div>
              <div className="border-3 xl:mt-6 xl:ml-6 xl:mr-8"></div>

              <div className="flex xl:mt-4  xl:ml-6">
                <p class="xl:text-xl blue-on-hover">› </p>
                <p class="xl:text-base blue-on-hover ml-2">Finance</p>
                <p class="xl:text-base xl:ml-40">(1)</p>
              </div>
              <div className="border-3 xl:mt-6 xl:ml-6 xl:mr-8"></div>

              <div className="flex xl:mt-4  xl:ml-6">
                <p class="xl:text-xl blue-on-hover">› </p>
                <p class="xl:text-base blue-on-hover ml-2">Health & Fitness</p>
                <p class="xl:text-base xl:ml-24">(3)</p>
              </div>
              <div className="border-3 xl:mt-6 xl:ml-6 xl:mr-8"></div>

              <p className="text-base1 font-bold text-darkblack mt-10 ml-6">
                Recent Post
              </p>

              <div className="flex xl:mt-10 xl:ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_03-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex xl:mt-2 xl:ml-2">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="xl:text-xs ml-2">August 10, 2023</p>
                  </div>
                  <p class="xl:text-sm hover-on-blue ml-2 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="xl:text-sm hover-on-blue ml-2 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex xl:mt-8 xl:ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_04-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex xl:mt-2 xl:ml-2">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="xl:text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="xl:text-sm hover-on-blue ml-2 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="xl:text-sm hover-on-blue ml-2 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex xl:mt-8 xl:ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_06-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex xl:mt-2 xl:ml-2">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="xl:text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="xl:text-sm hover-on-blue ml-2 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="xl:text-sm hover-on-blue ml-2 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex xl:mt-8 xl:ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex xl:mt-2 xl:ml-2">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="xl:text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="xl:text-sm hover-on-blue ml-2 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="xl:text-sm hover-on-blue ml-2 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="border-3 xl:mt-6 xl:ml-6 xl:mr-8"></div>

              <p className="xl:text-base1 font-bold text-darkblack xl:mt-10 xl:ml-6">
                Tags:
              </p>

              <div className="flex xl:ml-6 xl:mt-6">
                <div className="bg-newshade29 xl:px-4 xl:py-1 rounded-md red-on-hover4">
                  <p class="xl:text-base  ">Course</p>
                </div>

                <div className="bg-newshade29 xl:px-4 xl:py-1 rounded-md red-on-hover4 xl:ml-1">
                  <p class="xl:text-base  ">Education</p>
                </div>

                <div className="bg-newshade29 xl:px-4 xl:py-1 rounded-md red-on-hover4 xl:ml-1">
                  <p class="xl:text-base  ">Learn</p>
                </div>
              </div>

              <div className="flex xl:ml-6 xl:mt-4">
                <div className="bg-newshade29 xl:px-4 xl:py-1 rounded-md red-on-hover4">
                  <p class="xl:text-base  ">LMS</p>
                </div>

                <div className="bg-newshade29 xl:px-4 xl:py-1 rounded-md red-on-hover4 xl:ml-1">
                  <p class="xl:text-base ">Online</p>
                </div>

                <div className="bg-newshade29 xl:px-4 xl:py-1 rounded-md red-on-hover4 xl:ml-1">
                  <p class="xl:text-base ">Training</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*LANDSCAPE VIEW*/}

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden figtree bg-newshade29">
        <div className=" grid grid-cols-2 container mx-auto pt-16">
          <div>
            <div className="flex  ">
              <a href="/homepage1">
                <p class="text-base text-grey font-bold ml-12">Home</p>
              </a>
              <p class="text-base  text-grey font-bold ml-1">.</p>
              <p class="text-base  text-grey font-bold ml-1">Our Blogs</p>
              <p class="text-base  text-grey font-bold ml-1">.</p>
              <p class="text-base  text-grey font-bold ml-1">Data Science</p>
            </div>
            <p class="text-4xl text-darkblue font-bold pt-4 ml-12">
              Blog Details
            </p>
          </div>

          <div>
            <img
              class="w-4/12 ml-64 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/img/banner-shape.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 pb-12">
        <div className="ml-8 mr-8 rounded-lg">
          <img
            class=""
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-1048x540.jpg"
            alt=""
          />
        </div>
        <p class="text-5xl font-bold pt-6 ml-8 text-darkblack">
          Take Your Career Next Leveley Future <br></br>Approch
        </p>

        <div className="flex mt-6 ">
          <div className="">
            <div className="center-button">
              <img
                class="w-12 rounded-full ml-8"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/1/66204038516b2fe2f828703bc5e0423a.jpeg"
                alt=""
              />
            </div>
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">-By Admin</p>
          </div>

          <div>
            <img class="w-6 ml-8 pt-3" src={calender1} />
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">
              June 21, 2023
            </p>
          </div>

          <div>
            <img class="w-6 ml-8 pt-3" src={event1} />
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">
              Data Science
            </p>
          </div>

          <div>
            <img class="w-6 ml-8 pt-3" src={message1} />
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">0 Comments</p>
          </div>
        </div>

        <div className="border-3 mt-6  ml-8 mr-8"></div>

        <p class="mt-4 ml-8 xl:text-base">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta,<br></br> Malesuada Risus
          Nonrra Dolo Areay Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus
          Et Ultrices Posuere Cubilia Curae In<br></br> Tristique Libero, Quis
          Ultrices Diamraesent Varius Diam Dui. Class Aptent Taciti Sociosqu Ad
          Litora Torquent Per Conubia<br></br> Nostra.Maximus Ligula Eleifend Id
          Nisl Quis Interdum. Sed Malesuada Tortor Non Turpis Semper Bibendum.
          Ut Ac Nisi Porta,
          <br></br> Malesuada Risus NonVestibulum Ante Ipsum Primis
        </p>

        <div className="bg-newshade29 rounded-md mt-8 ml-8 mr-8 border-s-slate-950">
          <p class="text-base1 pt-12  text-center">
            “ Maximus ligula eleifend id nisl quis interdum. Sed malesuada
            tortor non turpis dear<br></br> bibendum. Ut ac nisi porta,
            malesuada risus nonVestibulum ante ipsum primis in<br></br> faucibus
            orci luctus et ultrices posuere cubilia curae in tristique libero ”
          </p>
          <div className="grid grid-cols-2 pb-4">
            <div>
              <p class="text-base text-grey font-bold ml-16 mt-8 ">
                David Miller
              </p>
            </div>
            <div>
              <img class="w-12 mt-8 ml-90" src={quote} />
            </div>
          </div>
        </div>

        <p class="mt-8 ml-8 xl:text-base">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta,<br></br> Malesuada Risus
          Nonrra Dolo Areay Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus
          Et Ultrices Posuere Cubilia Curae In<br></br> Tristique Libero, Quis
          Ultrices Diamraesent Varius Diam Dui. Class Aptent Taciti Sociosqu Ad
          Litora Torquent Per Conubia<br></br> Nostra.Maximus Ligula Eleifend Id
          Nisl Quis Interdum. Sed Malesuada Tortor Non Turpis Semper Bibendum.
          Ut Ac Nisi Porta,
          <br></br> Malesuada Risus NonVestibulum Ante Ipsum Primis
        </p>

        <p class="text-3xl font-bold text-darkblack mt-6 ml-8">
          What Will I Learn From This Event?
        </p>
        <p class="text-base mt-6 ml-8">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta,<br></br> Malesuada Risus
          Non Viverra Dolor. Vestibulum Ante Ipsum Primis In Faucibus Orci
          Luctus Et Ultrices Posuere.
        </p>

        <div className="flex mt-6 ml-16">
          <div>
            <ul class="a">
              <li>Become a UX designer.</li>
            </ul>
          </div>

          <div className="ml-52">
            <ul class="a ">
              <li>Build & test a full website design.</li>
            </ul>
          </div>
        </div>

        <div className="flex mt-2 ml-16">
          <div>
            <ul class="a">
              <li>You will be able to add UX designe</li>
            </ul>
          </div>

          <div className="ml-32">
            <ul class="a ">
              <li>Create your first UX brief & persona.</li>
            </ul>
          </div>
        </div>

        <div className="flex mt-2 ml-16">
          <div>
            <ul class="a">
              <li>Become a UI designer.</li>
            </ul>
          </div>

          <div className="ml-54">
            <ul class="a ">
              <li>How to use premade UI kits.</li>
            </ul>
          </div>
        </div>

        <p class="mt-8 ml-8 xl:text-base">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta,<br></br> Malesuada Risus
          Nonrra Dolo Areay Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus
          Et Ultrices Posuere Cubilia Curae In<br></br> Tristique Libero, Quis
          Ultrices Diamraesent Varius Diam Dui. Class Aptent Taciti Sociosqu Ad
          Litora Torquent Per Conubia<br></br> Nostra.Maximus Ligula Eleifend Id
          Nisl Quis Interdum. Sed Malesuada Tortor Non Turpis Semper Bibendum.
          Ut Ac Nisi Porta,
          <br></br> Malesuada Risus NonVestibulum Ante Ipsum Primis
        </p>

        <div className="border-3 mt-6  ml-8 mr-8"></div>
        <div className="flex mt-6">
          <div>
            <p class="text-base1 text-darkblack font-semibold ml-8 pt-1">
              Tags:
            </p>
          </div>
          <div className="rounded-full bg-newshade29 ml-3 p-2 ">
            <p class="text-sm text-grey-600">Course</p>
          </div>

          <div className="rounded-full bg-newshade29 ml-3 p-2 ">
            <p class="text-sm text-grey-600">Education</p>
          </div>

          <div className="rounded-full bg-newshade29 ml-3 p-2 ">
            <p class="text-sm text-grey-600 ">Training</p>
          </div>

          <div>
            <p class="text-base1 text-darkblack font-semibold ml-104 pt-1">
              Share:
            </p>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-facebook"></i>
              </button>
            </a>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-linkedin"></i>
              </button>
            </a>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-instagram"></i>
              </button>
            </a>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-youtube"></i>
              </button>
            </a>
          </div>

          <div className="border-3 mt-6  ml-8 mr-8"></div>
        </div>

        <div className="rounded-md bg-newshade29 mt-8 mr-8 p-8 ml-8">
          <div className="flex">
            <div>
              <img
                class="w-52 rounded-full ml-8 mt-6"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/1/66204038516b2fe2f828703bc5e0423a.jpeg"
                alt=""
              />
            </div>
            <div>
              <p class="text-base1 font-bold text-darkblack ml-12 pt-8">
                Admin
              </p>
              <p class="text-base text-blue pt-1 ml-12">Java Specialist</p>
              <p class="text-base  pt-1 ml-12">
                Maximus ligula eleifend id nisl quis interdum. Sed malesuada
                tortor non turpis semper ac nisi porta, malesuada risus awra
                nondolor. Vestibulum ante ipsum primis in faucibus orci luctus
                et curae in tristique libero.Maximus ligula eleifend id nisl
                quis interdum. Sed malesuada tortor no turpis semper bibendumt
                ac nisi porta, malesuada risus awra nondolor. Vestibulum ante
                ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                curae in tristique libero.
              </p>
              <div className="flex mt-6">
                <a href="homepage1/">
                  <button className=" ml-8 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-facebook"></i>
                  </button>
                </a>

                <a href="homepage1/">
                  <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-linkedin"></i>
                  </button>
                </a>

                <a href="homepage1/">
                  <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-youtube"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-8">
          <div className="border-4 rounded-md w-10/12 ml-8">
            <div className="flex ml-8 mt-4">
              <a href="/">
                <p class="text-xl text-darkblack -mt-1">← </p>
              </a>
              <p class="text-base text-grey ml-1 blue-on-hover">
                Previous Post
              </p>
            </div>
            <p class="text-base text-darkblack font-semibold ml-6 blue-on-hover pt-2">
              Take Your Career Next Leveley Future Approch
            </p>
          </div>

          <div className="border-4 rounded-md w-10/12 ml-8">
            <div className="flex ml-6 mt-4">
              <a href="/">
                <p class="text-base text-grey blue-on-hover ml-70">
                  Next Post{" "}
                </p>
              </a>
              <p class="text-xl text-darkblack ml-1 -mt-1">→</p>
            </div>
            <p class="text-base text-darkblack font-semibold ml-16 blue-on-hover pt-2">
              Take Your Career Next Leveley Future Approch
            </p>
          </div>
        </div>

        <div className="flex mt-8 ">
          <div>
            <p class="text-2xl font-bold ml-10 text-darkblack">
              You May Also Like
            </p>
          </div>

          <div className="flex">
            <div className="rounded-md h-8 w-8 bg-newshade29 ml-160 red-on-hover4">
              <a href="/">
                <p class="text-xl pt-1 ml-1">←</p>
              </a>
            </div>
            <div className="rounded-md h-8 w-8 bg-newshade29 ml-4 ">
              <a href="/">
                <p class="text-xl pt-1 ml-1">→</p>
              </a>
            </div>
          </div>
        </div>

        <div className="rounded-md bg-newshade29 mr-8 ml-8 mt-8 pb-12">
          <p class="text-2xl font-bold pt-12 ml-8 text-darkblack">
            Write a Review
          </p>
          <p class="text-base pt-4 ml-8">
            Your email address will not be published. Required fields are marked
            *
          </p>
          <div>
            <form>
              <textarea
                class=" border-4 mt-6 text-base w-10/12 rounded-md ml-8"
                id="w3review"
                name="w3review"
                placeholder="Comment*"
                rows="8"
                cols="0"
              ></textarea>
            </form>
          </div>
          <div className="flex ml-8">
            <input
              class="border-4 bg-white rounded-md w-5/12 p-4  xl:text-base"
              type="text"
              id="text"
              placeholder="Full Name"
            />
            <input
              class="border-4 bg-white rounded-md w-5/12 p-4 text-base ml-4"
              type="text"
              id="text"
              placeholder="Your Email"
            />
          </div>

          <div className="flex mt-6">
            <input class="ml-8" type="checkbox" id="checkbox" name="checkbox" />
            <p class="text-base -mt-2 ml-2">
              Save my name, email, and website in this browser for the next time
              I comment.
            </p>
          </div>

          <button class="ml-8 px-8 py-2 bg-newshade33 text-white rounded-md mt-6">
            Post Comment
          </button>
        </div>

        {/*2nd section*/}

        <div className="mt-12">
          <div className="ml-8 mr-8">
            <div className="border-3 w-full rounded-md  figtree p-6">
              <div className="pt-12 ">
                <div className="rounded-md bg-newshade29 w-12/12  p-2">
                  <div className="flex justify-between">
                    <div>
                      <p class="text-grey xl:text-base ml-2">SearchHere..</p>
                    </div>
                    <div className="h-8 w-8 rounded-md bg-newshade33 ">
                      <div>
                        <img class="w-6 pt-1 ml-1" src={search1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-4 mt-10 ml-2 mr-4"></div>

              <p className="text-base1 font-bold text-darkblack mt-6 ml-2">
                Categories
              </p>

              <div className="flex mt-6 justify-between mr-4">
                <p class="text-base blue-on-hover "> › Art & Design</p>
                <p class="text-base ">(1)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Business</p>
                <p class="text-base ">(3)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Data Science</p>
                <p class="text-base ">(3)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Development</p>
                <p class="text-base ">(2)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Finance</p>
                <p class="text-base">(1)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Health & Fitness</p>
                <p class="text-base ">(3)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <p className="text-base1 font-bold text-darkblack mt-10 ml-6">
                Recent Post
              </p>

              <div className="flex mt-10 ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_03-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-8">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">August 10, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex mt-8 ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_04-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-8">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex mt-8 ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_06-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-8">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex mt-8 ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-8">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="border-3 mt-6 ml-6 mr-8"></div>

              <div className="flex ml-6 mt-6">
                <div>
                  <p className="text-base1 font-bold text-darkblack ">Tags:</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4  ml-8">
                  <p class="text-base ">Course</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base  ">Education</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base  ">Learn</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4">
                  <p class="text-base  ">LMS</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base ">Online</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base ">Training</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*TAB VIEW*/}

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden figtree bg-newshade29 pb-20">
        <div className=" grid grid-cols-2 container mx-auto pt-16">
          <div>
            <div className="flex  ">
              <a href="/homepage1">
                <p class="text-base text-grey font-bold ml-12">Home</p>
              </a>
              <p class="text-base  text-grey font-bold ml-1">.</p>
              <p class="text-base  text-grey font-bold ml-1">Our Blogs</p>
              <p class="text-base  text-grey font-bold ml-1">.</p>
              <p class="text-base  text-grey font-bold ml-1">Data Science</p>
            </div>
            <p class="text-4xl text-darkblue font-bold pt-4 ml-12">
              Blog Details
            </p>
          </div>

          <div>
            <img
              class="w-32 ml-52 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/img/banner-shape.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20 pb-8">
        <div className="ml-8 mr-8 rounded-lg">
          <img
            class=""
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-1048x540.jpg"
            alt=""
          />
        </div>
        <p class="text-5xl font-bold pt-6 ml-8 text-darkblack">
          Take Your Career Next Leveley Future Approch
        </p>

        <div className="flex mt-6 ">
          <div className="">
            <div className="center-button">
              <img
                class="w-12 rounded-full ml-8"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/1/66204038516b2fe2f828703bc5e0423a.jpeg"
                alt=""
              />
            </div>
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">-By Admin</p>
          </div>

          <div>
            <img class="w-6 ml-8 pt-3" src={calender1} />
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">
              June 21, 2023
            </p>
          </div>

          <div>
            <img class="w-6 ml-8 pt-3" src={event1} />
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">
              Data Science
            </p>
          </div>

          <div>
            <img class="w-6 ml-8 pt-3" src={message1} />
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">0 Comments</p>
          </div>
        </div>

        <div className="border-3 mt-6  ml-8 mr-8"></div>

        <p class="mt-4 ml-2 text-base px-8">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta, Malesuada Risus Nonrra
          Dolo Areay Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus Et
          Ultrices Posuere Cubilia Curae In Tristique Libero, Quis Ultrices
          Diamraesent Varius Diam Dui. Class Aptent Taciti Sociosqu Ad Litora
          Torquent Per Conubia Nostra.Maximus Ligula Eleifend Id Nisl Quis
          Interdum. Sed Malesuada Tortor Non Turpis Semper Bibendum. Ut Ac Nisi
          Porta, Malesuada Risus NonVestibulum Ante Ipsum Primis
        </p>

        <div className="bg-newshade29 rounded-md mt-8 ml-8 mr-8 border-s-slate-950">
          <p class="text-base1 pt-12  text-center">
            “ Maximus ligula eleifend id nisl quis interdum. Sed malesuada
            tortor non turpis dear<br></br> bibendum. Ut ac nisi porta,
            malesuada risus nonVestibulum ante ipsum primis in<br></br> faucibus
            orci luctus et ultrices posuere cubilia curae in tristique libero ”
          </p>
          <div className="grid grid-cols-2 pb-4">
            <div>
              <p class="text-base text-grey font-bold ml-16 mt-8 ">
                David Miller
              </p>
            </div>
            <div>
              <img class="w-12 mt-8 ml-32" src={quote} />
            </div>
          </div>
        </div>

        <p class="mt-8 ml-2 px-8 text-base">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta, Malesuada Risus Nonrra
          Dolo Areay Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus Et
          Ultrices Posuere Cubilia Curae In Tristique Libero, Quis Ultrices
          Diamraesent Varius Diam Dui. Class Aptent Taciti Sociosqu Ad Litora
          Torquent Per Conubi Nostra.Maximus Ligula Eleifend Id Nisl Quis
          Interdum. Sed Malesuada Tortor Non Turpis Semper Bibendum. Ut Ac Nisi
          Porta, Malesuada Risus NonVestibulum Ante Ipsum Primis
        </p>

        <p class="text-3xl font-bold text-darkblack mt-6 ml-8">
          What Will I Learn From This Event?
        </p>
        <p class="text-base mt-6 ml-2 px-8">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta, Malesuada Risus Non
          Viverra Dolor. Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus Et
          Ultrices Posuere.
        </p>

        <div className="flex mt-6 ml-16">
          <div>
            <ul class="a">
              <li>Become a UX designer.</li>
            </ul>
          </div>

          <div className="ml-52">
            <ul class="a ">
              <li>Build & test a full website design.</li>
            </ul>
          </div>
        </div>

        <div className="flex mt-2 ml-16">
          <div>
            <ul class="a">
              <li>You will be able to add UX designe</li>
            </ul>
          </div>

          <div className="ml-32">
            <ul class="a ">
              <li>Create your first UX brief & persona.</li>
            </ul>
          </div>
        </div>

        <div className="flex mt-2 ml-16">
          <div>
            <ul class="a">
              <li>Become a UI designer.</li>
            </ul>
          </div>

          <div className="ml-54">
            <ul class="a ">
              <li>How to use premade UI kits.</li>
            </ul>
          </div>
        </div>

        <p class="mt-8 ml-2 px-8 text-base">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta, Malesuada Risus Nonrra
          Dolo Areay Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus Et
          Ultrices Posuere Cubilia Curae In Tristique Libero, Quis Ultrices
          Diamraesent Varius Diam Dui. Class Aptent Taciti Sociosqu Ad Litora
          Torquent Per Conubia Nostra.Maximus Ligula Eleifend Id Nisl Quis
          Interdum. Sed Malesuada Tortor Non Turpis Semper Bibendum. Ut Ac Nisi
          Porta, Malesuada Risus NonVestibulum Ante Ipsum Primis
        </p>

        <div className="border-3 mt-6  ml-8 mr-8"></div>
        <div className="flex mt-6">
          <div>
            <p class="text-base1 text-darkblack font-semibold ml-8 pt-1">
              Tags:
            </p>
          </div>
          <div className="rounded-full bg-newshade29 ml-3 p-2 ">
            <p class="text-sm text-grey-600">Course</p>
          </div>

          <div className="rounded-full bg-newshade29 ml-3 p-2 ">
            <p class="text-sm text-grey-600">Education</p>
          </div>

          <div className="rounded-full bg-newshade29 ml-3 p-2 ">
            <p class="text-sm text-grey-600 ">Training</p>
          </div>

          <div>
            <p class="text-base1 text-darkblack font-semibold pt-1 ml-48">
              Share:
            </p>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-facebook"></i>
              </button>
            </a>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-linkedin"></i>
              </button>
            </a>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-instagram"></i>
              </button>
            </a>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-youtube"></i>
              </button>
            </a>
          </div>

          <div className="border-3 mt-6  ml-8 mr-8"></div>
        </div>

        <div className="rounded-md bg-newshade29 mt-8 mr-8 p-8 ml-8">
          <div className="flex">
            <div>
              <img
                class="w-52 rounded-full ml-8 mt-6"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/1/66204038516b2fe2f828703bc5e0423a.jpeg"
                alt=""
              />
            </div>
            <div>
              <p class="text-base1 font-bold text-darkblack ml-12 pt-8">
                Admin
              </p>
              <p class="text-base text-blue pt-1 ml-12">Java Specialist</p>
              <p class="text-base  pt-1 ml-12">
                Maximus ligula eleifend id nisl quis interdum. Sed malesuada
                tortor non turpis semper ac nisi porta, malesuada risus awra
                nondolor. Vestibulum ante ipsum primis in faucibus orci luctus
                et curae in tristique libero.Maximus ligula eleifend id nisl
                quis interdum. Sed malesuada tortor no turpis semper bibendumt
                ac nisi porta, malesuada risus awra nondolor. Vestibulum ante
                ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                curae in tristique libero.
              </p>
              <div className="flex mt-6">
                <a href="homepage1/">
                  <button className=" ml-8 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-facebook"></i>
                  </button>
                </a>

                <a href="homepage1/">
                  <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-linkedin"></i>
                  </button>
                </a>

                <a href="homepage1/">
                  <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-youtube"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-8">
          <div className="border-4 rounded-md w-10/12 ml-8">
            <div className="flex ml-8 mt-4">
              <a href="/">
                <p class="text-xl text-darkblack -mt-1">← </p>
              </a>
              <p class="text-base text-grey ml-1 blue-on-hover">
                Previous Post
              </p>
            </div>
            <p class="text-base text-darkblack font-semibold ml-6 blue-on-hover pt-2">
              Take Your Career Next Leveley Future Approch
            </p>
          </div>

          <div className="border-4 rounded-md w-10/12 ml-4">
            <div className="flex ml-6 mt-4">
              <a href="/">
                <p class="text-base text-grey blue-on-hover ml-44">
                  Next Post{" "}
                </p>
              </a>
              <p class="text-xl text-darkblack ml-1 -mt-1">→</p>
            </div>
            <p class="text-base text-darkblack font-semibold ml-6 blue-on-hover pt-2">
              Take Your Career Next Leveley Future Approch
            </p>
          </div>
        </div>

        <div className="flex mt-8 ">
          <div>
            <p class="text-2xl font-bold ml-10 text-darkblack">
              You May Also Like
            </p>
          </div>

          <div className="flex ml-96">
            <div className="rounded-md h-8 w-8 bg-newshade29 red-on-hover4">
              <a href="/">
                <p class="text-xl pt-1 ml-1">←</p>
              </a>
            </div>
            <div className="rounded-md h-8 w-8 bg-newshade29 ml-4 ">
              <a href="/">
                <p class="text-xl pt-1 ml-1">→</p>
              </a>
            </div>
          </div>
        </div>

        <div className="rounded-md bg-newshade29 mr-8 ml-8 mt-8 pb-12">
          <p class="text-2xl font-bold pt-12 ml-8 text-darkblack">
            Write a Review
          </p>
          <p class="text-base pt-4 ml-8">
            Your email address will not be published. Required fields are marked
            *
          </p>
          <div>
            <form>
              <textarea
                class=" border-4 mt-6 text-base w-10/12 rounded-md ml-8"
                id="w3review"
                name="w3review"
                placeholder="Comment*"
                rows="8"
                cols="0"
              ></textarea>
            </form>
          </div>
          <div className="flex ml-8">
            <input
              class="border-4 bg-white rounded-md w-5/12 p-4  xl:text-base"
              type="text"
              id="text"
              placeholder="Full Name"
            />
            <input
              class="border-4 bg-white rounded-md w-5/12 p-4 text-base ml-4"
              type="text"
              id="text"
              placeholder="Your Email"
            />
          </div>

          <div className="flex mt-6">
            <input class="ml-8" type="checkbox" id="checkbox" name="checkbox" />
            <p class="text-base -mt-2 ml-2">
              Save my name, email, and website in this browser for the next time
              I comment.
            </p>
          </div>

          <button class="ml-8 px-8 py-2 bg-newshade33 text-white rounded-md mt-6">
            Post Comment
          </button>
        </div>

        {/*2nd section*/}

        <div className="mt-12 ">
          <div className="ml-8 mr-8">
            <div className="border-3 w-full rounded-md  figtree p-6 ">
              <div className="pt-12 ">
                <div className="rounded-md bg-newshade29 w-12/12  p-2">
                  <div className="flex justify-between">
                    <div>
                      <p class="text-grey xl:text-base ml-2">SearchHere..</p>
                    </div>
                    <div className="h-8 w-8 rounded-md bg-newshade33 ">
                      <div>
                        <img class="w-6 pt-1 ml-1" src={search1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-4 mt-10 ml-2 mr-4"></div>

              <p className="text-base1 font-bold text-darkblack mt-6 ml-2">
                Categories
              </p>

              <div className="flex mt-6 justify-between mr-4">
                <p class="text-base blue-on-hover "> › Art & Design</p>
                <p class="text-base ">(1)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Business</p>
                <p class="text-base ">(3)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Data Science</p>
                <p class="text-base ">(3)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Development</p>
                <p class="text-base ">(2)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Finance</p>
                <p class="text-base">(1)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Health & Fitness</p>
                <p class="text-base ">(3)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <p className="xl:text-base1 font-bold text-darkblack xl:mt-10 xl:ml-6">
                Recent Post
              </p>

              <div className="flex mt-10 ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_03-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-8">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">August 10, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex mt-8 ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_04-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-8">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex mt-8 ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_06-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-8">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex mt-8 ml-6">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-8">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-8 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="border-3 mt-6 ml-6 mr-8"></div>

              <div className="flex ml-6 mt-6">
                <div>
                  <p className="text-base1 font-bold text-darkblack ">Tags:</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4  ml-8">
                  <p class="text-base ">Course</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base  ">Education</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base  ">Learn</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4">
                  <p class="text-base  ">LMS</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base ">Online</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base ">Training</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*MOBILE VIEW*/}
      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade29 pb-12">
        <div>
          <div>
            <div className="flex pt-12  ">
              <a href="/homepage1">
                <p class="text-base text-grey font-bold ml-12">Home</p>
              </a>
              <p class="text-base  text-grey font-bold ml-1">.</p>
              <p class="text-base  text-grey font-bold ml-1">Our Blogs</p>
              <p class="text-base  text-grey font-bold ml-1">.</p>
              <p class="text-base  text-grey font-bold ml-1">Data Science</p>
            </div>
            <p class="text-4xl text-darkblue font-bold pt-4 ml-12">
              Blog Details
            </p>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 pb-12">
        <div className="ml-4 mr-4 rounded-lg">
          <img
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-1048x540.jpg"
            alt=""
          />
        </div>

        <p class="text-4xl font-bold pt-6 ml-4 text-darkblack">
          Take Your Career Next Leveley Future Approch
        </p>

        <div className="flex mt-6 ">
          <div className="">
            <div className="center-button">
              <img
                class="w-12 rounded-full ml-4"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/1/66204038516b2fe2f828703bc5e0423a.jpeg"
                alt=""
              />
            </div>
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">-By Admin</p>
          </div>

          <div>
            <img class="w-6 ml-8 pt-3" src={calender1} />
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">
              June 21, 2023
            </p>
          </div>
        </div>
        <div className="flex mt-4 ml-6">
          <div>
            <img class="w-6  pt-3" src={event1} />
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">
              Data Science
            </p>
          </div>

          <div>
            <img class="w-6 ml-8 pt-3" src={message1} />
          </div>
          <div>
            <p class="text-sm ml-1 pt-4 text-black blue-on-hover">0 Comments</p>
          </div>
        </div>

        <div className="border-3 mt-6  ml-4 mr-4"></div>

        <p class="mt-4 ml-2 text-base px-8">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta, Malesuada Risus Nonrra
          Dolo Areay Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus Et
          Ultrices Posuere Cubilia Curae In Tristique Libero, Quis Ultrices
          Diamraesent Varius Diam Dui. Class Aptent Taciti Sociosqu Ad Litora
          Torquent Per Conubia Nostra.Maximus Ligula Eleifend Id Nisl Quis
          Interdum. Sed Malesuada Tortor Non Turpis Semper Bibendum. Ut Ac Nisi
          Porta, Malesuada Risus NonVestibulum Ante Ipsum Primis
        </p>

        <div className="bg-newshade29 rounded-md mt-8 ml-8 mr-8 border-s-slate-950">
          <p class="text-base1 pt-12  text-center px-4">
            “ Maximus ligula eleifend id nisl quis interdum. Sed malesuada
            tortor non turpis dear bibendum. Ut ac nisi porta, malesuada risus
            nonVestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae in tristique libero ”
          </p>
          <div className="grid grid-cols-2 pb-4">
            <div>
              <p class="text-base text-grey font-bold ml-6 mt-8 ">
                David Miller
              </p>
            </div>
            <div>
              <img class="w-12 mt-8 ml-20" src={quote} />
            </div>
          </div>
        </div>

        <p class="mt-8 ml-2 px-8 text-base">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta, Malesuada Risus Nonrra
          Dolo Areay Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus Et
          Ultrices Posuere Cubilia Curae In Tristique Libero, Quis Ultrices
          Diamraesent Varius Diam Dui. Class Aptent Taciti Sociosqu Ad Litora
          Torquent Per Conubi Nostra.Maximus Ligula Eleifend Id Nisl Quis
          Interdum. Sed Malesuada Tortor Non Turpis Semper Bibendum. Ut Ac Nisi
          Porta, Malesuada Risus NonVestibulum Ante Ipsum Primis
        </p>

        <p class="text-3xl font-bold text-darkblack mt-6 ml-8">
          What Will I Learn From This Event?
        </p>
        <p class="text-base mt-6 ml-2 px-8">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta, Malesuada Risus Non
          Viverra Dolor. Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus Et
          Ultrices Posuere.
        </p>

        <div className=" mt-6 ml-16">
          <div>
            <ul class="a">
              <li>Become a UX designer.</li>
            </ul>
          </div>

          <div className=" mt-2">
            <ul class="a ">
              <li>Build & test a full website design.</li>
            </ul>
          </div>
        </div>

        <div className=" ml-16 mt-2">
          <div>
            <ul class="a">
              <li>You will be able to add UX designe</li>
            </ul>
          </div>

          <div className="mt-2">
            <ul class="a ">
              <li>Create your first UX brief & persona.</li>
            </ul>
          </div>
        </div>

        <div className="ml-16 mt-2">
          <div>
            <ul class="a">
              <li>Become a UI designer.</li>
            </ul>
          </div>

          <div className=" mt-2">
            <ul class="a ">
              <li>How to use premade UI kits.</li>
            </ul>
          </div>
        </div>

        <p class="mt-8 ml-2 px-8 text-base">
          Maximus Ligula Eleifend Id Nisl Quis Interdum. Sed Malesuada Tortor
          Non Turpis Semper Bibendum. Ut Ac Nisi Porta, Malesuada Risus Nonrra
          Dolo Areay Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus Et
          Ultrices Posuere Cubilia Curae In Tristique Libero, Quis Ultrices
          Diamraesent Varius Diam Dui. Class Aptent Taciti Sociosqu Ad Litora
          Torquent Per Conubia Nostra.Maximus Ligula Eleifend Id Nisl Quis
          Interdum. Sed Malesuada Tortor Non Turpis Semper Bibendum. Ut Ac Nisi
          Porta, Malesuada Risus NonVestibulum Ante Ipsum Primis
        </p>

        <div className="border-3 mt-6  ml-8 mr-8"></div>
        <div className="flex mt-6">
          <div>
            <p class="text-base1 text-darkblack font-semibold ml-8 pt-1">
              Tags:
            </p>
          </div>
          <div className="rounded-full bg-newshade29 ml-3 p-2 ">
            <p class="text-sm text-grey-600">Course</p>
          </div>

          <div className="rounded-full bg-newshade29 ml-6 p-2 ">
            <p class="text-sm text-grey-600">Education</p>
          </div>

          <div className="rounded-full bg-newshade29 ml-6 p-2 ">
            <p class="text-sm text-grey-600 ">Training</p>
          </div>
        </div>

        <div className="flex mt-4">
          <div>
            <p class="text-base1 text-darkblack font-semibold pt-1 ml-16">
              Share:
            </p>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-facebook"></i>
              </button>
            </a>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-linkedin"></i>
              </button>
            </a>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-instagram"></i>
              </button>
            </a>
          </div>

          <div>
            <a href="homepage1/">
              <button className=" ml-4 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                <i className="fab fa-youtube"></i>
              </button>
            </a>
          </div>
        </div>
        <div className="border-3 mt-6  ml-8 mr-8"></div>

        <div className="rounded-md bg-newshade29 mt-8 mr-8 p-8 ml-8">
          <div className="">
            <div>
              <img
                class="w-24 rounded-full ml-4 mt-6"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/1/66204038516b2fe2f828703bc5e0423a.jpeg"
                alt=""
              />
            </div>
            <div>
              <p class="text-base1 font-bold text-darkblack ml-2 pt-8">Admin</p>
              <p class="text-base text-blue pt-1 ml-2">Java Specialist</p>
              <p class="text-base  pt-1 ml-2">
                Maximus ligula eleifend id nisl quis interdum. Sed malesuada
                tortor non turpis semper ac nisi porta, malesuada risus awra
                nondolor. Vestibulum ante ipsum primis in faucibus orci luctus
                et curae in tristique libero.Maximus ligula eleifend id nisl
                quis interdum. Sed malesuada tortor no turpis semper bibendumt
                ac nisi porta, malesuada risus awra nondolor. Vestibulum ante
                ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                curae in tristique libero.
              </p>
              <div className="flex mt-6">
                <a href="homepage1/">
                  <button className=" ml-2 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-facebook"></i>
                  </button>
                </a>

                <a href="homepage1/">
                  <button className=" ml-2 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-linkedin"></i>
                  </button>
                </a>

                <a href="homepage1/">
                  <button className=" ml-2 bg-black text-white shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full ">
                    <i className="fab fa-youtube"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className=" mt-8">
          <div className="border-4 rounded-md w-10/12 ml-8">
            <div className="flex ml-8 mt-4">
              <a href="/">
                <p class="text-xl text-darkblack -mt-1">← </p>
              </a>
              <p class="text-base text-grey ml-1 blue-on-hover">
                Previous Post
              </p>
            </div>
            <p class="text-base text-darkblack font-semibold ml-6 blue-on-hover pt-2">
              Take Your Career Next Leveley Future Approch
            </p>
          </div>

          <div className="border-4 rounded-md w-10/12 ml-8 mt-4">
            <div className="flex ml-6 mt-4">
              <a href="/">
                <p class="text-base text-grey blue-on-hover ml-44">
                  Next Post{" "}
                </p>
              </a>
              <p class="text-xl text-darkblack ml-1 -mt-1">→</p>
            </div>
            <p class="text-base text-darkblack font-semibold ml-6 blue-on-hover pt-2">
              Take Your Career Next Leveley Future Approch
            </p>
          </div>
        </div>

        <div className="flex mt-8 ">
          <div>
            <p class="text-xl font-bold ml-10 text-darkblack">
              You May Also Like
            </p>
          </div>

          <div className="flex ml-12">
            <div className="rounded-md h-8 w-8 bg-newshade29 red-on-hover4">
              <a href="/">
                <p class="text-xl pt-1 ml-1">←</p>
              </a>
            </div>
            <div className="rounded-md h-8 w-8 bg-newshade29 ml-4 ">
              <a href="/">
                <p class="text-xl pt-1 ml-1">→</p>
              </a>
            </div>
          </div>
        </div>

        <div className="rounded-md bg-newshade29 mr-8 ml-8 mt-8 pb-12">
          <p class="text-2xl font-bold pt-12 ml-8 text-darkblack">
            Write a Review
          </p>
          <p class="text-base pt-4 ml-8">
            Your email address will not be <br></br>published. Required fields
            are marked *
          </p>
          <div>
            <form>
              <textarea
                class=" border-4 mt-6 text-base w-10/12 rounded-md ml-8"
                id="w3review"
                name="w3review"
                placeholder="Comment*"
                rows="8"
                cols="0"
              ></textarea>
            </form>
          </div>
          <div className="">
            <input
              class="border-4 bg-white rounded-md w-10/12 ml-8 p-4 text-base  mt-2"
              type="text"
              id="text"
              placeholder="Full Name"
            />
          </div>
          <div>
            <input
              class="border-4 bg-white rounded-md w-10/12 ml-8 p-4 text-base  mt-2"
              type="text"
              id="text"
              placeholder="Your Email"
            />
          </div>

          <div className="flex mt-4">
            <input class="ml-8" type="checkbox" id="checkbox" name="checkbox" />
            <p class="text-base -mt-2 ml-2">
              Save my name, email, and website in this browser for the next time
              I comment.
            </p>
          </div>

          <button class="ml-8 px-8 py-2 bg-newshade33 text-white rounded-md mt-6">
            Post Comment
          </button>
        </div>

        {/*2ND SECTION*/}
        <div className="mt-12 ">
          <div className="ml-8 mr-8">
            <div className="border-3 w-full rounded-md  figtree p-6 ">
              <div className="pt-12 ">
                <div className="rounded-md bg-newshade29 w-12/12  p-2">
                  <div className="flex justify-between">
                    <div>
                      <p class="text-grey xl:text-base ml-2">SearchHere..</p>
                    </div>
                    <div className="h-8 w-8 rounded-md bg-newshade33 ">
                      <div>
                        <img class="w-6 pt-1 ml-1" src={search1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-4 mt-10 ml-2 mr-4"></div>

              <p className="text-base1 font-bold text-darkblack mt-6 ml-2">
                Categories
              </p>

              <div className="flex mt-6 justify-between mr-4">
                <p class="text-base blue-on-hover "> › Art & Design</p>
                <p class="text-base ">(1)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Business</p>
                <p class="text-base ">(3)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Data Science</p>
                <p class="text-base ">(3)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Development</p>
                <p class="text-base ">(2)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Finance</p>
                <p class="text-base">(1)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <div className="flex mt-4 justify-between mr-4">
                <p class="text-base blue-on-hover ">› Health & Fitness</p>
                <p class="text-base ">(3)</p>
              </div>
              <div className="border-3 mt-6 ml-2 mr-4"></div>

              <p className="text-base1 font-bold text-darkblack mt-10 ml-6">
                Recent Post
              </p>

              <div className="flex mt-10 ml-4">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_03-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-2">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">August 10, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-2 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-2 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex mt-8 ml-4">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_04-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-2">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-2 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-2 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex mt-8 ml-4">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/blog_06-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-2">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-2 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-2 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="flex mt-8 ml-4">
                <div>
                  <img
                    class="w-20 rounded-md object-cover hover-scale transition-transform duration-300 ease-in-out"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/event_04-480x480.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex mt-2 ml-2">
                    <div>
                      <img class="w-4" src={calender1} />
                    </div>
                    <p class="text-xs ml-2">June 21, 2023</p>
                  </div>
                  <p class="text-sm hover-on-blue ml-2 text-darkblack pt-2">
                    Take Your Career Next
                  </p>
                  <p class="text-sm hover-on-blue ml-2 text-darkblack ">
                    Leveley Future Approch
                  </p>
                </div>
              </div>

              <div className="border-3 mt-6 ml-6 mr-8"></div>

              <div className="flex ml-6 mt-6">
                <div>
                  <p className="text-base1 font-bold text-darkblack ">Tags:</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4  ml-2">
                  <p class="text-base ">Course</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base  ">Education</p>
                </div>
              </div>
              <div className="flex mt-4">
                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4">
                  <p class="text-base  ">LMS</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base ">Online</p>
                </div>

                <div className="bg-newshade29 px-4 py-1 rounded-md red-on-hover4 ml-1">
                  <p class="text-base ">Training</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*END*/}

      <Footer2 />
    </>
  );
}
