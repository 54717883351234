import React from "react";
import {Swiper , SwiperSlide}from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'



import {RxArrowTopRight} from 'react-icons/rx'




const ActiveSlider = () => {
    return(
        <div> 
        </div>
    )
}
export default ActiveSlider