import Footer2 from "components/Footers/Footer2";
import React, {useState} from "react";
import share from "assets/img/share.png";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import videoplay from "assets/img/videoplay.png";
import arrowright from "assets/img/arrowright.png";
import womenn from "assets/img/womenn.png";
import teacher from "assets/img/teacher.png";
import researchstrategy from "assets/img/researchstrategy.png";
import people from "assets/img/people.png";
import strategicpartners from "assets/img/strategicpartners.png";
import corporatedprogramms from "assets/img/corporatedprograms.png";
import improvingresources from "assets/img/improvingresource.png";
import banner from "assets/img/banner.jpg";
import strategic1 from "assets/img/strategic1.png";
import calendar from "assets/img/calendar.png";
import graduate from "assets/img/graduate.png";
import elearning from "assets/img/elearning.png";
import badges from "assets/img/badges.png";
import winning from "assets/img/winning.png";
import thumnails from "assets/img/thumbnails.png";

export default function About3() {
  const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
  
    const nextSlide = () => {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    };
  
    const prevSlide = () => {
      setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    };
  
    const slides = [
      {
        id: 1,
        image: "https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_02-300x300.jpg",
        caption: "Neel Eonathon",
      },
      {
        id: 2,
        image: "https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg",
        caption: "Mr. K Jackerty",
      },
      {
        id: 3,
        image: "https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_03-300x300.jpg",
        caption: "Richard Whalen",
      },
      {
        id: 4,
        image: "https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_04-300x300.jpg",
        caption: "Esther Howard",
      },
    ];
  };

  
  const [showIcons, setShowIcons] = useState(false);
  const [showIcons1, setShowIcons1] = useState(false);
  const [showIcons2, setShowIcons2] = useState(false);
  const [showIcons3, setShowIcons3] = useState(false);


  
  return (
    <section>
      <Indexnavbar2 />

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden figtree bg-newshade29">
        <div
          style={{
            backgroundImage: `url("https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/counter_bg.png")`,
            width: "100%",
            height: "350px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className=" grid grid-cols-2 container mx-auto xl:pt-44">
            <div>
              <div className="flex  xl:ml-6">
                <a href="/homepage1">
                  <p class="xl:text-base text-grey font-bold ">Home</p>
                </a>
                <p class="xl:text-base  text-grey font-bold xl:ml-2">.</p>
                <p class="xl:text-base  text-darkblue font-bold xl:ml-2">
                  About
                </p>
              </div>
              <p class="xl:text-3xl text-darkblue font-bold xl:pt-8 xl:ml-6">
                About
              </p>
            </div>

            <div>
              <img
                class="xl:w-4/12 xl:ml-96"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/img/banner-shape.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto xl:mt-52">
        <div className="grid grid-cols-2 ">
          <div>
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 rounded-lg xl:ml-6"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img3-274x300.png "
                  alt=""
                />
              </div>
              <div>
                <div>
                  <img
                    class="xl:w-10/12 xl:ml-8 xl:-mt-24 rounded-full"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img4.png"
                    alt=""
                  />
                </div>
                <div class="">
                  <img
                    class="xl:w-11/12 xl:ml-10 xl:mt-2 curved-border"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img5.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="figtree xl:ml-24">
            <p class="xl:text-base text-darkblue font-semibold xl:-mt-24">
              ABOUT US
            </p>
            <p class="xl:text-4xl text-darkblack font-bold xl:pt-2">
              Build A Culture Of Collaboration
            </p>
            <p class="xl:text-4xl text-darkblack font-bold ">
              Through Peer Learning
            </p>
            <div>
              <img
                class="xl:w-3/12 xl:ml-64"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
                alt=""
              />
            </div>
            <p class="xl:text-base xl:pt-4">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A Type Specimen Book Has Survived When An
              Unknown Printer Took A Galley Offer Area Type And Scrambled To
              Make.
            </p>

            <p class="xl:text-base xl:pt-4">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A Type Specimen Book Has Survived When An
              Unknown Printer Took A Galley Offer Area Type And Scrambled To
              Make.
            </p>

            <div className="flex xl:mt-6">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1">
                <p class="text-white font-bold xl:text-xs text-center pt-1 ">
                  ✓
                </p>
              </div>
              <p class="xl:text-base  xl:ml-2 font-bold">
                Become a UX designer.
              </p>
            </div>

            <div className="flex xl:mt-2">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1">
                <p class="text-white font-bold xl:text-xs text-center pt-1 ">
                  ✓
                </p>
              </div>
              <p class="xl:text-base  xl:ml-2 font-bold">
                You will be able to add UX designe.
              </p>
            </div>

            <div className="flex xl:mt-2">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1">
                <p class="text-white font-bold xl:text-xs text-center pt-1 ">
                  ✓
                </p>
              </div>
              <p class="xl:text-base  xl:ml-2 font-bold">
                Become a UI designer.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden bg-newshade39 xl:mt-24">
        <div
          style={{
            backgroundImage: `url("https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/counter_bg.png")`,
            width: "100%",
            height: "250px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container mx-auto grid grid-cols-4 figtree xl:pt-24">
            <div>
              <p class="text-white xl:text-4xl font-bold">Join In On</p>
              <p class="text-white xl:text-4xl font-bold">Something Big</p>
            </div>

            <div>
              <div className="flex ">
                <div>
                  <img class="w-20 text-white " src={graduate} />
                </div>
                <div>
                  <p class="text-white xl:text-4xl font-bold xl:ml-6 xl:mt-2">
                    50M
                  </p>
                  <p class="text-white font-bold xl:text-base xl:ml-6">
                    Learners
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="flex ">
                <div>
                  <img class="w-20 text-white " src={elearning} />
                </div>
                <div>
                  <p class="text-white xl:text-4xl font-bold xl:ml-6 xl:mt-2">
                    120K
                  </p>
                  <p class="text-white font-bold xl:text-base xl:ml-6">
                    Top Courses
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="flex ">
                <div>
                  <img class="w-20 text-white " src={badges} />
                </div>
                <div>
                  <p class="text-white xl:text-4xl font-bold xl:ml-6 xl:mt-2">
                    34
                  </p>
                  <p class="text-white font-bold xl:text-base xl:ml-6">
                    Best Awards
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden  xl:pt-24 figtree bg-newshade29 xl:pb-32">
        <p class="text-center  xl:text-base font-semibold text-darkblue">
          CORE FEATURES
        </p>
        <p class="text-darkblack text-center xl:pt-2 font-bold xl:text-4xl">
          Why You Should Choose Quiklearn
        </p>
        <div className="center-button">
          <img
            class="xl:w-2/12  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center xl:text-base xl:mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen<br></br>
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="grid grid-cols-4 container mx-auto xl:mt-16">
          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out xl:pb-8">
            <div className="h-16 w-16 rounded-full bg-newshade34 xl:ml-32 xl:mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-1" src={graduate} />
              </div>
            </div>
            <p class="xl:text-xl font-bold text-darkblack text-center xl:pt-6">
              Expert-Led Video
            </p>
            <p class="xl:text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="xl:text-base text-center xl:pt-6">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out xl:pb-8 xl:ml-4">
            <div className="h-16 w-16 rounded-full bg-newshade35 xl:ml-32 xl:mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-1" src={elearning} />
              </div>
            </div>
            <p class="xl:text-xl font-bold text-darkblack text-center xl:pt-6">
              Expert-Led Video
            </p>
            <p class="xl:text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="xl:text-base text-center xl:pt-6">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out xl:pb-8 xl:ml-4">
            <div className="h-16 w-16 rounded-full bg-newshade37 xl:ml-32 xl:mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-2" src={winning} />
              </div>
            </div>
            <p class="xl:text-xl font-bold text-darkblack text-center xl:pt-6">
              Expert-Led Video
            </p>
            <p class="xl:text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="xl:text-base text-center xl:pt-6">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out xl:pb-8 xl:ml-4">
            <div className="h-16 w-16 rounded-full bg-newshade36 xl:ml-32 xl:mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-10 pt-3" src={thumnails} />
              </div>
            </div>
            <p class="xl:text-xl font-bold text-darkblack text-center xl:pt-6">
              Expert-Led Video
            </p>
            <p class="xl:text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="xl:text-base text-center xl:pt-6">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto xl:pt-24 figtree">
        <p class="text-center  xl:text-base font-semibold text-darkblue">
          OUR INSTRUCTORS
        </p>
        <p class="text-darkblack text-center xl:pt-2 font-bold xl:text-4xl">
          From The Quiklearn Community
        </p>
        <div className="center-button">
          <img
            class="xl:w-2/12  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center xl:text-base xl:mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen<br></br>
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="grid grid-cols-4 xl:mt-12 ">
          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl ">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/9/a87cce35aa3a4cb374ad14c92334a35e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute xl:mt-6 xl:ml-56"
                onMouseEnter={() => setShowIcons(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="xl:mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="xl:text-base1 text-center text-blue font-bold xl:mt-8 black-on-hover">
                Richard David
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                Marketing Expert
              </p>

              <div className="flex xl:mt-6 xl:pb-4">
                <p class="text-black xl:ml-6 xl:text-base1">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  2 courses
                </p>
                <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  16 students
                </p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl  xl:ml-4">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute xl:mt-6 xl:ml-56"
                onMouseEnter={() => setShowIcons1(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons1(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons1 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="xl:mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="xl:text-base1 text-center text-blue font-bold xl:mt-12 black-on-hover">
                Millar Richard
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">Business</p>

              <div className="flex xl:mt-6">
                <p class="text-black xl:ml-6 xl:text-base1">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  2 courses
                </p>
                <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  23 students
                </p>
              </div>
            </div>
          </div>

          <div className=" bg-newshade29 rounded-md xl:mr-4 shadow-xl  xl:ml-4">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/8/8d69d3fc4b9ecc16660fc9e32192e9e1.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute xl:mt-6 xl:ml-56"
                onMouseEnter={() => setShowIcons2(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons2(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons2 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="xl:mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="xl:text-base1 text-center text-blue font-bold xl:mt-12 black-on-hover">
                Kristin Watson
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">UX/UI Expert</p>

              <div className="flex xl:mt-6">
                <p class="text-black xl:ml-6 xl:text-base1">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  1 course
                </p>
                <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  1 student
                </p>
              </div>
            </div>
          </div>

          <div className=" bg-newshade29 rounded-md xl:mr-4 shadow-xl  xl:ml-4">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/7/84ce06432355d2548c4c2d534bb8873e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute xl:mt-6 xl:ml-56"
                onMouseEnter={() => setShowIcons3(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons3(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons3 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="xl:mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="xl:mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg xl:text-base1 h-10 w-10 items-center justify-center align-center rounded-full xl:ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="xl:text-base1 text-center text-blue font-bold xl:mt-12 black-on-hover">
                Jacob Jones
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                UX/UI Designer
              </p>

              <div className="flex xl:mt-6">
                <p class="text-black xl:ml-6 xl:text-base1">📄</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  2 courses
                </p>
                <p class="xl:text-base1 xl:ml-6 text-black">👤</p>
                <p class="xl:ml-2 xl:text-base text-grey-600 xl:mt-1">
                  8 students
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-24 bg-newshade29 figtree">
        <div>
          <p class="text-darkblue text-center xl:pt-24 font-semibold">
            OUR CLIENTS
          </p>
          <p class="text-darkblack text-center xl:pt-2 text-center xl:text-4xl font-bold">
            What’s Our Real Client Strories About Our <br></br>Work & Passion
          </p>
          <div className="center-button">
            <img
              class="xl:w-2/12 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="xl:text-base text-center xl:pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>


        <div className="md:block hidden ">
          <h2 className="md:mt-10">
            <logo-slider>
              <div className="px-2 ">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-sm pt-4 ml-1">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Neel Eonathon
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className=" pt-12 "
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_04-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal ml-1">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Robert Fox
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12  "
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_02-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal ml-1">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Richard Whalen
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_03-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold head1 text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Neel Eonathon
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_04-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Esther Howard
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_02-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Richard Whalen
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_03-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              {/* newly added */}
            </logo-slider>
          </h2>

          {/* LOGO SLIDER */}
          <h2 className="">
            <logo-slider2>
              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Robert Fox
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_02-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Richard Whalen
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_03-300x300.jpg"
                          alt=""
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Neel Eonathon
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_04-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold head1 text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Esther Howard
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12 "
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_02-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Neel Eonathon
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_04-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Richard Whalen
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_03-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>

              <div className="px-2">
                <h1 className="actions-template-card rounded  px-2">
                  <h1 className="w-full lg:w-12/12 md:w-12/12 ">
                    <h1 className="flex flex-wrap p-4">
                      <h1 className="lg:w-8/12 md:w-8/12 ">
                        <h2 className="font-bold text-base text-black pt-6">
                          ⭐⭐⭐⭐⭐
                        </h2>
                        <p className="head1 text-base pt-4 font-normal">
                          When An Unknown Printer Took Alley Ffer Area Typey And
                          Scrambled
                        </p>
                        <h2 className="font-bold text-xl text-black pt-4">
                          Mr. K Jackerty
                        </h2>
                      </h1>
                      <h1 className="lg:w-3/12 md:w-3/12 px-2 textalignend">
                        <img
                          className="pt-12"
                          src="radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/speaker_01-300x300.jpg"
                          alt="..."
                        />
                      </h1>
                    </h1>
                  </h1>
                </h1>
              </div>
            </logo-slider2>
          </h2>
        </div>
          /</section>


          <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 bg-newshade33">
        <section className="container mx-auto figtree">
          <div className="grid grid-cols-3">
            <div className="flex">
              <div>
                <img
                  class="xl:w-12/12 xl:mt-16"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-shape1.svg"
                  alt=""
                />
              </div>
              <div className="absolute">
                <img
                  class="xl:w-7/12 xl:mt-4 xl:ml-26 "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-action-img1.png"
                  alt=""
                />
              </div>
            </div>

            <div>
              <p class="text-white xl:text-3xl font-bold xl:mt-24 xl:-ml-4">
                Affordable Online Courses & Learning Opportunities For You
              </p>
            </div>

            <div>
              <a href="/">
                <button className="xl:px-6 xl:py-3 rounded-full bg-white red-on-hover4 xl:mt-28 xl:ml-20 text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>
        </section>
      </section>

      {/*LANDSCAPE VIEW*/}
      <section className="xl:hidden lg:block md:hidden sm:hidden hidden container mx-auto">
      <div
          style={{
            backgroundImage: `url("https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/counter_bg.png")`,
            width: "100%",
            height: "400px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className=" grid grid-cols-2 container mx-auto pt-12">
            <div>
              <div className="flex  ml-12">
                <a href="/homepage1">
                  <p class="text-base text-grey font-bold ">Home</p>
                </a>
                <p class="text-base  text-grey font-bold ml-2">.</p>
                <p class="text-base  text-darkblue font-bold ml-2">
                  About
                </p>
              </div>
              <p class="text-3xl text-darkblue font-bold pt-6 ml-12">
                About
              </p>
            </div>

            <div>
              <img
                class="w-4/12 ml-64"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/img/banner-shape.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 container mx-auto">
      <div>
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-lg ml-12"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img3-274x300.png "
                  alt=""
                />
              </div>
              <div>
                <div>
                  <img
                    class="w-10/12 ml-24 -mt-24 rounded-full"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img4.png"
                    alt=""
                  />
                </div>
                <div class="">
                  <img
                    class="w-10/12 ml-24 mt-2 curved-border"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img5.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="figtree mt-12">
            <p class="text-base text-darkblue font-semibold text-center">
              ABOUT US
            </p>
            <p class="text-4xl text-darkblack font-bold pt-2 text-center">
              Build A Culture Of Collaboration
            </p>
            <p class="text-4xl text-darkblack font-bold text-center">
              Through Peer Learning
            </p>
            <div className="center-button">
              <img
                class="w-32 "
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
                alt=""
              />
            </div>
            <p class="text-base pt-4 text-center px-8">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A Type Specimen Book Has Survived When An
              Unknown Printer Took A Galley Offer Area Type And Scrambled To
              Make.
            </p>

            <p class="text-base pt-4 text-center px-8">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A Type Specimen Book Has Survived When An
              Unknown Printer Took A Galley Offer Area Type And Scrambled To
              Make.
            </p>

            <div className="flex mt-6 justify-center">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1 ">
                <p class="text-white font-bold xl:text-xs text-center  ">
                  ✓
                </p>
              </div>
              <p class="text-base  ml-2 font-bold">
                Become a UX designer.
              </p>
            </div>

            <div className="flex mt-2 justify-center">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1 ">
                <p class="text-white font-bold xl:text-xs text-center  ">
                  ✓
                </p>
              </div>
              <p class="text-base  ml-2 font-bold">
                 able to add UX designe.
              </p>
            </div>

            <div className="flex mt-2 justify-center">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1 ">
                <p class="text-white font-bold text-xs text-center pt-1 ">
                  ✓
                </p>
              </div>
              <p class="text-base  ml-2 font-bold">
                Become a UI designer.
              </p>
            </div>
          </div>
     </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden bg-newshade39 mt-20">
        <div className="grid grid-cols-2 container mx-auto pt-12">
        <div className="">
            <div className="ml-44">
              <p class="text-white text-4xl font-bold">Join In On</p>
              <p class="text-white text-4xl font-bold">Something Big</p>
            </div>
            </div>
            <div>
              <div className="flex ml-32 ">
                <div>
                  <img class="w-20 text-white " src={graduate} />
                </div>
                <div>
                  <p class="text-white text-4xl font-bold ml-8 mt-2">
                    50M
                  </p>
                  <p class="text-white font-bold text-base ml-8">
                    Learners
                  </p>
                </div>
              </div>
            </div>
            </div>

            <div className="grid grid-cols-2 mt-12 pb-8">
            <div>
              <div className="flex ml-36   ">
                <div>
                  <img class="w-20 text-white " src={elearning} />
                </div>
                <div>
                  <p class="text-white text-4xl font-bold ml-6 mt-2">
                    120K
                  </p>
                  <p class="text-white font-bold text-base ml-6">
                    Top Courses
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="flex ml-32 ">
                <div>
                  <img class="w-20 text-white " src={badges} />
                </div>
                <div>
                  <p class="text-white text-4xl font-bold ml-6 mt-2">
                    34
                  </p>
                  <p class="text-white font-bold text-base ml-6">
                    Best Awards
                  </p>
                </div>
              </div>
            </div>
            </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20">
      <p class="text-center  text-base font-semibold text-darkblue">
          CORE FEATURES
        </p>
        <p class="text-darkblack text-center pt-2 font-bold text-4xl">
          Why You Should Choose Quiklearn
        </p>
        <div className="center-button">
          <img
            class="w-32  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center text-base mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen<br></br>
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="grid grid-cols-2 container mx-auto mt-12">
        <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-12">
            <div className="h-16 w-16 rounded-full bg-newshade34 ml-52 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-1" src={graduate} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-4 mr-12">
            <div className="h-16 w-16 rounded-full bg-newshade35 ml-48 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-1" src={elearning} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-12">
        <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-12">
            <div className="h-16 w-16 rounded-full bg-newshade37 ml-52 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-2" src={winning} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-4 mr-12">
            <div className="h-16 w-16 rounded-full bg-newshade36 ml-48 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-10 pt-3" src={thumnails} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>
        </div>
      </section>

      
      <section className="xl:hidden lg:block md:hidden sm:hidden hidden container mx-auto mt-20 figtree">
        <p class="text-center  text-base font-semibold text-darkblue">
          OUR INSTRUCTORS
        </p>
        <p class="text-darkblack text-center pt-2 font-bold text-4xl">
          From The Quiklearn Community
        </p>
        <div className="center-button">
          <img
            class="w-32  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center text-base mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen<br></br>
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="grid grid-cols-4 mt-12 ">
          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl ml-4 ">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/9/a87cce35aa3a4cb374ad14c92334a35e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-40"
                onMouseEnter={() => setShowIcons(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-8 black-on-hover">
                Richard David
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                Marketing Expert
              </p>

              <div className="flex mt-10 ">
                <p class="text-black ml-6 text-base1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>
              </div>
              <div className="flex  pb-4">
                <p class="text-base1 ml-6 text-black">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">16 students</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl  ml-2">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-40"
                onMouseEnter={() => setShowIcons1(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons1(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons1 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Millar Richard
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">Business</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>
              </div>
              <div className="flex pb-4">
                <p class="text-base1 ml-6 text-black">👤</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">23 students</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl ml-2 ">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/8/8d69d3fc4b9ecc16660fc9e32192e9e1.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-40"
                onMouseEnter={() => setShowIcons2(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons2(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons2 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Kristin Watson
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">UX/UI Expert</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 course</p>
              </div>
              <div className="flex">
                <p class="text-base1 ml-6 text-black">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 student</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl ml-2 mr-4">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/7/84ce06432355d2548c4c2d534bb8873e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-40"
                onMouseEnter={() => setShowIcons3(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons3(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons3 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Jacob Jones
              </p>
              <p class="text-grey-600 text-sm text-center ">UX/UI Designer</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1">📄</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">2 courses</p>
              </div>
              <div className="flex">
                <p class="text-base1 ml-6 text-black">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">8 students</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 bg-newshade29 figtree pb-16">
        <div>
          <p class="text-darkblue text-center pt-16 font-semibold">
            OUR CLIENTS
          </p>
          <p class="text-darkblack text-center pt-2 text-center text-4xl font-bold">
            What’s Our Real Client Strories About Our <br></br>Work & Passion
          </p>
          <div className="center-button">
            <img
              class="w-32 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="text-base text-center pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 bg-newshade33">
        <section className="container mx-auto figtree">
          <div className="grid grid-cols-3">
            <div className="flex">
              <div>
                <img
                  class="w-10/12 mt-16"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-shape1.svg"
                  alt=""
                />
              </div>
              <div className="absolute">
                <img
                  class="w-6/12 mt-4 ml-26 "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-action-img1.png"
                  alt=""
                />
              </div>
            </div>

            <div>
              <p class="text-white text-3xl font-bold mt-12 ">
                Affordable Online Courses & Learning Opportunities For You
              </p>
            </div>

            <div>
              <a href="/">
                <button className="px-6 py-3 rounded-full bg-white red-on-hover4 mt-16 ml-16 text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>
        </section>
      </section>


      {/*TAB VIEW*/}
        
      <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto">
      <div
          style={{
            backgroundImage: `url("https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/counter_bg.png")`,
            width: "100%",
            height: "400px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className=" grid grid-cols-2 container mx-auto pt-12">
            <div>
              <div className="flex  ml-12">
                <a href="/homepage1">
                  <p class="text-base text-grey font-bold ">Home</p>
                </a>
                <p class="text-base  text-grey font-bold ml-2">.</p>
                <p class="text-base  text-darkblue font-bold ml-2">
                  About
                </p>
              </div>
              <p class="text-3xl text-darkblue font-bold pt-4 ml-12">
                About
              </p>
            </div>

            <div>
              <img
                class="w-6/12 ml-40"
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/img/banner-shape.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20 container mx-auto">
      <div>
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-lg ml-8"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img3-274x300.png "
                  alt=""
                />
              </div>
              <div>
                <div>
                  <img
                    class="w-10/12 ml-12 -mt-24 rounded-full"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img4.png"
                    alt=""
                  />
                </div>
                <div class="">
                  <img
                    class="w-10/12 ml-12 mt-2 curved-border"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img5.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="figtree mt-12">
            <p class="text-base text-darkblue font-semibold text-center">
              ABOUT US
            </p>
            <p class="text-4xl text-darkblack font-bold pt-2 text-center">
              Build A Culture Of Collaboration
            </p>
            <p class="text-4xl text-darkblack font-bold text-center">
              Through Peer Learning
            </p>
            <div className="center-button">
              <img
                class="w-32 "
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
                alt=""
              />
            </div>
            <p class="text-base pt-4 text-center px-8">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A Type Specimen Book Has Survived When An
              Unknown Printer Took A Galley Offer Area Type And Scrambled To
              Make.
            </p>

            <p class="text-base pt-4 text-center px-8">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A Type Specimen Book Has Survived When An
              Unknown Printer Took A Galley Offer Area Type And Scrambled To
              Make.
            </p>

            <div className="flex mt-6 justify-center">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1 ">
                <p class="text-white font-bold xl:text-xs text-center  ">
                  ✓
                </p>
              </div>
              <p class="text-base  ml-2 font-bold">
                Become a UX designer.
              </p>
            </div>

            <div className="flex mt-2 justify-center">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1 ">
                <p class="text-white font-bold xl:text-xs text-center  ">
                  ✓
                </p>
              </div>
              <p class="text-base  ml-2 font-bold">
                 able to add UX designe.
              </p>
            </div>

            <div className="flex mt-2 justify-center">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1 ">
                <p class="text-white font-bold text-xs text-center pt-1 ">
                  ✓
                </p>
              </div>
              <p class="text-base  ml-2 font-bold">
                Become a UI designer.
              </p>
            </div>
          </div>
     </section>

     <section className="xl:hidden lg:hidden md:block sm:hidden hidden bg-newshade39 mt-20">
        <div className="grid grid-cols-2 container mx-auto pt-12">
        <div className="">
            <div className="ml-12">
              <p class="text-white text-4xl font-bold">Join In On</p>
              <p class="text-white text-4xl font-bold">Something Big</p>
            </div>
            </div>
            <div>
              <div className="flex ml-32 ">
                <div>
                  <img class="w-20 text-white " src={graduate} />
                </div>
                <div>
                  <p class="text-white text-4xl font-bold ml-8 mt-2">
                    50M
                  </p>
                  <p class="text-white font-bold text-base ml-8">
                    Learners
                  </p>
                </div>
              </div>
            </div>
            </div>

            <div className="grid grid-cols-2 mt-12 pb-8">
            <div>
              <div className="flex ml-12   ">
                <div>
                  <img class="w-20 text-white " src={elearning} />
                </div>
                <div>
                  <p class="text-white text-4xl font-bold ml-6 mt-2">
                    120K
                  </p>
                  <p class="text-white font-bold text-base ml-6">
                    Top Courses
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="flex ml-32 ">
                <div>
                  <img class="w-20 text-white " src={badges} />
                </div>
                <div>
                  <p class="text-white text-4xl font-bold ml-6 mt-2">
                    34
                  </p>
                  <p class="text-white font-bold text-base ml-6">
                    Best Awards
                  </p>
                </div>
              </div>
            </div>
            </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20">
      <p class="text-center  text-base font-semibold text-darkblue">
          CORE FEATURES
        </p>
        <p class="text-darkblack text-center pt-2 font-bold text-4xl">
          Why You Should Choose Quiklearn
        </p>
        <div className="center-button">
          <img
            class="w-32  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center text-base mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen<br></br>
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="grid grid-cols-2 container mx-auto mt-12">
        <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-12">
            <div className="h-16 w-16 rounded-full bg-newshade34 ml-32 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-1" src={graduate} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-4 mr-12">
            <div className="h-16 w-16 rounded-full bg-newshade35 ml-32 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-1" src={elearning} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-12">
        <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-12">
            <div className="h-16 w-16 rounded-full bg-newshade37 ml-32 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-2" src={winning} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-4 mr-12">
            <div className="h-16 w-16 rounded-full bg-newshade36 ml-32 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-10 pt-3" src={thumnails} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto mt-24 figtree">
        <p class="text-center  text-base font-semibold text-darkblue">
          OUR INSTRUCTORS
        </p>
        <p class="text-darkblack text-center pt-2 font-bold text-4xl">
          From The Quiklearn Community
        </p>
        <div className="center-button">
          <img
            class="w-32  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center text-base mt-2">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen<br></br>
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="grid grid-cols-2 mt-12 ">
          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl ml-6 mr-4 ">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/9/a87cce35aa3a4cb374ad14c92334a35e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-8 black-on-hover">
                Richard David
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                Marketing Expert
              </p>

              <div className="flex mt-10 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">16 students</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl  ml-2 mr-4">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons1(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons1(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons1 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Millar Richard
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">Business</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">23 students</p>
              </div>
            </div>
          </div>
        </div>


        <div className="grid grid-cols-2 mt-12">
          <div className="  bg-newshade29 rounded-md  shadow-xl ml-6 mr-4 ">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/8/8d69d3fc4b9ecc16660fc9e32192e9e1.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons2(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons2(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons2 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Kristin Watson
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">UX/UI Expert</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 course</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 student</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl ml-2 mr-4">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/7/84ce06432355d2548c4c2d534bb8873e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons3(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons3(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons3 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Jacob Jones
              </p>
              <p class="text-grey-600 text-sm text-center ">UX/UI Designer</p>

              <div className="flex mt-6">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">8 students</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20 bg-newshade29 figtree pb-16">
        <div>
          <p class="text-darkblue text-center pt-16 font-semibold">
            OUR CLIENTS
          </p>
          <p class="text-darkblack text-center pt-2 text-center text-4xl font-bold">
            What’s Our Real Client Strories About Our <br></br>Work & Passion
          </p>
          <div className="center-button">
            <img
              class="w-32 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="text-base text-center pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20 bg-newshade33">
        <section className="container mx-auto figtree">
          <div className="">
            <div className="flex center-button">
              <div>
                <img
                  class="w-10/12 mt-16"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-shape1.svg"
                  alt=""
                />
              </div>
              <div className="absolute">
                <img
                  class="w-10/12 mt-4  "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-action-img1.png"
                  alt=""
                />
              </div>
            </div>

            <div>
              <p class="text-white text-2xl font-bold mt-12 text-center">
                Affordable Online Courses & Learning Opportunities For You
              </p>
            </div>

            <div className="center-button pb-8">
              <a href="/">
                <button className="px-6 py-3 rounded-full bg-white red-on-hover4 mt-12 ml-4 text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>
        </section>
      </section>


      {/*MOBILE VIEW*/}
       
      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade29 pb-12">
      <div>
              <div className="flex  ml-12 pt-12 ">
                <a href="/homepage1">
                  <p class="text-base text-grey font-bold ">Home</p>
                </a>
                <p class="text-base  text-grey font-bold ml-2">.</p>
                <p class="text-base  text-darkblue font-bold ml-2">
                  About
                </p>
              </div>
              <p class="text-3xl text-darkblue font-bold  ml-12 pt-4">
                About
              </p>
            </div>
       </section>

       <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20">
       <div>
                <img
                  class="w-10/12 rounded-lg ml-8"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img3-274x300.png "
                  alt=""
                />
              </div>

              <div>
                  <img
                    class="w-10/12 ml-8 mt-4 rounded-full"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img4.png"
                    alt=""
                  />
                </div>

                <div class="">
                  <img
                    class="w-10/12 ml-8 mt-4 curved-border"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/about-img5.png"
                    alt=""
                  />
                </div>

                <div className="figtree mt-12">
            <p class="text-base text-darkblue font-semibold text-center">
              ABOUT US
            </p>
            <p class="text-3xl text-darkblack font-bold pt-2 text-center">
              Build A Culture<br></br> Of Collaboration
            </p>
            <p class="text-3xl text-darkblack font-bold text-center">
              Through Peer Learning
            </p>
            <div className="center-button">
              <img
                class="w-32 "
                src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
                alt=""
              />
            </div>
            <p class="text-base pt-4 text-center px-8">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A Type Specimen Book Has Survived When An
              Unknown Printer Took A Galley Offer Area Type And Scrambled To
              Make.
            </p>

            <p class="text-base pt-4 text-center px-8">
              When An Unknown Printer Took A Galley Offer Area Type And
              Scrambled To Make A Type Specimen Book Has Survived When An
              Unknown Printer Took A Galley Offer Area Type And Scrambled To
              Make.
            </p>

            <div className="flex mt-6 justify-center">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1 ">
                <p class="text-white font-bold xl:text-xs text-center  ">
                  ✓
                </p>
              </div>
              <p class="text-base  ml-2 font-bold">
                Become a UX designer.
              </p>
            </div>

            <div className="flex mt-2 justify-center">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1 ">
                <p class="text-white font-bold xl:text-xs text-center  ">
                  ✓
                </p>
              </div>
              <p class="text-base  ml-2 font-bold">
                 able to add UX designe.
              </p>
            </div>

            <div className="flex mt-2 justify-center -ml-4">
              <div className="bg-newshade33 rounded-full w-6 h-6 mt-1  ">
                <p class="text-white font-bold text-xs text-center pt-1 ">
                  ✓
                </p>
              </div>
              <p class="text-base  ml-2 font-bold">
                Become a UI designer.
              </p>
            </div>
          </div>
       </section>

       <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade39 mt-20 pb-8">
        <div className=" container mx-auto pt-12 ">
        <div className="">
            <div className="">
              <p class="text-white text-4xl font-bold text-center">Join In On</p>
              <p class="text-white text-4xl font-bold text-center">Something Big</p>
            </div>
            </div>
            <div>
              <div className="flex  justify-center mt-8">
                <div>
                  <img class="w-20 text-white " src={graduate} />
                </div>
                <div>
                  <p class="text-white text-4xl font-bold ml-8 mt-2">
                    50M
                  </p>
                  <p class="text-white font-bold text-base ml-8">
                    Learners
                  </p>
                </div>
              </div>
            </div>
            </div>

            <div className=" mt-8 ">
            <div>
              <div className="flex mt-8 justify-center  ">
                <div>
                  <img class="w-20 text-white " src={elearning} />
                </div>
                <div>
                  <p class="text-white text-4xl font-bold ml-6 mt-2">
                    120K
                  </p>
                  <p class="text-white font-bold text-base ml-6">
                    Top Courses
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="flex justify-center mt-8 ">
                <div>
                  <img class="w-20 text-white " src={badges} />
                </div>
                <div>
                  <p class="text-white text-4xl font-bold ml-6 mt-2">
                    34
                  </p>
                  <p class="text-white font-bold text-base ml-6">
                    Best Awards
                  </p>
                </div>
              </div>
            </div>
            </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20">
      <p class="text-center  text-base font-semibold text-darkblue">
          CORE FEATURES
        </p>
        <p class="text-darkblack text-center pt-2 font-bold text-3xl">
          Why You Should Choose Quiklearn
        </p>
        <div className="center-button">
          <img
            class="w-32  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center text-base mt-2 px-8">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen
          Book It Has Survived Not Only Five Centuries
        </p>

        <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-12 mr-12 mt-12">
            <div className="h-16 w-16 rounded-full bg-newshade34 ml-28 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-1" src={graduate} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-12 mr-12 mt-8">
            <div className="h-16 w-16 rounded-full bg-newshade35 ml-28 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-1" src={elearning} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-12 mr-12 mt-8">
            <div className="h-16 w-16 rounded-full bg-newshade37 ml-28 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-12 pt-2" src={winning} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>

          <div className="border-5 bg-white shadow-xl rounded-md object-cover hover-scale1 transition-transform duration-300 ease-in-out pb-8 ml-12 mt-8 mr-12">
            <div className="h-16 w-16 rounded-full bg-newshade36 ml-28 mt-12 object-cover hover-scale transition-transform duration-300 ease-in-out">
              <div className="center-button">
                <img class="w-10 pt-3" src={thumnails} />
              </div>
            </div>
            <p class="text-xl font-bold text-darkblack text-center pt-6">
              Expert-Led Video
            </p>
            <p class="text-xl font-bold text-darkblack text-center ">
              Courses
            </p>
            <p class="text-base text-center pt-4">
              When An Unknown Printer Took A <br></br>Galley Offer Area Type And{" "}
              <br></br>Scrambled To Make
            </p>
          </div>
     </section>


     <section className="xl:hidden lg:hidden md:hidden sm:block block container mx-auto mt-20">
          <p class="text-center  text-base font-semibold text-darkblue">
          OUR INSTRUCTORS
        </p>
        <p class="text-darkblack text-center pt-2 font-bold text-4xl">
          From The Quiklearn Community
        </p>
        <div className="center-button">
          <img
            class="w-32  "
            src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
            alt=""
          />
        </div>
        <p class="text-center text-sm mt-2 px-8">
          When An Unknown Printer Took A Galley Of Type And Scrambled It To Make
          A Type Specimen
          Book It Has Survived Not Only Five Centuries
        </p>


          <div className="mt-8">
          <div className="  bg-newshade29 rounded-md xl:mr-4 shadow-xl ml-6 mr-4 ">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/9/a87cce35aa3a4cb374ad14c92334a35e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-8 black-on-hover">
                Richard David
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">
                Marketing Expert
              </p>

              <div className="flex mt-10 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">16 students</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl  ml-6 mr-4 mt-8">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/10/418cbee72cafbbf58290b71f9e359260.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons1(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons1(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons1 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Millar Richard
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">Business</p>

              <div className="flex mt-6 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">23 students</p>
              </div>
            </div>
          </div>


          <div className="  bg-newshade29 rounded-md  shadow-xl ml-6 mr-4 mt-8 ">
            <div className="flex">
              <div>
                <img
                  class="w-12/12  rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/8/8d69d3fc4b9ecc16660fc9e32192e9e1.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons2(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons2(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons2 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Kristin Watson
              </p>
              <p class="text-grey-600 xl:text-sm text-center ">UX/UI Expert</p>

              <div className="flex mt-6 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 course</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">1 student</p>
              </div>
            </div>
          </div>

          <div className="  bg-newshade29 rounded-md  shadow-xl ml-6 mt-8 mr-4">
            <div className="flex">
              <div>
                <img
                  class="w-12/12 rounded-md  object-cover hover-scale1 transition-transform duration-300 ease-in-out"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads//learn-press-profile/7/84ce06432355d2548c4c2d534bb8873e.jpeg"
                  alt=""
                />
              </div>
              <div
                className="bg-white w-12 h-12 rounded-full absolute mt-6 ml-64"
                onMouseEnter={() => setShowIcons3(true)} // Show icons on mouse enter
                onMouseLeave={() => setShowIcons3(false)} // Hide icons on mouse leave
              >
                <div className="center-button">
                  <img className="w-4 pt-3" src={share} alt="Share Button" />
                </div>
                {showIcons3 && (
                  <div className="absolute top-0 left-0 flex flex-col">
                    {/* Additional icons */}
                    {/* Example icons */}
                    <span class="mt-16">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </span>
                    <span class="mt-2">
                      <a href="/">
                        <button className="bg-white text-black shadow-lg text-base1 h-10 w-10 items-center justify-center align-center rounded-full ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <p class="text-base1 text-center text-blue font-bold mt-12 black-on-hover">
                Jacob Jones
              </p>
              <p class="text-grey-600 text-sm text-center ">UX/UI Designer</p>

              <div className="flex mt-6 pb-4">
                <p class="text-black ml-6 text-base1 pt-1">📄</p>
                <p class="ml-2 xl:text-base text-grey-600 mt-1">2 courses</p>

                <p class="text-base1 ml-16 text-black pt-1">👤</p>
                <p class="ml-2 text-base text-grey-600 mt-1">8 students</p>
              </div>
            </div>
          </div>
            </div>
          </section>


          <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 bg-newshade29 figtree pb-16">
        <div>
          <p class="text-darkblue text-center pt-12 font-semibold">
            OUR CLIENTS
          </p>
          <p class="text-darkblack text-center pt-2 text-center text-3xl font-bold">
            What’s Our Real Client Strories About Our <br></br>Work & Passion
          </p>
          <div className="center-button">
            <img
              class="w-32 "
              src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg"
              alt=""
            />
          </div>
          <p class="text-sm px-8 text-center pt-4">
            When An Unknown Printer Took A Galley Of Type And Scrambled It To
            Make A Type Specimen <br></br>Book It Has Survived Not Only Five
            Centuries
          </p>
        </div>
      </section>


          <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 bg-newshade33">
        <section className="container mx-auto figtree">
          <div className="">
            <div className="flex center-button ml-8">
              <div>
                <img
                  class="w-10/12 mt-16"
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-shape1.svg"
                  alt=""
                />
              </div>
              <div className="absolute">
                <img
                  class="w-10/12 mt-4  "
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/call-action-img1.png"
                  alt=""
                />
              </div>
            </div>

            <div>
              <p class="text-white text-2xl font-bold mt-12 text-center">
                Affordable Online Courses & Learning Opportunities For You
              </p>
            </div>

            <div className="center-button pb-8">
              <a href="/">
                <button className="px-6 py-3 rounded-full bg-white red-on-hover4 mt-12  text-blue">
                  Start Learning Today ➔
                </button>
              </a>
            </div>
          </div>
        </section>
      </section>


      {/*END*/}
      <Footer2 />
    </section>
  );
}
