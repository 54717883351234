import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import rplogo from "assets/img/rplogo.png";
import rplogo2 from "assets/img/rplogo2.png";
import Footer2 from "components/Footers/Footer2";
import drop from "assets/img/drop.png";
import { createPopper } from '@popperjs/core';

export default function Indexnavbar2() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const popoverDropdownRef = React.createRef();
  const btnDropdownRef = React.createRef();

  const [navbarOpen1, setNavbarOpen1] = React.useState(false);
  const popoverDropdownRef1 = React.createRef();
  const btnDropdownRef1 = React.createRef();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const [isDropdownOpenn, setDropdownOpenn] = useState(false);
  const handleDropdownTogglee = () => {
    setDropdownOpenn(!isDropdownOpenn);
  };

  useEffect(() => {
    if (isDropdownVisible) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });
    }
  }, [isDropdownVisible]);

  window.addEventListener("scroll", function () {
    var nav = document.querySelector(".bg-newshade32");
    nav.classList.toggle("scrolled", window.scrollY > 0);
  });

  return (
    
    <section>
      
      <section className="bg-1 bg-newshade32 .scrolled fixed w-full z-50">
        <section className=" flex xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto figtree">
          <div className="flex  flex-wrap  justify-between p-2">
            <div>
              <div>
                <a href="/homepage2">
                  <img
                    class="xl:w-10/12 xl:mt-2"
                    src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/logo-light.svg"
                    alt=""
                  />
                </a>
              </div>
            </div>

            <div class=" xl:text-lg font-bold">
              <nav>
                <ul class="navbar-links">
                  <li class="navbar-dropdown ">
                    <a class="xl:ml-24" href="/homepage1">
                      Home
                    </a>
                    {/*} <div class="dropdown ">
                  <a class=" " href="/homepage1">
                    Homepage1
                  </a>
                  <a class="" href="/homepage1">
                    Homepage2
                  </a>
                  <a class="" href="/homepage1">
                    Homepage3
                  </a>
                  <a class="r" href="/homepage1">
                    Homepage4
                  </a>
  </div>*/}
                  </li>
                  <li class="navbar-dropdown">
                    
                    <a  href="#">Cources </a>
                    
                    <div class="dropdown">
                      <a class="text-darkblack xl:text-xl font-bold" href="#">
                        Course Category
                      </a>
                      <a href="#">Business</a>
                      <a href="#">Technology</a>
                      <a href="#">Development</a>
                      <a href="#">Finance</a>
                      <a href="#">Health&Fitness</a>
                      <a href="#">Data Science</a>
                      <a href="#"> Art&Desgning</a>
                    </div>
                  </li>
                  <li class="navbar-dropdown">
                    <a href="Services2">Events</a>
                    <div class="dropdown">
                      <a href="#">All Events</a>
                      <a href="#">Event Details</a>
                      <a href="#">All speaker </a>
                      <a href="#">Speaker Details</a>
                    </div>
                  </li>
                  <li class="navbar-dropdown">
                    <a href="/About3">About-Us</a>
                  </li>
                  <li class="navbar-dropdown">
                    <a href="/Employer">Blog</a>
                    {/*<div class="dropdown">
                  <a href="#">Blog Grid</a>
                  <a href="#">Blog List</a>
                  <a href="#">Post Standard</a>
                  <a href="#">Post Gallery</a>
                  <a href="#">Post Video</a>
</div>*/}
                  </li>

                  <li class="navbar-dropdown">
                    <a href="contact?">Contact Us</a>
                  </li>
                </ul>
              </nav>
            </div>

            <div>
              <a href="/">
                <button class="text-white xl:text-lg font-bold rounded-md border-3 xl:px-8 xl:py-1 xl:mt-4 xl:ml-32 ">
                  Login
                </button>
              </a>
            </div>
          </div>
        </section>
      </section>


      {/*LANDSCAPE VIEW*/}


     
      <section className={`xl:hidden lg:block md:hidden sm:hidden hidden bg-newshade39  pb-4 ${navbarOpen ? '' : ''}`}>
      <div className="container mx-auto">
        <div className="flex justify-between">
          <div>
            <img className="w-40 pt-2 ml-8" src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/logo-light.svg" alt=""/>
          </div>
          <div> 
            <div className="text-2xl text-white mr-12 pt-4 bg-transparent" onClick={() => setNavbarOpen(!navbarOpen)}>
              {navbarOpen ? (
                <i className="fas fa-times"></i>
              ) : (
                <i className="fas fa-bars"></i>
              )}
            </div>
          </div>
        </div>
        {navbarOpen && (
          <ul className="navbar-menu bg-newshade39 ml-16 mt-6">
            <a href="/homepage1">
            <li className="navbar-item bg-newshade39 text-xl figtree text-white font-semibold">Home</li>
            </a>

            <a href="/About3">
            <li className="navbar-item bg-newshade39 text-xl figtree text-white font-semibold mt-2">About-us</li>
            </a>

            <div className="flex justify-between mt-2">
      <p className="text-xl text-white font-semibold bg-newshade39 figtree ">Courses</p>
      <p
        className="text-xl text-white font-bold mr-12"
        onClick={() => setIsOpen(!isOpen)}
      >
        +
      </p>
      </div>
      {isOpen && (
        <div className="text-base text-white figtree ml-10">
           <p class="pt-2"><a href="/homepage1">Business</a></p>
           <p class="pt-2"><a href="/homepage1">Technology</a></p>
           <p class="pt-2"><a href="/homepage1">Development</a></p>
        </div>
      )}
     
            <div className="flex justify-between mt-2">
              <a href="/Services2">
      <p className="text-xl text-white font-semibold bg-newshade39 figtree ">Events</p></a>
      <p
        className="text-xl text-white font-bold mr-12"
        onClick={() => setIsOpen1(!isOpen1)}
      >
        +
      </p>
      </div>
      {isOpen1 && (
         <div className="text-base text-white figtree ml-10">
         <p class="pt-2"><a href="/homepage1">All Events</a></p>
         <p class="pt-2"><a href="/homepage1">Event Details</a></p>
         <p class="pt-2"><a href="/homepage1">Speaker</a></p>
      </div>
      )}
            <a href="/Employer">
            <li className="navbar-item bg-newshade39 text-xl figtree text-white font-semibold mt-2">Blog</li>
            </a>

            <a href="/contact?">
            <li className="navbar-item bg-newshade39 text-xl figtree text-white font-semibold mt-2">Contact-Us</li>
            </a>
          </ul>
        )}
      </div>
    </section>

     {/*TAB VIEW*/}

    <section className={`xl:hidden lg:hidden md:block sm:hidden hidden bg-newshade39  pb-4 ${navbarOpen ? '' : ''}`}>
      <div className="container mx-auto">
        <div className="flex justify-between">
          <div>
            <img className="w-40 pt-2 ml-8" src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/logo-light.svg" alt=""/>
          </div>
          <div> 
            <div className="text-2xl text-white mr-12 pt-4 bg-transparent" onClick={() => setNavbarOpen(!navbarOpen)}>
              {navbarOpen ? (
                <i className="fas fa-times"></i>
              ) : (
                <i className="fas fa-bars"></i>
              )}
            </div>
          </div>
        </div>
        {navbarOpen && (
          <ul className="navbar-menu bg-newshade39 ml-16 mt-6">
            <a href="/homepage1">
            <li className="navbar-item bg-transparent text-xl figtree text-white font-semibold">Home</li>
            </a>

            <a href="/About3">
            <li className="navbar-item bg-transparent text-xl figtree text-white font-semibold">About-us</li>
            </a>

            <div className="flex justify-between mt-2">
      <p className="text-xl text-white font-semibold bg-transparent figtree ">Courses</p>
      <p
        className="text-xl text-white font-bold mr-12"
        onClick={() => setIsOpen(!isOpen)}
      >
        +
      </p>
      </div>
      {isOpen && (
        <div className="text-base text-white figtree ml-10">
           <p class="pt-2"><a href="/homepage1">Business</a></p>
           <p class="pt-2"><a href="/homepage1">Technology</a></p>
           <p class="pt-2"><a href="/homepage1">Development</a></p>
        </div>
      )}
     
            <div className="flex justify-between mt-2">
              <a href="/Services2">
      <p className="text-xl text-white font-semibold bg-transparent figtree ">Events</p></a>
      <p
        className="text-xl text-white font-bold mr-12"
        onClick={() => setIsOpen1(!isOpen1)}
      >
        +
      </p>
      </div>
      {isOpen1 && (
         <div className="text-base text-white figtree ml-10">
         <p class="pt-2"><a href="/homepage1">All Events</a></p>
         <p class="pt-2"><a href="/homepage1">Event Details</a></p>
         <p class="pt-2"><a href="/homepage1">Speaker</a></p>
      </div>
      )}
            <a href="/Employer">
            <li className="navbar-item bg-transparent text-xl figtree text-white font-semibold mt-2">Blog</li>
            </a>

            <a href="/contact?">
            <li className="navbar-item bg-transparent text-xl figtree text-white font-semibold mt-2">Contact-Us</li>
            </a>
          </ul>
        )}
      </div>
    </section>


    {/*MOBILE VIEW*/}
    <section className={`xl:hidden lg:hidden md:hidden sm:block block bg-newshade39  pb-4 ${navbarOpen ? '' : ''}`}>
      <div className="container mx-auto">
        <div className="flex justify-between">
          <div>
            <img className="w-40 pt-2 ml-8" src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/logo-light.svg" alt=""/>
          </div>
          <div> 
            <div className="text-2xl text-white mr-12 pt-4 bg-transparent" onClick={() => setNavbarOpen(!navbarOpen)}>
              {navbarOpen ? (
                <i className="fas fa-times"></i>
              ) : (
                <i className="fas fa-bars"></i>
              )}
            </div>
          </div>
        </div>
        {navbarOpen && (
          <ul className="navbar-menu bg-newshade39 ml-16 mt-6">
            <a href="/homepage1">
            <li className="navbar-item bg-transparent text-xl figtree text-white font-semibold">Home</li>
            </a>

            <a href="/About3">
            <li className="navbar-item bg-transparent text-xl figtree text-white font-semibold">About-Us</li>
            </a>

            <div className="flex justify-between mt-2">
      <p className="text-xl text-white font-semibold bg-transparent figtree ">Courses</p>
      <p
        className="text-xl text-white font-bold mr-12"
        onClick={() => setIsOpen(!isOpen)}
      >
        +
      </p>
      </div>
      {isOpen && (
        <div className="text-base text-white figtree ml-10">
           <p class="pt-2"><a href="/homepage1">Business</a></p>
           <p class="pt-2"><a href="/homepage1">Technology</a></p>
           <p class="pt-2"><a href="/homepage1">Development</a></p>
        </div>
      )}
     
            <div className="flex justify-between mt-2">
              <a href="/Services2">
      <p className="text-xl text-white font-semibold bg-transparent figtree ">Events</p></a>
      <p
        className="text-xl text-white font-bold mr-12"
        onClick={() => setIsOpen1(!isOpen1)}
      >
        +
      </p>
      </div>
      {isOpen1 && (
         <div className="text-base text-white figtree ml-10">
         <p class="pt-2"><a href="/homepage1">All Events</a></p>
         <p class="pt-2"><a href="/homepage1">Event Details</a></p>
         <p class="pt-2"><a href="/homepage1">Speaker</a></p>
      </div>
      )}
            <a href="/Employer">
            <li className="navbar-item bg-transparent text-xl figtree text-white font-semibold mt-2">Blog</li>
            </a>

            <a href="/contact?">
            <li className="navbar-item bg-transparent text-xl figtree text-white font-semibold mt-2">Contact-Us</li>
            </a>
          </ul>
        )}
      </div>
    </section>
       

        {/*TAB AND MOBILE VIEW*/}
      {/*<section className=" container mx-auto xl:hidden lg:hidden md:block block sm:block ">
        <div className="w-full relative flex justify-between">
          <div className={`px-0 ${navbarOpen ? "hidden" : ""}`}>
            <span>
              <a href="http://localhost:3000/">
                <img
                  src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/uploads/2023/08/logo-dark.svg" alt=""
                  className="   md:w-40 md:h-45 md:mt-4 w-40   mt-2  cursor-pointer  lg:pr-0 md:pr-0   text-1xl md:ml-4 h-8 ml-4 "
                />
              </a>
            </span>
          </div>
          <button
            className="bg-transparent cursor-pointer text-lg text-black font-bold leading-none border-transparent rounded  ml-12 block  outline-none focus:outline-none md:mr-4 mr-8 md:mt-4 mt-2"
            type="button  "
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? (
              <i className=""></i>
            ) : (
              <i className="fas fa-bars"></i>
            )}
          </button>
        </div>
          </section>*/}

     {/*} <div className="xl:hidden lg:hidden md:block sm:block block ">
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none py-3 xl:pt-1 xl:py-0" +
            (navbarOpen ? " block bg-white" : " hidden")
          }
          id="example-navbar-warning"
        >
          <div className="px-0">
            <div className="xl:w-full relative flex justify-between lg:w-auto lg:static lg:justify-start">
              <div className="pr-4 ">
                <span>
                  <a href="/">
                    <img
                      src={rplogo}
                      alt=""
                      className=" md:w-40 md:h-45 md:mt-0 h-8  cursor-pointer pr-0  md:pr-0   md:ml-12 mt-2 ml-6  w-32"
                    />
                  </a>
                </span>
              </div>
              <button
                className="cursor-pointer text-3xl text-black md:mr-12 font-semibold leading-none block  outline-none focus:outline-none mr-8"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                x
              </button>
            </div>

            <div className="xl:hidden lg:hidden md:block block">
              <a href="/About3">
                <p className=" cursor-pointer md:pl-6 pl-2  md:pb-3 pb-0  md:pt-4 pt-5  md:text-base font  uppercase  text-black md:ml-10 bg-white figtree font-semibold text-sm ml-6">
                  About Us
                </p>
              </a>
            </div>
            <div className="border-3 md:ml-14 md:mr-12 ml-6 mr-8 mt-1"></div>

            <div className="xl:hidden lg:hidden md:block block ">
              <a href="/Services2">
                <p className=" cursor-pointer md:pl-6 pl-2  md:pb-3 pb-0  md:pt-4 pt-3 md:text-base font  uppercase text-black md:ml-10 bg-white figtree font-semibold text-sm ml-6">
                  services
                </p>
              </a>
            </div>
            <div className="border-3 md:ml-14 md:mr-12 ml-6 mr-8 mt-1"></div>

            <div className="xl:hidden lg:hidden md:block block ">
              <a href="/Employer">
                <p className=" cursor-pointer md:pl-6 pl-2  md:pb-3 pb-0  md:pt-4 pt-3 md:text-base font  uppercase text-black md:ml-10 bg-white figtree font-semibold text-sm ml-6">
                  Employer
                </p>
              </a>
            </div>
            <div className="border-3 md:ml-14 md:mr-12 ml-6 mr-8 mt-1"></div>

            <div className="xl:hidden lg:hidden md:block block">
              <a href="/Homepage2">
                <p className=" cursor-pointer md:pl-6 pl-2  md:pb-3 pb-0  md:pt-4 pt-3 md:text-base font  uppercase text-black md:ml-10 bg-white figtree font-semibold text-sm ml-6">
                  Candidate
                </p>
              </a>
            </div>

            <div className="xl:hidden lg:hidden md:block block md:mt-20 mt-12 ">
              <a href="/contact?">
                <button className="md:px-4 md:py-1 md:ml-14 bg-black text-white rounded-md px-4 py-1 ml-6 ">
                  Contact US
                </button>
              </a>
            </div>

            <div className="flex xl:hidden lg:hidden md:block block md:mt-16 pb-56 pl-0 mt-8 ">
              <a href="homepage2/">
                <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-14 ml-6">
                  <i className="fab fa-facebook"></i>
                </button>
              </a>

              <a href="homepage2/">
                <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-linkedin"></i>
                </button>
              </a>

              <a href="homepage2/">
                <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-instagram"></i>
                </button>
              </a>

              <a href="homepage2/">
                <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-youtube"></i>
                </button>
              </a>
            </div>
          </div>
        </div>
        </div>*/}
    </section>
  );
}
