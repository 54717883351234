import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer2 from "components/Footers/Footer2";
import banner from "assets/img/banner.jpg";
import mail from "assets/img/mail.png";
import location from "assets/img/location.png";
import availability from "assets/img/availability.png";
import calendar from "assets/img/calendar.png";
import phone from "assets/img/phone.png";

export default function Contactus() {
  return (
    <section>
      <Indexnavbar2 />
      <section className="xl:block lg:block md:hidden sm:hidden hidden  bg-newshade40 xl:pt-24 lg:pt-2 figtree">
        <div className=" ">
          <div
            class=""
            style={{
              backgroundImage: `url("https://bantec.themeori.com/wp-content/uploads/2024/02/Frame-10000088311.png.webp")`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "right center",
            }}
          >
            <div className="container mx-auto xl:pt-40 lg:pt-40">
              <p class="xl:text-5xl lg:text-5xl font-bold text-white  xl:ml-8 lg:ml-8">
                Contact us
              </p>
              <div className="flex xl:mt-4 lg:mt-2">
                <div>
                  <a href="/homepage1">
                    <p class="xl:text-base lg:text-base text-white blue-on-hover  xl:ml-8 lg:ml-8">
                      Home
                    </p>
                  </a>
                </div>
                <div class="vl-5"></div>
                <a href="/">
                  <p class="xl:text-base lg:text-base text-blue1 blue-on-hover  xl:ml-8 lg:ml-8">
                    Contact-us
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

        <section className="xl:block lg:block md:hidden sm:hidden hidden container mx-auto xl:mt-24 figtree lg:mt-20">
             <div className="grid grid-cols-3">
                <div>
                  <div className=" rounded-md bg-white shadow-xl xl:pb-4 lg:pb-4 xl:ml-2 lg:ml-8">
                     <div className="flex">
                        <div className="h-16 w-16 rounded-full bg-newshade29 xl:ml-6 xl:mt-6 lg:ml-6 lg:mt-6
                       ">
                            <div className="center-button ">
                            <img class="w-8 pt-4" src={mail}/>
                            </div>
                        </div>
                        <div>
                          <p class="xl:text-base1 lg:text-base1 lg:ml-6 lg:mt-6 font-bold text-darkblack xl:ml-6 xl:mt-6">Email Address</p>
                          <p class="xl:text-base lg:text-base lg:ml-6 lg:pt-2 xl:pt-2 blue-on-hover xl:ml-6">name.info@example.com</p>
                          <p class="xl:text-base lg:text-base lg:ml-6 blue-on-hover xl:ml-6">support@example.com</p>
                        </div>
                     </div>
                  </div>
                </div>


                <div>
                  <div className="rounded-md bg-white shadow-xl xl:pb-4 xl:ml-2 lg:pb-4 lg:ml-4">
                     <div className="flex">
                        <div className="h-16 w-16 rounded-full bg-newshade29 xl:ml-6 xl:mt-6 lg:ml-6 lg:mt-6">
                            <div className="center-button ">
                            <img class="w-8 pt-4" src={location}/>
                            </div>
                        </div>
                        <div>
                          <p class="xl:text-base1 font-bold text-darkblack xl:ml-6 xl:mt-6 lg:text-base1 lg:ml-6 lg:mt-6">Office Address</p>
                          <p class="xl:text-base xl:pt-2 blue-on-hover xl:ml-6 lg:text-base lg:ml-6 lg:pt-2">8502 Preston Rd. Inglewood</p>
                          <p class="xl:text-base  blue-on-hover xl:ml-6 lg:text-base lg:ml-6">Maine 98380</p>
                        </div>
                     </div>
                  </div>
                </div>


                <div>
                  <div className=" rounded-md bg-white shadow-xl xl:pb-4 xl:ml-2 lg:pb-4 lg:ml-4 lg:mr-8">
                     <div className="flex">
                        <div className="h-16 w-16 rounded-full bg-newshade29 xl:ml-6 xl:mt-6 lg:ml-6 lg:mt-6 ">
                            <div className="center-button ">
                            <img class="w-8 pt-4" src={phone}/>
                            </div>
                        </div>
                        <div>
                          <p class="xl:text-base1 lg:text-base1 font-bold text-darkblack xl:ml-6 xl:mt-6 lg:mt-6 lg:ml-6 ">Phone Number</p>
                          <p class="xl:text-base lg:text-base lg:pt-2 xl:pt-2 blue-on-hover xl:ml-6 lg:ml-6">(270) 555-0117</p>
                          <p class="xl:text-base  blue-on-hover xl:ml-6 lg:ml-6">(489) 459-7845</p>
                        </div>
                     </div>
                  </div>
                </div>
             </div>
        </section>

        <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-24 bg-newshade41 xl:pb-24 lg:mt-20 lg:pb-20">
            <div className="grid grid-cols-2 container mx-auto figtree xl:pt-24 lg:pt-24">
             <div className="xl:ml-2 lg:ml-8">
              <div className="xl:w-3/12 lg:w-32 rounded-md bg-newshade16  ">
               <p class="xl:text-base lg:text-base text-blue text-center">Ready To Chat</p>
               </div>
               <p class="xl:text-7xl font-bold text-darkblack xl:pt-4 lg:text-4xl lg:pt-4">Get in Touch with</p>
               <p class="xl:text-7xl font-bold text-darkblack lg:text-4xl">Our Experts!</p>
               <div>
                <img class="xl:w-3/12 xl:ml-60 lg:w-4/12 lg:ml-12"src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg" alt=""/>
               </div>
               <p class="xl:text-base xl:pt-4 font-semibold text-grey lg:text-sm lg:pt-4">Do have a query about our services, or just want to say hello, our</p>
               <p class="xl:text-base font-semibold text-grey">dedicated team is eager to connect with you.</p>
               <p class="xl:text-2xl text-darkblack xl:pt-6 font-bold lg:text-2xl lg:pt-4">Your Benefits:</p>

               <div className="flex xl:mt-6 lg:mt-4">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 lg:text-xs">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base  xl:ml-2 lg:text-base lg:ml-2 ">
                Social Integration
                </p>

                <div className="bg-newshade33 rounded-full w-6 h-6 xl:ml-8 lg:ml-4">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 lg:text-xs">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base  xl:ml-2 lg:text-base lg:ml-2">
                Direct Email Links
                </p>
              </div>

              <div className="flex xl:mt-2 lg:mt-2">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 lg:text-xs">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base  xl:ml-2 lg:text-base lg:ml-2">
                FAQs Section
                </p>

                <div className="bg-newshade33 rounded-full w-6 h-6 xl:ml-15 lg:ml-10">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 lg:text-xs">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base xl:ml-2 lg:text-base lg:ml-2 ">
                Interactive Map
                </p>
              </div>

              <div className="flex xl:mt-2 lg:mt-2">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 lg:text-xs ">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base  xl:ml-2 lg:text-base lg:ml-2">
                Callback Option
                </p>

                <div className="bg-newshade33 rounded-full w-6 h-6 xl:ml-10 lg:ml-6">
                  <p class="text-white font-bold xl:text-xs text-center pt-1 lg:text-xs">
                    ✓
                  </p>
                </div>
                <p class="xl:text-base  xl:ml-2 lg:text-base lg:ml-2 ">
                Instant Chat Support
                </p>
              </div>
             </div>

             <div className="lg:-ml-8 lg:mr-4 xl:ml-0 xl:mr-0">
              <form>
                <div className="flex">
                <input class="border-4 bg-white rounded-md xl:w-6/12 p-4  xl:text-base lg:w-6/12 lg:text-base" type="text" id="text" placeholder="Full Name"/>
                <input class="border-4 bg-white rounded-md xl:w-6/12 p-4  xl:text-base xl:ml-2 lg:ml-2 lg:w-6/12 lg:text-base" type="text" id="text" placeholder="Your Email"/>
                </div>
                <input class="border-4 bg-white rounded-md xl:w-12/12 p-4  xl:text-base xl:mt-6 lg:w-12/12 lg:text-base lg:mt-4" type="text" id="text" placeholder="Subject"/>
                <textarea  class=" border-4 xl:mt-6 lg:mt-4 lg:text-base lg:w-12/12 xl:text-base xl:w-12/12 rounded-md" id="w3review" name="w3review" placeholder="Message" rows="6" cols="0"></textarea>
                <button class=" xl:mt-6 bg-newshade33 text-center rounded-md xl:py-2 xl:px-6 text-white xl:text-base lg:mt-4 lg:px-6 lg:py-2 lg:text-base">Submit</button>
              </form>
             </div>
            </div>
        </section>

        <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-24 bg-newshade33 rounded-md figtree xl:pt-24 xl:pb-24 lg:mt-20 lg:pb-20 lg:pt-24 ">
           <div className="container mx-auto">
             <p class="xl:text-5xl text-center  font-bold text-white lg:text-4xl">Unlock Your Potential Today</p>
             <div className="flex justify-center xl:mt-4 lg:mt-2">
              <div>
                <button class="xl:px-8 xl:py-2 rounded-md bg-black text-white xl:mt-2 lg:px-8 lg:py-2 lg:mt-2 ">Request Quote ➔</button>
              </div>
              
              <div className="w-12 h-12 rounded-full bg-white xl:ml-4 lg:ml-4">
               <div class="center-button ">
                <img class="w-8 pt-2" src={phone}/>
               </div>
              </div>

              <div>
                <p class="xl:text-base text-white xl:ml-4 font-bold lg:text-base lg:ml-4">Make a call</p>
                <p class="xl:text-base text-white xl:ml-4 font-bold lg:text-base lg:ml-4">(307) 555-0133</p>
              </div>
             </div>
           </div>
        </section>

        {/*TAB VIEW*/}
        <section className="xl:hidden lg:hidden md:block sm:hidden hidden bg-newshade39 ">
           <div>
           
            <div className="container mx-auto ">
            <p class="text-5xl  font-bold text-white  text-center pt-12 ">
                Contact us
              </p>
              <div className="flex mt-4 justify-center pb-20">
                <div>
                  <a href="/homepage1">
                    <p class="text-base text-white blue-on-hover ">
                      Home
                    </p>
                  </a>
                </div>
                <div class="vl-5"></div>
                <a href="/">
                  <p class="text-base text-white blue-on-hover  ml-8 ">
                    Contact-us
                  </p>
                </a>
              </div>
            </div>
            </div>
             </section>

             <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20">
                <div className="grid grid-cols-2">
                <div>
                  <div className=" rounded-md bg-white shadow-xl pb-4 ml-8 ">
                     <div className="flex">
                        <div className="h-16 w-16 rounded-full bg-newshade29 ml-6 mt-6 
                       ">
                            <div className="center-button ">
                            <img class="w-8 pt-4" src={mail}/>
                            </div>
                        </div>
                        <div>
                          <p class="text-base1   font-bold text-darkblack ml-6 mt-6">Email Address</p>
                          <p class="text-base  pt-2 blue-on-hover ml-6">name.info@example.com</p>
                          <p class="text-base  blue-on-hover ml-6">support@example.com</p>
                        </div>
                     </div>
                  </div>
                </div>


                <div>
                  <div className="rounded-md bg-white shadow-xl pb-4 ml-2 mr-8">
                     <div className="flex">
                        <div className="h-16 w-16 rounded-full bg-newshade29 ml-6 mt-6  ">
                            <div className="center-button ">
                            <img class="w-8 pt-4" src={location}/>
                            </div>
                        </div>
                        <div>
                          <p class="text-base1 font-bold text-darkblack ml-6 mt-6 ">Office Address</p>
                          <p class="text-base pt-2 blue-on-hover ml-6 ">8502 Preston Rd. Inglewood</p>
                          <p class="text-base  blue-on-hover ml-6 ">Maine 98380</p>
                        </div>
                     </div>
                  </div>
                </div>
                </div>


                <div>
                  <div className="rounded-md bg-white shadow-xl pb-4 ml-2 w-6/12 mt-8">
                     <div className="flex">
                        <div className="h-16 w-16 rounded-full bg-newshade29 ml-6 mt-6  ">
                            <div className="center-button ">
                            <img class="w-8 pt-4" src={phone}/>
                            </div>
                        </div>
                        <div>
                          <p class="text-base1 font-bold text-darkblack ml-6 mt-6 ">Phone Number</p>
                          <p class="text-base pt-2 blue-on-hover ml-6 ">(270) 555-0117</p>
                          <p class="text-base  blue-on-hover ml-6 ">(489) 459-7845</p>
                        </div>
                     </div>
                  </div>
                </div>
             </section>


             <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20 bg-newshade41 pb-20 ">
            <div className=" container mx-auto figtree pt-24 ">
             <div className=" ml-8">
              <div className=" w-32 rounded-md bg-newshade16  ">
               <p class="text-base text-blue text-center">Ready To Chat</p>
               </div>
               <p class="text-4xl font-bold text-darkblack pt-4 ">Get in Touch with Our</p>
               <p class="text-4xl font-bold text-darkblack "> Experts!</p>
               <div className="">
                <img class="w-32"src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg" alt=""/>
               </div>
               <p class="text-base pt-4 font-semibold text-grey ">Do have a query about our services, or just want to say hello, our</p>
               <p class="xl:text-base font-semibold text-grey">dedicated team is eager to connect with you.</p>
               <p class="text-2xl text-darkblack pt-6 font-bold ">Your Benefits:</p>

               <div className="flex mt-4">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2  ">
                Social Integration
                </p>

                <div className="bg-newshade33 rounded-full w-6 h-6  ml-12">
                  <p class="text-white font-bold text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2 ">
                Direct Email Links
                </p>
              </div>

              <div className="flex mt-2 ">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2 ">
                FAQs Section
                </p>

                <div className="bg-newshade33 rounded-full w-6 h-6 ml-18">
                  <p class="text-white font-bold text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2  ">
                Interactive Map
                </p>
              </div>

              <div className="flex mt-2 ">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold text-xs text-center pt-1  ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2 ">
                Callback Option
                </p>

                <div className="bg-newshade33 rounded-full w-6 h-6 ml-14">
                  <p class="text-white font-bold text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2  ">
                Instant Chat Support
                </p>
              </div>
             </div>

             <div className="ml-8 mt-12 mr-8">
              <form>
                <div className="flex">
                <input class="border-4 bg-white rounded-md w-6/12 p-4 text-base" type="text" id="text" placeholder="Full Name"/>
                <input class="border-4 bg-white rounded-md w-6/12 p-4  ml-4 text-base" type="text" id="text" placeholder="Your Email"/>
                </div>
                <input class="border-4 bg-white rounded-md w-12/12 p-4 text-base mt-4 " type="text" id="text" placeholder="Subject"/>
                <textarea  class=" border-4 mt-4 w-12/12 text-base  rounded-md " id="w3review" name="w3review" placeholder="Message" rows="6" cols="0"></textarea>
                <button class=" mt-4 bg-newshade33 text-center rounded-md py-2 px-6 text-white text-base ">Submit</button>
              </form>
             </div>
            </div>
        </section>

        <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20 bg-newshade33 rounded-md figtree pt-24 pb-20  ">
           <div className="container mx-auto">
             <p class="text-5xl text-center font-bold text-white ">Unlock Your Potential Today</p>
             <div className="flex justify-center mt-4">
              <div>
                <button class="px-8 py-2 rounded-md bg-black text-white mt-2  ">Request Quote ➔</button>
              </div>
              
              <div className="w-12 h-12 rounded-full bg-white ml-4 ">
               <div class="center-button ">
                <img class="w-8 pt-2" src={phone}/>
               </div>
              </div>

              <div>
                <p class="text-base text-white ml-4 font-bold ">Make a call</p>
                <p class="text-base text-white ml-4 font-bold ">(307) 555-0133</p>
              </div>
             </div>
           </div>
        </section>


        {/*MOBILE VIEW*/}
        <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade39 ">
           <div>
           
            <div className="container mx-auto ">
            <p class="text-5xl  font-bold text-white  text-center pt-12 ">
                Contact us
              </p>
              <div className="flex mt-4 justify-center pb-20">
                <div>
                  <a href="/homepage1">
                    <p class="text-base text-white blue-on-hover ">
                      Home
                    </p>
                  </a>
                </div>
                <div class="vl-5"></div>
                <a href="/">
                  <p class="text-base text-white blue-on-hover  ml-8 ">
                    Contact-us
                  </p>
                </a>
              </div>
            </div>
            </div>
             </section>

             <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20">
                <div className="">
                <div>
                  <div className=" rounded-md bg-white shadow-xl pb-4 ml-8 mr-8">
                     <div className="flex">
                        <div className="h-16 w-16 rounded-full bg-newshade29 ml-6 mt-6 
                       ">
                            <div className="center-button ">
                            <img class="w-8 pt-4" src={mail}/>
                            </div>
                        </div>
                        <div>
                          <p class="text-base1   font-bold text-darkblack ml-6 mt-6">Email Address</p>
                          <p class="text-base  pt-2 blue-on-hover ml-6">name.info@example.com</p>
                          <p class="text-base  blue-on-hover ml-6">support@example.com</p>
                        </div>
                     </div>
                  </div>
                </div>


                <div>
                  <div className="rounded-md bg-white shadow-xl pb-4 ml-8 mt-8 mr-8">
                     <div className="flex">
                        <div className="h-16 w-16 rounded-full bg-newshade29 ml-6 mt-6  ">
                            <div className="center-button ">
                            <img class="w-8 pt-4" src={location}/>
                            </div>
                        </div>
                        <div>
                          <p class="text-base1 font-bold text-darkblack ml-6 mt-6 ">Office Address</p>
                          <p class="text-base pt-2 blue-on-hover ml-6 ">8502 Preston Rd. Inglewood</p>
                          <p class="text-base  blue-on-hover ml-6 ">Maine 98380</p>
                        </div>
                     </div>
                  </div>
                </div>
                </div>


                <div>
                  <div className="rounded-md bg-white shadow-xl pb-4 ml-8 mr-8 mt-8">
                     <div className="flex">
                        <div className="h-16 w-16 rounded-full bg-newshade29 ml-6 mt-6  ">
                            <div className="center-button ">
                            <img class="w-8 pt-4" src={phone}/>
                            </div>
                        </div>
                        <div>
                          <p class="text-base1 font-bold text-darkblack ml-6 mt-6 ">Phone Number</p>
                          <p class="text-base pt-2 blue-on-hover ml-6 ">(270) 555-0117</p>
                          <p class="text-base  blue-on-hover ml-6 ">(489) 459-7845</p>
                        </div>
                     </div>
                  </div>
                </div>
             </section>

             <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 bg-newshade41 pb-20 ">
            <div className=" container mx-auto figtree pt-24 ">
             <div className=" ml-8">
              <div className=" w-32 rounded-md bg-newshade16  ">
               <p class="text-base text-blue text-center">Ready To Chat</p>
               </div>
               <p class="text-4xl font-bold text-darkblack pt-4 ">Get in Touch with Our Experts!</p>
               
               <div className="">
                <img class="w-32"src="https://www.radiustheme.com/demo/wordpress/themes/quiklearn/wp-content/themes/quiklearn/assets/element/underline.svg" alt=""/>
               </div>
               <p class="text-base pt-4 font-semibold text-grey ">Do have a query about our services,<br></br> or just want to say hello our</p>
               <p class="xl:text-base font-semibold text-grey">dedicated team is eager to connect <br></br>with you.</p>
               <p class="text-2xl text-darkblack pt-6 font-bold ">Your Benefits:</p>

               <div className="flex mt-4">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2  ">
                Social Integration
                </p>
                </div>

                <div className="flex mt-2" >
                <div className="bg-newshade33 rounded-full w-6 h-6  ">
                  <p class="text-white font-bold text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2 ">
                Direct Email Links
                </p>
                </div>

              <div className="flex mt-2 ">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2 ">
                FAQs Section
                </p>
                </div>

                <div className="flex mt-2">
                <div className="bg-newshade33 rounded-full w-6 h-6 ">
                  <p class="text-white font-bold text-xs text-center pt-1 ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2  ">
                Interactive Map
                </p>
              </div>

              <div className="flex mt-2 ">
                <div className="bg-newshade33 rounded-full w-6 h-6">
                  <p class="text-white font-bold text-xs text-center pt-1  ">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2 ">
                Callback Option
                </p>
                 </div>

                 <div className="flex mt-2">
                <div className="bg-newshade33 rounded-full w-6 h-6 ">
                  <p class="text-white font-bold text-xs text-center pt-1">
                    ✓
                  </p>
                </div>
                <p class="text-base ml-2  ">
                Instant Chat Support
                </p>
              </div>
             </div>

             <div className="ml-8 mt-12 mr-8">
              <form>
                <div className="flex">
                <input class="border-4 bg-white rounded-md w-6/12 p-4 text-base" type="text" id="text" placeholder="Full Name"/>
                <input class="border-4 bg-white rounded-md w-6/12 p-4  ml-4 text-base" type="text" id="text" placeholder="Your Email"/>
                </div>
                <input class="border-4 bg-white rounded-md w-12/12 p-4 text-base mt-4 " type="text" id="text" placeholder="Subject"/>
                <textarea  class=" border-4 mt-4 w-12/12 text-base  rounded-md " id="w3review" name="w3review" placeholder="Message" rows="6" cols="0"></textarea>
                <button class=" mt-4 bg-newshade33 text-center rounded-md py-2 px-6 text-white text-base ">Submit</button>
              </form>
             </div>
            </div>
        </section>
    
        <section className="xl:hidden lg:hidden md:hidden sm:block block mt-20 bg-newshade33 rounded-md figtree pt-24 pb-20  ">
           <div className="container mx-auto">
             <p class="text-5xl text-center font-bold text-white ">Unlock Your Potential Today</p>
             <div className="flex justify-center mt-4">
              <div>
                <button class="px-6 py-2 rounded-md bg-black text-white mt-2  ">Request Quote ➔</button>
              </div>
              
              <div className="w-12 h-12 rounded-full bg-white ml-4 ">
               <div class="center-button ">
                <img class="w-8 pt-2" src={phone}/>
               </div>
              </div>

              <div>
                <p class="text-base text-white ml-4 font-bold ">Make a call</p>
                <p class="text-base text-white ml-4 font-bold ">(307) 555-0133</p>
              </div>
             </div>
           </div>
        </section>


             {/*END*/}


      <Footer2 />
    </section>
  );
}
